import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

interface Props {
  entityType: 'company' | 'trust';
  isShareholder?: boolean;
  hideQuestionNumbers?: boolean;
  hideThirdPartyQuestion?: boolean;
  offersFinancialServices?: boolean;
  setOffersFinancialServices: (value: boolean) => void;
  offersCryptoServices?: boolean;
  setOffersCryptoServices: (value: boolean) => void;
  tradesArmsOilOrPreciousMats?: boolean;
  setTradesArmsOilOrPreciousMats: (value: boolean) => void;
}

export const SupplyInformationStepComplianceDisclaimers: React.FC<Props> = (props) => {
  const {
    entityType,
    offersFinancialServices,
    setOffersFinancialServices,
    offersCryptoServices,
    setOffersCryptoServices,
    tradesArmsOilOrPreciousMats,
    setTradesArmsOilOrPreciousMats,
    hideThirdPartyQuestion,
    isShareholder,
    hideQuestionNumbers,
  } = props;

  const entityTypeText = entityType === 'company' ? 'company' : 'corporate trustee';
  const entityDeterminerText = isShareholder ? 'this' : 'your';

  return (
    <FlexLayout direction='column' spacing={32}>
      {!hideThirdPartyQuestion && (
        <>
          <FlexLayout direction='column' spacing={12}>
            <Body size='small' weight='emphasis'>
              {hideQuestionNumbers ? '' : '4.'} Does {entityDeterminerText} {entityTypeText} offer financial services
              (e.g. advisory services, stock brokerage) to underlying third-party customers?
            </Body>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={getRadioSelectedValue(offersFinancialServices)}
              onChange={(value) => setOffersFinancialServices(value === 'yes')}
            />
          </FlexLayout>
          <FlexLayout direction='column' spacing={12}>
            <Body size='small' weight='emphasis'>
              {hideQuestionNumbers ? '' : '5.'} Does {entityDeterminerText} {entityTypeText} offer crypto services (e.g.
              exchange, ATM) to third-party customers?
            </Body>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={getRadioSelectedValue(offersCryptoServices)}
              onChange={(value) => setOffersCryptoServices(value === 'yes')}
            />
          </FlexLayout>
        </>
      )}
      <FlexLayout direction='column' spacing={12}>
        <Body size='small' weight='emphasis'>
          {hideQuestionNumbers ? '' : '6.'} Does {entityDeterminerText} {entityTypeText} directly trade in arms, oil or
          precious materials?
        </Body>
        <RadioGroup
          checkboxes={yesNoRadioGroup}
          selected={getRadioSelectedValue(tradesArmsOilOrPreciousMats)}
          onChange={(value) => setTradesArmsOilOrPreciousMats(value === 'yes')}
        />
      </FlexLayout>
    </FlexLayout>
  );
};
