import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { getPriceScale } from '@swyftx/currency-util';

import {
  TriggerType,
  ProfitLossValueMeasurement,
  ProfitLossValueMethod,
} from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useProfitStopLossValueCalculationValueUI } from './ProfitStopLossValueCalculationValue.utils';
import { getCalculatedTriggerPrice } from '../../hooks/ProfitStopLossUtils';

type Props = {
  amount: Big;
  feeAmount: Big;
  feePercentage: string;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
  setProfitLossValueMeasurement: React.Dispatch<React.SetStateAction<ProfitLossValueMeasurement>>;
  profitLossValueMethod: ProfitLossValueMethod;
  profitLossValue: string;
  purchasePrice: Big;
  currentPrice?: string;
  triggerPrice?: string;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  triggerType: TriggerType;
  estimatedProfitPercentage: string;
  setProfitLossValue: (newValue: string) => void;
};

const PROFIT_AMOUNTS = ['25', '50', '100', '250', '500'];
const PERCENTAGE_AMOUNTS = ['3', '5', '10', '15', '25'];

const ProfitStopLossValueCalculationValue: React.FC<Props> = ({
  amount,
  feeAmount,
  feePercentage,
  profitLossValueMeasurement,
  profitLossValueMethod,
  profitLossValue,
  purchasePrice,
  currentPrice,
  triggerPrice,
  primaryAsset,
  secondaryAsset,
  triggerType,
  setProfitLossValueMeasurement,
  estimatedProfitPercentage,
  setProfitLossValue,
}) => {
  const { error, label, endAdornment, startAdornment, placeholder, isDollarCalculation } =
    useProfitStopLossValueCalculationValueUI({
      triggerType,
      profitLossValue,
      profitLossValueMethod,
      profitLossValueMeasurement,
      currentPrice,
      triggerPrice,
      primaryAsset,
      secondaryAsset,
      estimatedProfitPercentage,
    });

  const sanitiseValue = (newValue: string) => {
    const isValid = newValue === '.' || !Number.isNaN(Number(newValue));
    if (!isValid) return;

    setProfitLossValue(newValue);
  };

  const onUpdateFromPercentage = (percentageValue: string) => {
    if (profitLossValueMethod === ProfitLossValueMethod.AmountTarget) {
      setProfitLossValue(percentageValue);
      return;
    }

    const calcTriggerPrice = getCalculatedTriggerPrice(
      {
        purchasePrice,
        feePercentage,
        feeAmount,
        triggerType,
        profitLossValue,
        profitLossValueMeasurement,
        amount,
      },
      percentageValue,
    );

    const priceScale = getPriceScale(calcTriggerPrice.toString());
    if (triggerType === TriggerType.TakeProfit) {
      setProfitLossValue(
        Big(purchasePrice)
          .times(Big(1).plus(Big(percentageValue).div(100)))
          .toFixed(priceScale),
      );
    } else {
      setProfitLossValue(
        Big(purchasePrice)
          .times(Big(1).minus(Big(percentageValue).div(100)))
          .toFixed(priceScale),
      );
    }
  };

  return (
    <FlexLayout direction='column' spacing={8}>
      <FlexLayout direction='row' spacing={4} className='px-16'>
        <FlexLayout spacing={4} direction='column' className='w-full'>
          <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
            {profitLossValueMethod === ProfitLossValueMethod.AmountTarget && (
              <FlexLayout
                direction='row'
                alignItems='center'
                className='w-full'
                justifyContent='space-between'
                spacing={8}
              >
                <Body weight='emphasis'>{label}</Body>

                <EnhancedTabs<ProfitLossValueMeasurement>
                  value={profitLossValueMeasurement}
                  size='sm'
                  variant='default'
                  onChange={setProfitLossValueMeasurement}
                  tabs={[
                    {
                      title: '$',
                      color: 'accent',
                      value: ProfitLossValueMeasurement.Dollar,
                    },
                    {
                      title: '%',
                      color: 'accent',
                      value: ProfitLossValueMeasurement.Percentage,
                    },
                  ]}
                />
              </FlexLayout>
            )}
          </FlexLayout>
          <Input
            id='input-profit-amount'
            value={profitLossValue}
            error={error.length > 0}
            onChange={(e) => sanitiseValue(e.target.value)}
            color='primary'
            placeholder={placeholder}
            leading={startAdornment}
            trailing={endAdornment}
            className='w-full'
          />
          {error && (
            <Body size='small' color='error'>
              {error}
            </Body>
          )}
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={8}
        className='w-full px-16'
      >
        {/* map over profit amounts */}
        {isDollarCalculation &&
          PROFIT_AMOUNTS.map((profitAmount) => (
            <Button
              id={`btn-profit-amount-${profitAmount}`}
              key={profitAmount}
              size='sm'
              variant='outlined'
              className='w-full'
              onClick={() => setProfitLossValue(profitAmount)}
            >
              ${profitAmount}
            </Button>
          ))}
        {!isDollarCalculation &&
          PERCENTAGE_AMOUNTS.map((profitPercentage) => (
            <Button
              id={`btn-profit-amount-${profitPercentage}`}
              key={profitPercentage}
              size='sm'
              variant='outlined'
              className='w-full'
              onClick={() => onUpdateFromPercentage(profitPercentage)}
            >
              {profitPercentage}%
            </Button>
          ))}
      </FlexLayout>
    </FlexLayout>
  );
};

export { ProfitStopLossValueCalculationValue };
