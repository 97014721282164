import { useCallback, useEffect, useMemo } from 'react';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import {
  Bundles,
  Clock,
  Coin,
  Dashboard,
  Deposit,
  MenuCollapse,
  MenuExpand,
  PortfolioTransfer,
  Profile,
  QuickBuy,
  Recurring,
  Shop,
  Trade,
  Wallet,
  WhatsNew,
  Withdraw,
  LendingHand,
} from '@swyftx/aviary/icons/outlined';
import { AppSideBarItemType, AppSideBarState } from '@swyftx/aviary/molecules/AppSideBar';
import { AppSideBarItem } from '@swyftx/aviary/molecules/AppSideBar/AppSideBarItem';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UIStore } from '@shared/store';

import { DateTime } from 'luxon';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SideBarMobileFooter } from './components/SideBarMobileFooter';
import { SideBarMobileHeader } from './components/SideBarMobileHeader';
import { useAppSideBarAvoEvents } from './useAppSideBarAvoEvents';

const useAppSideBar = () => {
  const {
    navDrawerExpanded,
    navDrawerPinned,
    setNavDrawerPinned,
    setNavDrawerExpanded,
    setNavDrawerOpen,
    navDrawerOpen,
  } = UIStore.useUIStore;

  const { navigate, isRouteActive } = useNavigateRoute();
  const { openModal } = useModal();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const autoInvestEnabled = isFeatureEnabled(AppFeature.AutoInvest);
  const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const quickBuyEnabled = isFeatureEnabled(AppFeature.QuickBuy);
  const loansEnabled = isFeatureEnabled(AppFeature.Loans);
  const { trackRouteNavigation } = useAppSideBarAvoEvents();
  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const state: AppSideBarState = useMemo((): AppSideBarState => {
    if (isSm && navDrawerOpen) return 'overlay';
    if (!isSm && !isXs && navDrawerPinned) return 'docked';
    if (isXs && navDrawerExpanded) return 'docked';

    return 'closed';
  }, [isSm, isXs, navDrawerExpanded, navDrawerOpen, navDrawerPinned]);

  const onOpenModal = useCallback(
    (modal: Modals, route: NavigationURLs) => {
      trackRouteNavigation(route);
      openModal(modal);
      setNavDrawerExpanded(false);
    },
    [openModal, setNavDrawerExpanded, trackRouteNavigation],
  );

  const onCloseAppBar = useCallback(() => {
    setNavDrawerExpanded(false);
  }, [setNavDrawerExpanded]);

  const onExpand = useCallback(() => {
    if (isSm) {
      setNavDrawerOpen(!navDrawerOpen);
      setNavDrawerPinned(false);
    } else {
      setNavDrawerOpen(false);
      setNavDrawerPinned(!navDrawerPinned);
    }
  }, [isSm, navDrawerOpen, navDrawerPinned, setNavDrawerOpen, setNavDrawerPinned]);

  useEffect(() => {
    if (isSm) {
      if (navDrawerPinned || navDrawerExpanded) {
        setNavDrawerOpen(true);
        setNavDrawerPinned(false);
        setNavDrawerExpanded(false);
      }
    } else {
      if (navDrawerOpen) {
        setNavDrawerPinned(true);
        setNavDrawerExpanded(true);
        setNavDrawerOpen(false);
      }
    }
  }, [
    isSm,
    isXs,
    navDrawerExpanded,
    navDrawerOpen,
    navDrawerPinned,
    setNavDrawerExpanded,
    setNavDrawerOpen,
    setNavDrawerPinned,
  ]);

  const header = useMemo(() => {
    if (isXs) {
      return <SideBarMobileHeader />;
    } else {
      return (
        <AppSideBarItem
          className='pb-8'
          item={{
            title: navDrawerPinned || navDrawerOpen ? 'Collapse' : 'Expand',
            icon: navDrawerPinned ? <MenuCollapse id='dashboard' /> : <MenuExpand id='dashboard' />,
            onClick: onExpand,
            separator: true,
          }}
        />
      );
    }
  }, [isXs, navDrawerOpen, navDrawerPinned, onExpand]);

  const footer = useMemo(() => {
    if (isXs) {
      return <SideBarMobileFooter />;
    } else {
      return undefined;
    }
  }, [isXs]);

  const onNavigate = useCallback(
    (path: NavigationURLs) => {
      trackRouteNavigation(path);
      navigate(path);
      setNavDrawerExpanded(false);
    },
    [navigate, setNavDrawerExpanded, trackRouteNavigation],
  );

  const isNew = useCallback((date?: DateTime) => {
    if (!date) return false;
    const addedDatePlus3Months = date.plus({ months: 3 }).toMillis();
    if (addedDatePlus3Months > DateTime.now().toMillis()) {
      return true;
    }
    return false;
  }, []);

  const buildAppSideBarItem = useCallback(
    (
      title: string,
      icon: React.ReactNode,
      path: NavigationURLs,
      addedDate?: DateTime,
      onClick?: () => void,
      hidden?: boolean,
    ): AppSideBarItemType => {
      const featureIsNew = isNew(addedDate);

      return {
        title,
        icon,
        active: isRouteActive(path),
        onClick: onClick ? onClick : () => onNavigate(path),
        trailing: featureIsNew ? (
          <Chip leadingIcon={<WhatsNew className='text-color-text-white' />} size='sm' color='accent'>
            New
          </Chip>
        ) : undefined,
        hidden,
      };
    },
    [isNew, isRouteActive, onNavigate],
  );

  const items: AppSideBarItemType[] = useMemo(
    (): AppSideBarItemType[] => [
      buildAppSideBarItem('Dashboard', <Dashboard />, NavigationURLs.Dashboard),
      buildAppSideBarItem('Profile', <Profile />, NavigationURLs.Profile, undefined, undefined, !isXs),
      buildAppSideBarItem(
        'Trade',
        <Trade />,
        NavigationURLs.UniversalTrade,
        universalTradePageEnabled ? DateTime.fromFormat('2023-12-23', 'yyyy-MM-dd') : undefined,
      ),
      buildAppSideBarItem(
        'Quick buy',
        <QuickBuy id='quickBuy' className='h-24 w-24' />,
        NavigationURLs.QuickBuy,
        quickBuyEnabled ? DateTime.fromFormat('2023-10-31', 'yyyy-MM-dd') : undefined,
      ),
      buildAppSideBarItem('Deposit', <Deposit />, NavigationURLs.DepositReceive, undefined, () =>
        onOpenModal(Modals.DepositReceive, NavigationURLs.DepositReceive),
      ),
      buildAppSideBarItem('Withdraw', <Withdraw />, NavigationURLs.WithdrawSend, undefined, () =>
        onOpenModal(Modals.WithdrawSend, NavigationURLs.WithdrawSend),
      ),
      buildAppSideBarItem('Transfer portfolio', <PortfolioTransfer />, NavigationURLs.PortfolioTransfer),
      buildAppSideBarItem('Asset list', <Coin />, NavigationURLs.AssetsAll),
      buildAppSideBarItem('Wallets', <Wallet />, NavigationURLs.WalletsRoot),
      buildAppSideBarItem('Bundles', <Bundles />, NavigationURLs.Bundles),
      buildAppSideBarItem(
        'Auto invest',
        <Recurring />,
        NavigationURLs.AutoInvest,
        DateTime.fromFormat('2023-12-14', 'yyyy-MM-dd'),
        undefined,
        !autoInvestEnabled,
      ),
      buildAppSideBarItem('Transactions', <Clock />, NavigationURLs.TransactionsRoot),
      buildAppSideBarItem('Shop', <Shop />, NavigationURLs.Shop),
      buildAppSideBarItem('Loans', <LendingHand />, NavigationURLs.Loans, undefined, undefined, !loansEnabled),
    ],
    [
      autoInvestEnabled,
      buildAppSideBarItem,
      isXs,
      loansEnabled,
      onOpenModal,
      quickBuyEnabled,
      universalTradePageEnabled,
    ],
  );

  return { items, state, header, footer, onCloseAppBar };
};

export { useAppSideBar };
