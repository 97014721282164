import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Links } from '@shared/constants';

type Props = {
  open: boolean;
  doNotShowToggle?: boolean;
  onDoNotShowToggle: (checked: boolean) => void;
  onClose: () => void;
};

const TriggerOrdersNoteModal: React.FC<Props> = ({ open, onClose, doNotShowToggle, onDoNotShowToggle }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'triggerOrderNotes' });
  const isXs = useTailwindBreakpoint('xs');

  return (
    <Modal
      id='note-about-trigger-orders-modal'
      open={open}
      position={isXs ? 'bottom' : 'center'}
      title='A note about trigger orders'
      overlayClassName='z-modal-popover-backdrop'
      className='sm:w-[400px]'
      onClose={onClose}
    >
      <FlexLayout direction='column' spacing={16} className='px-24 pb-24'>
        <Body color='secondary'>{t('labels.learnMoreText')}</Body>
        <Body color='secondary'>
          <Trans
            t={t}
            i18nKey='labels.historyPage'
            components={[
              <Body
                key='hyperlink'
                className='inline cursor-pointer'
                color='accent'
                onClick={() => window.open(Links.help.orderTypes, '_blank', 'noopener,noreferrer')}
              />,
            ]}
          />
        </Body>
        <FlexLayout spacing={8}>
          <Checkbox checked={doNotShowToggle} onCheckedChange={(checked) => onDoNotShowToggle(!!checked)} />
          <Body className='cursor-pointer' onClick={() => onDoNotShowToggle(!doNotShowToggle)}>
            {t('buttonLabels.doNotShow')}
          </Body>
        </FlexLayout>
        <FlexLayout className='w-full'>
          <Button variant='filled' onClick={onClose}>
            {t('buttonLabels.understand')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { TriggerOrdersNoteModal };
