import { useCallback } from 'react';

import { api, Asset } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { getWithdrawAddressMetadata, WithdrawalAddress } from '@shared/services';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

type Props = {
  asset?: Asset;
};

const useFetchAssetAddresses = ({ asset }: Props) => {
  const { getUniqueIdentifier } = UserStore.useUserStore;

  const fetchAssetAddresses = useCallback(async () => {
    if (!asset) return [];

    const response = await api.endpoints.getWithdrawAddresses({ params: { code: asset.code } });

    const formattedAddressData: Array<WithdrawalAddress> = response.data.address.map((address) => {
      const metadata = getWithdrawAddressMetadata(address.address_details);
      return { ...address, metadata };
    });

    return formattedAddressData;
  }, [asset]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['asset-addresses', asset?.code, getUniqueIdentifier()],
    queryFn: fetchAssetAddresses,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 60,
  });

  return { status, data: data || [], error, isFetching, isPreviousData, isStale };
};

export { useFetchAssetAddresses };
