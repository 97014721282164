import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { TransactionHistoryStatuses, TransactionHistorySides, TransactionHistoryTab } from '@routes/Transactions/types';

import { Separator } from '@radix-ui/react-separator';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { TransactionsFilters } from '../TransactionsFilters';
import { TransactionsTable } from '../TransactionsTable/TransactionsTable';
import { TransactionsTabs } from '../TransactionsTabs';

type Props = {
  filterAsset?: Asset;
  type: TransactionHistoryTab;
  className?: string;
  onChangeType: (type: TransactionHistoryTab) => void;
};

const TransactionsTableCard: React.FC<Props> = observer(({ filterAsset, type, className, onChangeType }) => {
  const [statuses, setStatuses] = useState<TransactionHistoryStatuses[]>([]);
  const [sides, setSides] = useState<TransactionHistorySides[]>([]);
  const [asset, setAsset] = useState<Asset | undefined>(filterAsset);
  const [startDate, setStartDate] = useState<DateTime | undefined>(undefined);
  const [endDate, setEndDate] = useState<DateTime | undefined>(undefined);

  useEffect(() => {
    setAsset(filterAsset);
  }, [filterAsset]);

  useEffect(() => {
    setStatuses([]);
    setSides([]);
  }, [type]);

  const onClearFilters = useCallback(() => {
    setStatuses([]);
    if (!filterAsset) setAsset(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [filterAsset]);

  return (
    <Card
      className={cn('relative w-full overflow-hidden rounded-[0px] @container sm:rounded-16', className)}
      data-container='transactions-table-card'
    >
      <FlexLayout
        alignItems='start'
        justifyContent='start'
        className='border-b border-color-border-main'
        direction='column'
        spacing={0}
      >
        <TransactionsTabs value={type} onChange={onChangeType} />
        <TransactionsFilters
          filterAsset={filterAsset}
          type={type}
          asset={asset}
          statuses={statuses}
          sides={sides}
          startDate={startDate}
          endDate={endDate}
          setAsset={setAsset}
          setStatuses={setStatuses}
          setSides={setSides}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </FlexLayout>
      <Separator />
      <FlexLayout className='h-[calc(100%-7rem)] w-full overflow-hidden'>
        <TransactionsTable
          type={type}
          asset={asset}
          sides={sides}
          statuses={statuses}
          startDate={startDate}
          endDate={endDate}
        >
          {(statuses.length > 0 || (asset && !filterAsset) || startDate || endDate) && (
            <Button variant='outlined' onClick={onClearFilters}>
              Clear filters
            </Button>
          )}
        </TransactionsTable>
      </FlexLayout>
    </Card>
  );
});

export { TransactionsTableCard };
