import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { lighten } from '@mui/system/colorManipulator';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { ArrowInCircleDown } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { StorageKey } from '@shared/storage';
import { formatCurrency } from '@shared/utils';

import { AssetColors } from '@utils/assets';

import { useLocalStorage } from 'react-use';

import { ProfitStopLossSummary } from './ProfitStopLossSummary';
import { TriggerOrdersNoteModal } from '../../TriggerOrdersNoteModal';

type Props = {
  triggerType: TriggerType;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  amount: Big;
  feePercentage?: string;
  estimatedTotal: string;
  triggerPrice: string;
  estimatedProfitPercentage: string;
  estimatedProfitDollars: string;
};

const ProfitLossPlaceOrderSummary: React.FC<Props> = ({
  triggerType,
  primaryAsset,
  secondaryAsset,
  amount,
  estimatedTotal,
  feePercentage,
  triggerPrice,
  estimatedProfitPercentage,
  estimatedProfitDollars,
}) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const [doNotShowStopLossTriggerNotes, setDoNotShowStopLossTriggerNotes] = useLocalStorage(
    StorageKey.DO_NOT_SHOW_STOP_LOSS_TRIGGER_NOTES,
    false,
  );

  const [showTriggerNotes, setShowTriggerNotes] = useState<boolean>(false);

  useEffect(() => {
    if (!doNotShowStopLossTriggerNotes) {
      setShowTriggerNotes(true);
    }
  }, [doNotShowStopLossTriggerNotes]);

  return (
    <>
      <TriggerOrdersNoteModal
        open={showTriggerNotes}
        onClose={() => setShowTriggerNotes(false)}
        doNotShowToggle={doNotShowStopLossTriggerNotes}
        onDoNotShowToggle={(checked) => setDoNotShowStopLossTriggerNotes(checked)}
      />

      <FlexLayout direction='column' spacing={16}>
        <FlexLayout direction='column' spacing={0} className='px-16'>
          <Body color='secondary'>{t('labels.orderType')}</Body>
          <Body weight='bold' color='primary'>
            {t(triggerType === TriggerType.TakeProfit ? 'labels.triggerSell' : 'labels.stopSell')}
          </Body>
        </FlexLayout>

        <FlexLayout spacing={4} direction='column' className='px-16'>
          {secondaryAsset && (
            <FlexLayout direction='row' spacing={16} alignItems='center'>
              <FlexLayout
                alignItems='center'
                justifyContent='center'
                className='h-48 w-48 rounded-[16px]'
                style={{ backgroundColor: lighten(AssetColors[secondaryAsset.code], 0.8) }}
              >
                <AssetIcon asset={secondaryAsset.code} size={24} />
              </FlexLayout>
              <FlexLayout direction='column'>
                <Body color='secondary'>{t('labels.spend')}</Body>
                <Numeric weight='emphasis' color='primary'>
                  {formatCurrency(amount, secondaryAsset)}
                </Numeric>
              </FlexLayout>
            </FlexLayout>
          )}
          <FlexLayout alignItems='center' justifyContent='center' className='h-48 w-48'>
            <ArrowInCircleDown />
          </FlexLayout>
          {primaryAsset && (
            <FlexLayout direction='row' spacing={16} alignItems='center'>
              <FlexLayout
                alignItems='center'
                justifyContent='center'
                className='h-48 w-48 rounded-[16px]'
                style={{ backgroundColor: lighten(AssetColors[primaryAsset.code], 0.8) }}
              >
                <AssetIcon asset={primaryAsset.code} size={24} />
              </FlexLayout>
              <FlexLayout direction='column'>
                <Body color='secondary'>{t('labels.receive')}</Body>
                <Numeric weight='emphasis' color='primary'>
                  {formatCurrency(estimatedTotal, primaryAsset)}
                </Numeric>
              </FlexLayout>
            </FlexLayout>
          )}
        </FlexLayout>

        <ProfitStopLossSummary
          triggerType={triggerType}
          triggerPrice={triggerPrice}
          feePercentage={feePercentage}
          estimatedProfitPercentage={estimatedProfitPercentage}
          estimatedProfitDollars={estimatedProfitDollars}
          estimatedTotal={estimatedTotal}
          primaryAsset={primaryAsset}
          secondaryAsset={secondaryAsset}
          showDivider
          addPadding
        />
      </FlexLayout>
    </>
  );
};

export { ProfitLossPlaceOrderSummary };
