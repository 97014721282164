import React, { useCallback } from 'react';

import { LinkButton } from '@swyftx/aviary/atoms/Button/LinkButton';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Reddit, Facebook, TwitterX, Instagram, Telegram, Search } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';

import { capitalize } from '@utils/formatting';

interface Props {
  asset: Asset;
}

// Hide urls if a null twitter link or if no subreddit is set
const KNOWN_BAD_URLS = ['https://twitter.com/null', 'https://www.reddit.com'];

export const AssetLinks: React.FC<Props> = (props) => {
  const { asset } = props;

  const assetsToMap = Object.entries(asset.urls ?? {}).filter(
    ([urlType, url]) => !['website'].includes(urlType) && url !== '' && !KNOWN_BAD_URLS.includes(url),
  );

  const getIcon = useCallback((urlType: string) => {
    switch (urlType) {
      case 'reddit':
        return <Reddit />;
      case 'facebook':
        return <Facebook />;
      case 'twitter':
        return <TwitterX />;
      case 'instagram':
        return <Instagram />;
      case 'telegram':
        return <Telegram />;
      case 'explorer':
        return <Search />;
    }
  }, []);

  return (
    <FlexLayout direction='row' spacing={8}>
      {assetsToMap.map(([urlType, url]) => (
        <LinkButton
          variant='outlined'
          layout='icon'
          size='md'
          leadingIcon={getIcon(urlType)}
          key={urlType}
          className='h-[2rem] w-[2rem] items-center'
          url={url}
          openInNewTab
          tooltip={capitalize(urlType)}
        />
      ))}
    </FlexLayout>
  );
};
