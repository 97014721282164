import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyRight, Cross, Delete } from '@swyftx/aviary/icons/outlined';

import { api } from '@shared/api';
import { UIStore, UserStore } from '@shared/store';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { EntityOnboardingStepId } from '@routes/EntityOnboarding/types/EntityOnboarding.types';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { CancelEntityApplicationModal } from './modals/CancelEntityApplicationModal';
import { useEntityOnboardingAnalytics } from '../events/useEntityOnboardingAnalytics';

const submittedStepId: EntityOnboardingStepId = 'formSubmittedStep';

const HeaderExitButton: React.FC = observer(() => {
  const isXs = useTailwindBreakpoint('xs');
  const { navigate } = useNavigateRoute();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancelApplicationOpen, setCancelApplicationOpen] = useState<boolean>(false);
  const { addToastMessage } = UIStore.useUIStore;
  const { search } = useLocation();
  const { updateApplyForEntityAccountFlag } = useEntityOnboardingApplication();
  const { applicationExited, applicationCancelled } = useEntityOnboardingAnalytics();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepId = queryParams.get('step') as EntityOnboardingStepId;

  const isSubmitted = search.endsWith(submittedStepId);

  const { isUserVerified, isKyc1Complete, isEntity } = UserStore.useUserStore;
  const canDeleteEntity = useMemo(
    () => !isSubmitted && isKyc1Complete() && isUserVerified() && !isEntity(),
    [isKyc1Complete, isUserVerified, isEntity, isSubmitted],
  );

  const handleSaveAndExit = useCallback(() => {
    applicationExited(stepId);
    navigate(NavigationURLs.Dashboard);
  }, [applicationExited, navigate, stepId]);

  const onCancelApplication = useCallback(async () => {
    setIsLoading(true);
    setCancelApplicationOpen(false);
    applicationCancelled(stepId);
    try {
      await api.endpoints.cancelEntityApplicationForm();
      addToastMessage({
        severity: 'success',
        message: 'Successfully cancelled application.',
      });
      updateApplyForEntityAccountFlag(false);
      setIsLoading(false);
      navigate(NavigationURLs.Dashboard);
    } catch (e) {
      addToastMessage({
        severity: 'error',
        message: 'Failed to cancel application. Please try again.',
      });
      setIsLoading(false);
    }
  }, [addToastMessage, applicationCancelled, navigate, stepId, updateApplyForEntityAccountFlag]);

  if (isXs) {
    return (
      <>
        <FlexLayout direction='row' spacing={8} className='cursor-pointer'>
          {canDeleteEntity && (
            <Button
              variant='ghost'
              onClick={() => setCancelApplicationOpen(true)}
              leadingIcon={<Delete />}
              layout='icon'
            />
          )}
          <Button variant='ghost' onClick={handleSaveAndExit} leadingIcon={<Cross />} layout='icon' />
        </FlexLayout>
        <CancelEntityApplicationModal
          open={cancelApplicationOpen}
          setOpen={setCancelApplicationOpen}
          onConfirm={onCancelApplication}
        />
      </>
    );
  }

  return (
    <>
      <FlexLayout direction='row' spacing={8} className='items-center'>
        {canDeleteEntity && (
          <Button
            variant='ghost'
            leadingIcon={<Delete />}
            loading={isLoading}
            onClick={() => setCancelApplicationOpen(true)}
          >
            Cancel application
          </Button>
        )}

        <Button variant='outlined' onClick={handleSaveAndExit} trailingIcon={<ArrowLineOnlyRight />}>
          Save and exit
        </Button>
      </FlexLayout>
      <CancelEntityApplicationModal
        open={cancelApplicationOpen}
        setOpen={setCancelApplicationOpen}
        onConfirm={onCancelApplication}
      />
    </>
  );
});

export { HeaderExitButton };
