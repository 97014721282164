import { useMemo } from 'react';

import { EntityApplicationForm } from '@shared/api';

import { useEntityOnboardingSelector } from '@routes/EntityOnboarding/EntityOnboarding.context';

type Document = {
  title: string;
  detail?: string;
  content: JSX.Element;
};

type SupportingDocuments = {
  requiredDocuments: Document[];
  optionalDocuments: Document[];
};

const companyExtract = {
  title: 'Company Extract',
  detail: '(from ASIC or NZ Register)',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const corporateCompanyExtract = {
  title: '1x Company Extract of the business applying for entity account',
  detail: '(from ASIC or NZ Register)',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const shareholderCompanyExtract = {
  title: '1x Company Extract for every company added as a shareholder',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const multiCompanyExtract = {
  title: 'Company Extract',
  detail: '(one per company, from ASIC or NZ Register)',
  content: (
    <img
      src='assets/images_new/entity_application/company_extract.jpg'
      alt='Company Extract Example'
      className='h-[60vh]'
    />
  ),
};

const smsfTrustDeed = {
  title: 'Executed SMSF Trust Deed',
  content: (
    <img src='assets/images_new/entity_application/trust_deed.jpg' alt='Trust Deed Example' className='h-[60vh]' />
  ),
};

const trustDeed = {
  title: 'Executed Trust Deed',
  content: (
    <img src='assets/images_new/entity_application/trust_deed.jpg' alt='Trust Deed Example' className='h-[60vh]' />
  ),
};

const deedOfVariation = {
  title: 'Executed Deed of Variation',
  content: (
    <img
      src='assets/images_new/entity_application/deed_of_variation.jpg'
      alt='Deed of Variation Example'
      className='h-[60vh]'
    />
  ),
};

const rolloverStatement = {
  title: 'Rollover statement',
  content: (
    <img
      src='assets/images_new/entity_application/rollover_statement.jpg'
      alt='Rollover Statement Example'
      className='h-[60vh]'
    />
  ),
};

export const useDocumentSuggestions = (): SupportingDocuments => {
  const applicationData = useEntityOnboardingSelector(
    (state) => state.context.applicationData,
  ) as EntityApplicationForm;
  const entityType = applicationData.type;

  return useMemo<SupportingDocuments>(() => {
    const requiredDocuments: Document[] = [];
    const optionalDocuments: Document[] = [];
    if (entityType === 'COMPANY') {
      const shareholders = applicationData?.shareholders;
      const isCompanyShareholder = shareholders.some((shareholder) => shareholder.type === 'CORPORATE');
      if (isCompanyShareholder) {
        requiredDocuments.push(corporateCompanyExtract, shareholderCompanyExtract);
      } else {
        requiredDocuments.push(companyExtract);
      }
    }

    if (entityType === 'SMSF_INDIVIDUAL') {
      requiredDocuments.push(smsfTrustDeed);
      optionalDocuments.push(rolloverStatement);
    }
    if (entityType === 'SMSF_CORPORATE') {
      requiredDocuments.push(smsfTrustDeed, multiCompanyExtract);
      optionalDocuments.push(rolloverStatement);
    }
    if (entityType === 'TRUST_CORPORATE') {
      requiredDocuments.push(trustDeed, multiCompanyExtract);
      optionalDocuments.push(deedOfVariation);
    }
    if (entityType === 'TRUST_INDIVIDUAL') {
      requiredDocuments.push(trustDeed, deedOfVariation);
    }
    return { requiredDocuments, optionalDocuments };
  }, [entityType, applicationData]);
};
