import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { ButtonProps } from '@swyftx/aviary/atoms/Button/Button.styles';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { ChipProps } from '@swyftx/aviary/atoms/Chip/Chip.styles';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Locked } from '@swyftx/aviary/icons/outlined';

import { UserStore } from '@shared/store';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import {
  actionRequired,
  hasStarted,
  inProgress,
  isLocked,
  isValidCountry,
  isVerified,
  stepsPending,
  hasCompletedGreenID,
} from './VerifyIdentity.util';

export const VerifyIdentity: React.FC = observer(() => {
  const { jumioTransaction } = useContext(ProfileVerificationContext);
  const { userProfile, isKyc1Complete } = UserStore.useUserStore;
  const { t } = useTranslation('profile.verification');
  const { navigate } = useNavigateRoute();

  const locked: boolean = isLocked(userProfile);
  const isInProgress: boolean = inProgress(userProfile, jumioTransaction);
  const hasStartedVerification: boolean = hasStarted(userProfile, jumioTransaction);
  const hasStepsPending: boolean = stepsPending(userProfile, jumioTransaction, isKyc1Complete);
  const hasFinishedGreenID = hasCompletedGreenID(userProfile);

  const isActionRequired: boolean = actionRequired(userProfile, jumioTransaction, isKyc1Complete);
  const verified: boolean = isVerified(userProfile, jumioTransaction);
  const userCountry = userProfile?.address?.country;

  const onVerifyNow = useCallback(() => {
    navigate(NavigationURLs.Onboarding);
  }, [navigate]);

  const buildChip: () => ChipProps | undefined = () => {
    if (!isValidCountry(userCountry)) {
      return {
        color: 'destructive',
        children: t('verificationMethod.verifyIdentity.buttonLabels.invalidCountry', { code: userCountry }),
      };
    }
    if (locked || !hasStartedVerification) return undefined;
    if (isActionRequired) {
      return { color: 'destructive', children: t('verificationMethod.verifyIdentity.buttonLabels.actionRequired') };
    }
    if (hasStepsPending && !hasFinishedGreenID) {
      return { color: 'info', children: t('verificationMethod.verifyIdentity.buttonLabels.stepsPending') };
    }
    return { color: 'success', children: t('verificationMethod.verifyIdentity.buttonLabels.verified') };
  };

  const buildButton: () => ButtonProps | undefined = () => {
    if (verified || !isValidCountry(userCountry) || hasFinishedGreenID) return undefined;
    if (locked) {
      return {
        disabled: true,
        children: t('verificationMethod.verifyIdentity.buttonLabels.locked'),
        leadingIcon: <Locked />,
      };
    }

    let children;
    if (!hasStartedVerification) {
      children = t('verificationMethod.verifyIdentity.buttonLabels.startVerification');
    } else if (isActionRequired) {
      children = t('verificationMethod.verifyIdentity.buttonLabels.resolve');
    } else if (isInProgress || hasStepsPending) {
      children = t('verificationMethod.verifyIdentity.buttonLabels.resume');
    }

    return {
      onClick: () => onVerifyNow(),
      children,
    };
  };

  const chip = buildChip();
  const button = buildButton();

  return (
    <FlexLayout className='items-center' spacing={8}>
      {chip && (
        <Chip size='sm' id='label' variant='subtle' color={chip.color}>
          {chip.children}
        </Chip>
      )}
      {button && (
        <Button disabled={button.disabled} onClick={button.onClick} leadingIcon={button.leadingIcon}>
          {button.children}
        </Button>
      )}
    </FlexLayout>
  );
});
