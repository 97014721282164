import React, { useMemo, useState } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode';

import { RecurringOrder, RecurringOrderStatus } from '@shared/api/@types/recurringOrder';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { AutoInvestOrdersEmpty } from './AutoInvestOrdersEmpty';
import { useFetchAutoInvest } from '../../hooks';
import { AutoInvestOrderItem } from '../AutoInvestOrderItem';

type Props = {
  isDemo: boolean;
  disabledTooltip?: string;
};

const AutoInvestOrders: React.FC<Props> = observer(({ isDemo, disabledTooltip }) => {
  const { getUniqueIdentifier } = UserStore.useUserStore;
  const [tabValue, setTabValue] = useState<RecurringOrderStatus>(RecurringOrderStatus.Active);
  const { autoInvestments, isFetching } = useFetchAutoInvest({ userId: getUniqueIdentifier() });
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isXs = useTailwindBreakpoint('xs');

  const hasAutomatedOrders = useMemo(() => (autoInvestments?.length || 0) > 0, [autoInvestments?.length]);

  const automatedOrders = useMemo(
    // legacy recurring orders before API changes don't have a status
    () =>
      autoInvestments?.filter((a) => {
        if (tabValue === RecurringOrderStatus.Active) {
          return !a.status || (a.status !== RecurringOrderStatus.Paused && a.status !== RecurringOrderStatus.Expired);
        } else if (tabValue === RecurringOrderStatus.Paused) {
          return a.status === RecurringOrderStatus.Paused;
        }
        return false;
      }),
    [autoInvestments, tabValue],
  );

  const actions = useMemo(() => {
    if (!hasAutomatedOrders || !isFeatureEnabled(AppFeature.AutoInvestFromBalances)) return undefined;

    return (
      <EnhancedTabs
        fullWidth={isXs}
        size={isXs ? 'md' : 'sm'}
        value={tabValue}
        onChange={setTabValue}
        tabs={[
          {
            title: 'Active',
            value: RecurringOrderStatus.Active,
          },
          {
            title: 'Paused',
            value: RecurringOrderStatus.Paused,
          },
        ]}
      />
    );
  }, [hasAutomatedOrders, isFeatureEnabled, isXs, tabValue]);

  if (isDemo) {
    return (
      <Card className='py-32'>
        <UnavailableDemoMode subTitle='Unfortunately this feature is not supported while using demo mode.' showImage />
      </Card>
    );
  }

  return (
    <ElementContainer
      title='Your automated orders'
      titleAlignment='start'
      titleItemsAlignment={isXs ? 'start' : 'center'}
      cardWrapper={false}
      actions={actions}
      titleDirection={isXs ? 'column' : 'row'}
      className='pt-24 md:pt-0'
    >
      {isFetching && !hasAutomatedOrders && (
        <FlexLayout direction='column' spacing={24}>
          <div className='h-[256px] w-full animate-pulse rounded-16 bg-color-background-surface-secondary' />
          <div className='h-[256px] w-full animate-pulse rounded-16 bg-color-background-surface-secondary' />
          <div className='h-[256px] w-full animate-pulse rounded-16 bg-color-background-surface-secondary' />
        </FlexLayout>
      )}

      {(!hasAutomatedOrders || !automatedOrders?.length) && !isFetching && (
        <Card className='px-24 py-48'>
          <AutoInvestOrdersEmpty
            tabValue={tabValue}
            hasAutomatedOrders={hasAutomatedOrders}
            disabledTooltip={disabledTooltip}
          />
        </Card>
      )}

      {hasAutomatedOrders && automatedOrders && (
        <FlexLayout direction='column' spacing={24}>
          {automatedOrders.map((autoInvestment: RecurringOrder) => (
            <AutoInvestOrderItem
              autoInvestment={autoInvestment}
              key={`${autoInvestment.templateUuid}_${autoInvestment.source}`}
            />
          ))}
        </FlexLayout>
      )}
    </ElementContainer>
  );
});

export { AutoInvestOrders };
