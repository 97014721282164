import React, { PropsWithChildren } from 'react';

import { ArrowLineOnlyLeft, Cross } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Dialog from '@radix-ui/react-dialog';

import { ModalProps, modalCardVariants, modalVariants } from './Modal.styles';
import { Button } from '../Button';
import { Card, CardHeader } from '../Card';
import { FlexLayout } from '../Layout/Flex';
import { Heading, Utility } from '../Typography';

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  open,
  triggerElement,
  title,
  subTitle,
  showCloseButton = title !== undefined,
  onClose,
  onOpenChange,
  position = 'center',
  className,
  headerElement,
  contentClassName,
  overlayClassName,
  scrollClassName,
  container,
  children,
}) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    {triggerElement && <Dialog.Trigger asChild>{triggerElement}</Dialog.Trigger>}
    <Dialog.Portal container={container}>
      <Dialog.Overlay
        onClick={onClose}
        className={cn(
          'fixed left-0 top-0 z-modal-backdrop h-full w-full bg-black opacity-50',
          position === 'fullscreen' ? 'hidden md:block' : '',
          overlayClassName,
        )}
      />
      <Dialog.Content
        onInteractOutside={(e) => e.preventDefault()}
        className={cn(
          modalVariants({ position }),
          contentClassName,
          position === 'bottom' ? 'transition-transform data-[state=open]:animate-[slide-down-up_.3s_forwards]' : '',
          position === 'dynamic'
            ? 'transition-transform data-[state=open]:animate-[slide-down-up_.3s_forwards]  md:data-[state=open]:animate-none'
            : '',
          position === 'fullscreen'
            ? 'transition-transform data-[state=open]:animate-[slide-right-left_.3s_forwards]  md:data-[state=open]:animate-none'
            : '',
        )}
      >
        <Card className={cn(modalCardVariants({ position }), className)} border={false}>
          {(title || showCloseButton) && (
            <CardHeader className={position === 'bottom' ? 'p-16 @md:p-24 @md:py-18' : 'p-16 md:p-24'}>
              <FlexLayout
                alignItems='center'
                className='w-full'
                justifyContent={title ? 'space-between' : 'end'}
                spacing={24}
              >
                {position === 'fullscreen' && (
                  <Dialog.Close asChild className='flex w-[90px] justify-start md:hidden'>
                    <span>
                      <Button
                        onClick={onClose}
                        className='block md:hidden'
                        variant='ghost'
                        layout='icon'
                        leadingIcon={<ArrowLineOnlyLeft className='h-20 w-20' />}
                        aria-label='Close'
                      />
                    </span>
                  </Dialog.Close>
                )}
                {title && (
                  <FlexLayout direction='column' spacing={0} className='items-center md:items-start'>
                    <Heading size='h4'>{title}</Heading>
                    {subTitle && <Utility variant='caption'>{subTitle}</Utility>}
                  </FlexLayout>
                )}
                {position === 'fullscreen' && <span className='block min-w-[90px] md:hidden'>{headerElement}</span>}
                <Dialog.Close asChild>
                  <Button
                    className={cn(
                      showCloseButton ? '' : 'invisible',
                      position === 'fullscreen' ? 'hidden md:block' : '',
                    )}
                    onClick={onClose}
                    variant='outlined'
                    layout='icon'
                    leadingIcon={<Cross />}
                    aria-label='Close'
                  />
                </Dialog.Close>
              </FlexLayout>
            </CardHeader>
          )}
          <div className={cn('h-full overflow-auto', scrollClassName)}>{children}</div>
        </Card>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export { Modal };
