import { cva } from 'cva';

export const tabsListVariants = cva('w-full !bg-transparent p-0 flex flex-row', {
  variants: {
    variant: {
      parent: 'outline-b rounded-[0px] py-0',
      default: '!bg-color-background-surface-secondary',
      child:
        'bg-color-background-none gap-8 hover:bg-color-background-none text-color-text-primary outline outline-1 !outline-color-border-main hover:outline-color-border-hover active:outline-color-border-active active:bg-color-background-none',
      header:
        'bg-color-background-none gap-8 hover:bg-color-background-none text-color-text-secondary  outline outline-1 !outline-color-border-main hover:outline-color-border-hover active:outline-color-border-active data-[state=active]:bg-color-background-none',
    },
    fullWidth: {
      true: 'w-full',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'child',
  },
});

export const tabsTriggerVariants = cva('w-full !shadow-none py-0', {
  variants: {
    variant: {
      parent: '!bg-transparent border-b data-[state=active]:border-color-border-active rounded-[0px]',
      default: 'm-4 data-[state=active]:!outline focus-visible:!ring-offset-0 !outline-color-border-main !border-none',
      child:
        'rounded-[20px] text-color-text-primary !outline-color-border-main duration-500 data-[state=inactive]:!outline focus:!outline hover:bg-color-background-surface-hover',
      standalone:
        'rounded-[20px] text-color-text-primary !outline-color-border-main duration-500 data-[state=active]:bg-color-background-primary-subtle data-[state=active]:text-color-text-accent data-[state=inactive]:!outline focus:!outline',
      header:
        'uppercase data-[state=active]:text-color-text-accent data-[state=active]:bg-color-background-none hover:text-color-text-selected data-[state=active]:font-600 font-400 !p-0 mr-16',
    },
    color: {
      default: '',
      success: '',
      accent: '',
      error: '',
    },
    size: {
      sm: 'h-24',
      md: 'py-8',
    },
    fullWidth: {
      true: 'w-full',
      false: '',
    },
    disabled: {
      true: 'cursor-not-allowed',
      false: '',
    },
    selected: {
      tue: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'child',
      selected: true,
      class: 'data-[state=active]:bg-color-text-primary data-[state=active]:text-color-text-inverse',
    },
    {
      variant: 'default',
      color: 'default',
      class: 'data-[state=active]:!bg-color-background-surface-primary',
    },
    {
      variant: 'default',
      color: 'success',
      class: 'data-[state=active]:!bg-color-background-success data-[state=active]:!text-color-text-inverse ',
    },
    {
      variant: 'default',
      color: 'error',
      class: 'data-[state=active]:!bg-color-background-error data-[state=active]:!text-color-text-inverse ',
    },
    {
      variant: 'default',
      color: 'accent',
      class: 'data-[state=active]:!bg-color-background-info data-[state=active]:!text-color-text-inverse ',
    },
  ],
  defaultVariants: {
    variant: 'child',
    size: 'md',
    color: 'default',
  },
});
