import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { useQuery } from '@tanstack/react-query';

type Props = {
  primary?: number;
  secondary?: number | number[];
};

const useFetchDynamicPriceAlerts = ({ primary, secondary }: Props = {}) => {
  const { getUniqueIdentifier } = UserStore.useUserStore;

  const fetchDynamicPriceAlerts = useCallback(async () => {
    const response = await api.endpoints.getPriceAlerts({
      query: { primary, secondary, status: ['WAITING', 'PAUSED'] },
    });

    return response.data;
  }, [primary, secondary]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['dynamic-price-alerts', primary, secondary, getUniqueIdentifier()],
    queryFn: fetchDynamicPriceAlerts,
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { status, data, error, isFetching, isPreviousData, isStale };
};

export { useFetchDynamicPriceAlerts };
