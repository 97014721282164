import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import {
  Asset,
  AssetHistoryItem,
  AssetHistoryItemStatus,
  AssetType,
  OrderSource,
  SortDirection,
  SortKey,
  TransactionType,
} from '@shared/api';
import { Big } from '@shared/safe-big';
import { TransactionHistoryItem } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import {
  TransactionHistorySides,
  TransactionHistoryStatuses,
  TransactionHistoryTab,
  TransactionsPageTableData,
} from '@routes/Transactions/types';

import { DateTime } from 'luxon';
import { OrderActions } from 'src/lib/UniversalTrade/components/OrdersTile/tableItems/OrderActions';
import { getOrderSide, getOrderType } from 'src/lib/UniversalTrade/components/OrdersTile/utils';
import { getGroupByTitle } from 'src/lib/dynamic-price-alerts/utils/dynamic-price-alerts.utils';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { TransactionsAmount, TransactionsDate } from 'src/lib/transactions/components';
import { TransactionsDownloadInvoice } from 'src/lib/transactions/components/TransactionsDownloadInvoice/TransactionsDownloadInvoice';
import { useInfiniteTransactions } from 'src/lib/transactions/hooks/useInfiniteTransactions';
import { useTransactionDetails } from 'src/lib/transactions/hooks/useTransactionDetails';

type Props = {
  asset?: Asset;
  sides: TransactionHistorySides[];
  statuses: TransactionHistoryStatuses[];
  type: TransactionHistoryTab;
  startDate?: DateTime;
  endDate?: DateTime;
  deps?: any[];
};

type TransactionsHeaderData = { [key in keyof TransactionsPageTableData]: EnhancedTableHeaderData };

const useTransactionsPageTable = ({ asset, sides, statuses, type, startDate, endDate }: Props) => {
  const { t } = useTranslation('orders');
  const { getAssetById } = useMarkets();
  const [sort, setSort] = useState<EnhancedTableSort<TransactionsPageTableData>>({
    sortKey: 'date',
    sortDirection: 'DESC',
  });

  const transactionStatuses = useMemo(
    (): AssetHistoryItemStatus[] =>
      statuses.map((status) => {
        switch (status) {
          case 'completed':
            return AssetHistoryItemStatus.Completed;
          case 'failed':
            return AssetHistoryItemStatus.Failed;
          case 'open':
          case 'pending':
            return AssetHistoryItemStatus.Pending;
        }
      }),
    [statuses],
  );

  const transactionTypes = useMemo((): TransactionType[] => {
    const types: TransactionType[] = [];
    switch (type) {
      case 'deposits':
        types.push(TransactionType.Deposit);
        break;
      case 'withdrawals':
        types.push(TransactionType.Withdrawal);
        break;
      case 'orders':
        if (!sides.length || sides.includes('buy')) types.push(TransactionType.Buy);
        if (!sides.length || sides.includes('sell')) types.push(TransactionType.Sell);
        break;
    }

    return types;
  }, [sides, type]);

  const { transactions, fetchNextPage, isFetchingNextPage, isFetching, hasNextPage } = useInfiniteTransactions({
    asset,
    sortKey: sort.sortKey as SortKey,
    sortDirection: sort.sortDirection as SortDirection,
    statuses: transactionStatuses,
    types: transactionTypes,
    startDate: startDate?.toMillis() || undefined,
    endDate: endDate?.toMillis() || undefined,
  });

  const { getTransactionDetails } = useTransactionDetails();

  const headers: TransactionsHeaderData = useMemo(
    () => ({
      date: {
        title: 'Date',
        alignment: 'start',
        insetLeft: true,
        sortable: true,
        frozen: true,
        showFrozenDivider: false,
        className: 'hidden @md:table-cell min-w-[225px]',
      },

      type: {
        title: 'Type',
        alignment: 'start',
        sortable: false,
        frozen: true,
        offsetLeft: 225,
        showFrozenDivider: type !== 'orders',
        className: 'hidden @md:table-cell min-w-[120px]',
      },

      side: {
        title: 'Side',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[60px]',
        enabled: type === 'orders',
        frozen: true,
        offsetLeft: 345,
        showFrozenDivider: type === 'orders',
      },

      status: {
        title: 'Status',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[130px]',
      },

      asset: {
        title: 'Asset',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[80px]',
        insetLeft: true,
      },

      address: {
        title: type === 'deposits' ? 'Deposited from' : 'Withdrawn to',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[200px]',
        enabled: type !== 'orders',
      },

      addressLabel: {
        title: 'Account BSB / Address',
        alignment: 'start',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[200px]',
        enabled: type !== 'orders',
      },

      orderAssetStatusType: {
        title: type === 'orders' ? 'Order/Date' : 'Date',
        sortKey: 'date',
        alignment: 'start',
        sortable: true,
        className: 'table-cell @md:hidden',
        insetRight: true,
      },

      orderPrice: {
        title: 'Order Price',
        alignment: 'end',
        sortable: false,
        className: 'hidden @sm:table-cell min-w-[150px]',
        enabled: type === 'orders',
      },

      amount: {
        title: 'Amount',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[220px]',
      },

      total: {
        title: 'Total',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell min-w-[150px]',
        enabled: type === 'orders',
      },

      fee: {
        title: 'Fee',
        alignment: 'end',
        sortable: false,
        className: 'hidden @lg:table-cell w-[120px]',
      },

      orderTotalAmount: {
        title: type === 'orders' ? 'Total/Amount' : 'Total',
        alignment: 'end',
        sortable: false,
        className: 'table-cell @md:hidden',
      },

      actions: {
        title: 'Actions',
        alignment: 'end',
        sortable: false,
        className: 'hidden @md:table-cell w-[75px]',
        insetRight: true,
        enabled: type === 'orders',
      },
    }),
    [type],
  );

  const data: EnhancedTableData<TransactionsPageTableData, AssetHistoryItem>[] = useMemo(() => {
    if (!transactions) return [];
    const pages = transactions.pages.flatMap((page) => page.transactions);

    return pages?.map((transaction: AssetHistoryItem) => {
      const details = getTransactionDetails(transaction, { noSuffix: true });
      const transactionAsset = getAssetById(transaction.secondaryAsset);
      const orderType = getOrderType(transaction.orderType);
      const orderSide = getOrderSide(transaction.orderType);
      const isOrderAssetMigration = transaction.orderSource === OrderSource.AssetMigration;

      return {
        value: transaction,
        date: {
          value: transaction.date,
          groupByTitle: getGroupByTitle(DateTime.fromMillis(transaction.date || 0)),
          element: (
            <FlexLayout justifyContent='start'>
              <TransactionsDate date={details.date} className='w-[210px] sm:w-auto' />
            </FlexLayout>
          ),
        },
        type: {
          value: {},
          element: (
            <>
              {type === 'orders' && <Body>{isOrderAssetMigration ? t('types.assetMigration') : orderType}</Body>}
              {type === 'deposits' && (
                <Body>Deposit {transactionAsset?.assetType === AssetType.Crypto ? 'crypto' : 'fiat'}</Body>
              )}
              {type === 'withdrawals' && (
                <Body>Withdraw {transactionAsset?.assetType === AssetType.Crypto ? 'crypto' : 'fiat'}</Body>
              )}
            </>
          ),
        },

        // asset migration orders should not show the order side, override with a blank state
        side: {
          value: {},
          element: isOrderAssetMigration ? (
            <Body color='disabled'>-</Body>
          ) : (
            <Body color={orderSide === 'Buy' ? 'success' : 'error'}>{orderSide}</Body>
          ),
        },

        status: {
          value: transaction.statusRaw,
          element: (
            <FlexLayout justifyContent='start'>
              <Chip size='sm' variant='subtle' color={details.status.color}>
                {details.status.status}
              </Chip>
            </FlexLayout>
          ),
        },

        asset: {
          value: {},
          element: (
            <FlexLayout alignItems='center' spacing={8}>
              <AssetIcon asset={transactionAsset} size={18} />
              <Body color='primary'>{transactionAsset?.code}</Body>
            </FlexLayout>
          ),
        },

        address: {
          value: transaction.address,
          element: (
            <FlexLayout direction='column' justifyContent='start' spacing={4}>
              {details.label ? (
                <Body color='primary' weight='emphasis' className='w-[200px] truncate'>
                  {details.label}
                </Body>
              ) : transactionAsset?.assetType === AssetType.Crypto ? (
                <Body color='primary' weight='emphasis' className='w-[200px] truncate'>
                  External wallet
                </Body>
              ) : null}
            </FlexLayout>
          ),
        },

        addressLabel: {
          value: transaction.address,
          element: (
            <FlexLayout direction='column' justifyContent='start' spacing={4}>
              {transaction.address ? (
                <FlexLayout spacing={8}>
                  <Body color='primary'>{details.addressLabel}</Body>
                </FlexLayout>
              ) : (
                <Body color='primary'>{details.label}</Body>
              )}
            </FlexLayout>
          ),
        },

        orderAssetStatusType: {
          value: transaction.date,
          element: (
            <FlexLayout direction='row' alignItems='center' spacing={4}>
              <FlexLayout direction='column' spacing={4}>
                <FlexLayout alignItems='center' spacing={8}>
                  <FlexLayout spacing={4}>
                    {type === 'orders' && (
                      <>
                        <Body color='primary' weight='emphasis'>
                          {isOrderAssetMigration ? t('types.assetMigration') : getOrderType(transaction.orderType)}
                        </Body>
                        {/* asset migration orders should not show the order side, override with a blank state*/}
                        {!isOrderAssetMigration && (
                          <Body color='primary' weight='emphasis'>
                            {orderSide}
                          </Body>
                        )}
                      </>
                    )}
                    {type !== 'orders' && (
                      <>
                        {type === 'deposits' && (
                          <Body color='primary' weight='emphasis'>
                            Deposit {transactionAsset?.assetType === AssetType.Crypto ? 'crypto' : 'fiat'}
                          </Body>
                        )}
                        {type === 'withdrawals' && (
                          <Body color='primary' weight='emphasis'>
                            Withdraw {transactionAsset?.assetType === AssetType.Crypto ? 'crypto' : 'fiat'}
                          </Body>
                        )}
                      </>
                    )}
                  </FlexLayout>
                </FlexLayout>
                <FlexLayout spacing={4}>
                  <FlexLayout spacing={8}>
                    <Chip size='sm' variant='subtle' color={details.status.color}>
                      {details.status.status}
                    </Chip>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          ),
        },

        orderPrice: {
          value: {},
          className: 'align-bottom @md:align-middle',
          element: (
            <FlexLayout justifyContent='end' alignItems='end' className='min-h-full' spacing={4}>
              <Numeric className='w-[150px] truncate text-right !text-14 @md:!text-16'>{details.triggerPrice}</Numeric>
              <Numeric color='secondary'>
                {details.primaryAssetCode}/{details.secondaryAssetCode}
              </Numeric>
            </FlexLayout>
          ),
        },

        amount: {
          value: details.amount,
          element: (
            <FlexLayout className='min-w-[220px]' justifyContent='end' spacing={4}>
              <TransactionsAmount amount={details.amount} />
              <Numeric color='secondary'>{details.amountAssetCode}</Numeric>
            </FlexLayout>
          ),
        },

        total: {
          value: transaction.orderType,
          className: 'align-top @md:align-middle',
          element: (
            <FlexLayout
              justifyContent='end'
              spacing={4}
              className={details.status.color === 'destructive' ? 'line-through decoration-1' : ''}
            >
              <Numeric>{details.total}</Numeric>
              <Numeric color='secondary'>{details.totalAssetCode}</Numeric>
            </FlexLayout>
          ),
        },

        fee: {
          value: {},
          element: (
            <Tooltip title={details.secondaryFee} align='end'>
              <div>
                <Numeric
                  className={`w-[200px] text-right ${
                    details.secondaryFee
                      ? 'relative underline decoration-color-border-main decoration-dashed decoration-1 underline-offset-4	'
                      : ''
                  }`}
                >
                  {formatCurrency(details.primaryFee, transactionAsset, { appendCode: true })}
                </Numeric>
              </div>
            </Tooltip>
          ),
        },

        orderTotalAmount: {
          className: 'align-top @md:align-middle',
          element: (
            <FlexLayout direction='column' alignItems='end' spacing={4}>
              <FlexLayout justifyContent='start' alignItems='center' spacing={4}>
                <AssetIcon asset={transactionAsset} size={16} />
                <TransactionsAmount amount={details.amount} />
                <Numeric color='primary' weight='bold'>
                  {details.amountAssetCode}
                </Numeric>
              </FlexLayout>
              <FlexLayout justifyContent='start' spacing={4}>
                <Numeric size='small' color='secondary'>
                  {details.isFiat ? '$' : '~$'}
                  {Big(details.userCountryValue).toFixed(2)}
                </Numeric>
                <Numeric size='small' color='secondary'>
                  {details.userCountryAsset?.code}
                </Numeric>
              </FlexLayout>
            </FlexLayout>
          ),
        },

        actions: {
          blockClick: true,
          element: (
            <FlexLayout justifyContent='end'>
              {transaction.status === AssetHistoryItemStatus.Completed && (
                <TransactionsDownloadInvoice orderId={transaction.uuid} />
              )}
              {transaction.status === AssetHistoryItemStatus.Pending && (
                <OrderActions order={transaction as TransactionHistoryItem} />
              )}
            </FlexLayout>
          ),
        },
      };
    });
  }, [transactions, getTransactionDetails, getAssetById, type]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<TransactionsPageTableData>) => {
      if (!newSort) return;

      setSort(newSort);
    },
    [setSort],
  );

  return {
    initialSort: sort,
    sort,
    headers,
    data,
    onSort,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  };
};

export { useTransactionsPageTable };
