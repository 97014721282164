import React from 'react';

import { VariantProps, cva } from 'cva';
// https://www.figma.com/file/bTl1BBi1Bh0ECdgyWX8GAS/Components?type=design&node-id=281-4636&mode=design&t=RufRmJAttZWOqOCd-0

export interface ModalProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>,
    VariantProps<typeof modalVariants> {
  open?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  showCloseButton?: boolean;
  triggerElement?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  overlayClassName?: string;
  scrollClassName?: string;
  container?: HTMLElement;
  headerElement?: React.ReactNode;
  onClose?: () => void;
  onOpenChange?: (open?: boolean) => void;
}

export const modalVariants = cva('fixed z-modal', {
  variants: {
    position: {
      top: '-translate-x-1/2 left-1/2 top-[15vh]',
      bottom: 'left-0 bottom-0 !rounded-b-0 w-full',
      center: '-translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2',
      dynamic:
        'left-0 bottom-0 !rounded-b-0 w-full md:w-auto md:!rounded-b-32 md:left-auto md:bottom-auto md:-translate-x-1/2 md:-translate-y-1/2 md:left-1/2 md:top-1/2',
      fullscreen:
        'left-0 bottom-0 !rounded-b-0 w-full h-[100dvh] md:h-auto md:top-auto md:w-auto md:!rounded-b-32 md:left-auto md:bottom-auto md:-translate-x-1/2 md:-translate-y-1/2 md:left-1/2 md:top-1/2',
    },
  },
  defaultVariants: {
    position: 'center',
  },
});

export const modalCardVariants = cva(
  'max-h-[80vh] h-full w-full overflow-hidden md:min-w-[450px] lg:max-h-[95vh] lg:min-w-[600px] rounded-32',
  {
    variants: {
      position: {
        top: '',
        bottom: 'rounded-b-0 rounded-t-16',
        center: '',
        dynamic: 'rounded-b-0 rounded-t-16 md:rounded-b-32 md:rounded-t-32',
        fullscreen: 'rounded-t-0 rounded-b-0 md:rounded-t-32 md:rounded-b-32 max-h-[100dvh] md:max-h-[80vh]',
      },
    },
  },
);
