import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { UserStore } from '@shared/store';

import PrivateRoute from '@routes/PrivateRoute';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { SwyftxAppShellCustomHeader } from 'src/lib/layout';
import { NavigationURLs } from 'src/lib/navigation/types';

import EntityOnboarding from './EntityOnboarding';
import { EntityOnboardingProvider } from './EntityOnboarding.context';
import { HeaderExitButton } from './components/HeaderExitButton';

const EntityOnboardingRoute: React.FC = observer(() => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isLoggedIn } = UserStore.useUserStore;

  if (!isFeatureEnabled(AppFeature.EntityOnboarding) && isLoggedIn) {
    return <Navigate to={NavigationURLs.Dashboard} />;
  }

  return (
    <SwyftxAppShellCustomHeader headerItemOverride={<HeaderExitButton />}>
      <PrivateRoute
        element={
          <EntityOnboardingProvider>
            <EntityOnboarding />
          </EntityOnboardingProvider>
        }
      />
    </SwyftxAppShellCustomHeader>
  );
});

export const EntityOnboardingRoutes: RouteObject[] = [
  {
    path: NavigationURLs.EntityOnboarding,
    element: <EntityOnboardingRoute />,
  },
];
