import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { AddInCircleFilled, CrossInCircleFilled, TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Coin, Dollar, Trade } from '@swyftx/aviary/icons/outlined';

import { FormattedText } from '@global-components/Text';

import { AssetHistoryItemStatus, OrderSource, TransactionType } from '@shared/api';
import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { formatDateTime } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { TransactionHistoryItem } from '@services/WalletService';
import { getOrderType } from '@utils/orders';

type Props = {
  asset: Asset;
  transaction: TransactionHistoryItem;
};

const SingleWalletTransactionHistoryItem: React.FC<Props> = ({ asset, transaction }) => {
  const { t } = useTranslation('orders');
  const amount = useMemo(() => {
    if (transaction.amount === '0') {
      return asset.id === transaction.primaryAsset ? transaction.primaryAmount : transaction.secondaryAmount;
    }
    return transaction.amount;
  }, [asset, transaction]);

  const secondaryAsset = useMemo(() => assetService.getAsset(transaction.secondaryAsset), [transaction]);
  const completed = useMemo(() => transaction.status === AssetHistoryItemStatus.Completed, [transaction]);
  const failed = useMemo(() => transaction.status === AssetHistoryItemStatus.Failed, [transaction]);
  const pending = useMemo(() => transaction.status === AssetHistoryItemStatus.Pending, [transaction]);
  const isDebit = useMemo(
    () =>
      transaction.type === TransactionType.Withdrawal ||
      (transaction.type === TransactionType.Sell && asset.id === transaction.secondaryAsset) ||
      (transaction.type === TransactionType.Buy && asset.id !== transaction.secondaryAsset),
    [transaction, asset.id],
  );
  const isAssetMigration = useMemo(() => transaction.orderSource === OrderSource.AssetMigration, [transaction]);

  const title = useMemo(
    () =>
      isAssetMigration ? t('types.assetMigration') : getOrderType(transaction, asset.assetType === AssetType.Fiat),
    [transaction, asset],
  );

  return (
    <>
      <FlexLayout direction='row' alignItems='center' className='h-[60px] p-16'>
        <FlexLayout
          direction='row'
          alignItems='center'
          spacing={8}
          justifyContent='start'
          className={cn('w-1/2 text-left @md:w-1/4')}
        >
          <Avatar color='secondary'>
            {isAssetMigration ? (
              <Trade className={cn('h-12 w-12 text-color-text-primary @md:h-18 @md:w-18')} />
            ) : (
              <>
                {isDebit && <Dollar className={cn('h-12 w-12 text-color-text-primary @md:h-18 @md:w-18')} />}
                {!isDebit && <Coin className={cn('h-12 w-12 text-color-text-primary @md:h-18 @md:w-18')} />}
              </>
            )}
          </Avatar>
          <FlexLayout direction='column'>
            <FlexLayout direction='row' alignItems='center' spacing={8} className='w-full'>
              <Body
                size='small'
                color='primary'
                className='max-w-[85px] truncate sm:max-w-[150px] xl:max-w-[250px]'
                title={title}
              >
                {title}{' '}
                {secondaryAsset &&
                  secondaryAsset.id !== asset.id &&
                  [TransactionType.Buy, TransactionType.Sell].includes(transaction.type) &&
                  secondaryAsset.code}
              </Body>
              {completed && <TickInCircleFilled className='h-16 w-16 text-color-text-success' />}
              {failed && <CrossInCircleFilled className='h-16 w-16 text-color-text-error' />}
              {pending && <AddInCircleFilled className='h-16 w-16 text-color-text-info' />}
            </FlexLayout>

            <Numeric size='small' color='secondary'>
              {formatDateTime(new Date(transaction.date)).toUpperCase()}
            </Numeric>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' className='flex w-1/4 text-right @md:hidden'>
          <FormattedText
            formatBalance
            prefix={isDebit ? '-' : '+'}
            typographyProps={{
              color: failed || pending ? 'secondary' : isDebit ? 'error' : 'success',
              size: 'small',
              className: failed ? 'line-through' : '!text-12 @md:!text-14',
            }}
            value={amount}
            currency={asset}
          />
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' className='hidden w-1/4 text-right @md:flex'>
          {!isDebit && (
            <FormattedText
              prefix='+'
              typographyProps={{
                color: failed || pending ? 'secondary' : 'success',
                className: failed ? 'line-through' : '!text-12 @md:!text-14',
                size: 'small',
              }}
              value={amount}
              currency={asset}
              formatBalance
            />
          )}
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' className='hidden w-1/4 text-right @md:flex'>
          {isDebit && (
            <FormattedText
              prefix='-'
              typographyProps={{
                color: failed || pending ? 'secondary' : 'error',
                className: failed ? 'line-through' : '!text-12 @md:!text-14',
                size: 'small',
              }}
              value={amount.replace('-', '')}
              currency={asset}
              formatBalance
            />
          )}
          {transaction.type === TransactionType.Deposit && transaction.feeAmount && (
            <FormattedText
              prefix='-'
              typographyProps={{
                color: failed || pending ? 'secondary' : 'error',
                className: failed ? 'line-through' : '!text-12 @md:!text-14',
                size: 'small',
              }}
              value={transaction.feeAmount.replace('-', '')}
              currency={asset}
              formatBalance
            />
          )}
        </FlexLayout>

        <FlexLayout alignItems='center' justifyContent='end' className='w-1/2 text-right  @md:flex @md:w-1/4'>
          <FormattedText
            typographyProps={{
              color: 'primary',
              className: '!text-12 @md:!text-14 text-right whitespace-nowrap',
              size: 'small',
            }}
            value={transaction.runningTotal}
            formatBalance
            currency={asset}
          />
        </FlexLayout>
      </FlexLayout>

      <Separator className='w-full' />
    </>
  );
};

export { SingleWalletTransactionHistoryItem };
