import { useMemo } from 'react';
import { TFuncKey } from 'react-i18next';

import { AccountStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

export type VerificationFunctionality =
  | 'demoMode'
  | 'cryptoTrading'
  | 'fiat'
  | 'crypto'
  | 'cryptoDeposits'
  | 'cryptoWithdrawals'
  | 'refer'
  | 'limits'
  | 'entityAccounts'
  | 'trust';
export type VerificationRequirement = 'verifiedEmail' | 'verifiedPhone' | 'governmentId' | 'identityAuth' | 'funds';

export type VerificationLevel = {
  status: AccountStatusEnum;
  restrictedStatus: AccountStatusEnum;
  label: TFuncKey<'profile.verification'>;
  color: string;
  cardColor: string;
  functionalityUnlocked: VerificationFunctionality[];
  requirementsToUnlock: VerificationRequirement[];
  renderAsTile: boolean;
  imgSrc: string;
};

const functionalityIcons: { [key in VerificationFunctionality]: string } = {
  demoMode: 'Coin',
  cryptoTrading: 'Coin',
  fiat: 'Dollar',
  crypto: 'Coin',
  cryptoDeposits: 'Coin',
  cryptoWithdrawals: 'Coin',
  refer: 'Gift',
  limits: 'ArrowUp',
  entityAccounts: 'Coin',
  trust: 'ArrowUp',
};

const useVerificationLevels = (): VerificationLevel[] => {
  const { userProfile } = UserStore.useUserStore;
  const isLegacyUser = userProfile?.metadata?.cryptoRequiresKyc1 === false;

  return useMemo(() => {
    // legacy users have access to crypto at silver
    // - note that gold NZ isn't factored here as NZ skips silver and always required gold for crypto
    const silverFunc: VerificationFunctionality[] = isLegacyUser
      ? ['cryptoTrading', 'crypto', 'fiat']
      : ['cryptoTrading', 'cryptoDeposits', 'fiat'];
    const goldFunc: VerificationFunctionality[] = isLegacyUser
      ? ['entityAccounts', 'refer', 'trust']
      : ['entityAccounts', 'cryptoWithdrawals', 'refer', 'trust'];

    return [
      {
        status: AccountStatusEnum.BRONZE,
        restrictedStatus: AccountStatusEnum.BRONZE_RESTRICTED,
        label: 'verificationLevel.bronze.title',
        color: '#995301',
        cardColor: 'rgba(255, 162, 54, 0.08)',
        functionalityUnlocked: ['demoMode'],
        requirementsToUnlock: [],
        renderAsTile: true,
        imgSrc: 'verification_bronze',
      },
      {
        status: AccountStatusEnum.BRONZE_RESTRICTED,
        restrictedStatus: AccountStatusEnum.BRONZE_RESTRICTED,
        label: 'verificationLevel.bronze.restricted',
        color: '#995301',
        cardColor: 'rgba(255, 162, 54, 0.08)',
        functionalityUnlocked: ['demoMode'],
        requirementsToUnlock: [],
        renderAsTile: false,
        imgSrc: 'verification_bronze',
      },
      {
        status: AccountStatusEnum.SILVER,
        restrictedStatus: AccountStatusEnum.SILVER_RESTRICTED,
        label: 'verificationLevel.silver.title',
        color: '#96A1B5',
        cardColor: 'rgba(250, 250, 250, 1)',
        functionalityUnlocked: silverFunc,
        requirementsToUnlock: ['verifiedEmail', 'verifiedPhone', 'governmentId'],
        renderAsTile: true,
        imgSrc: 'verification_silver',
      },
      {
        status: AccountStatusEnum.SILVER_RESTRICTED,
        restrictedStatus: AccountStatusEnum.SILVER_RESTRICTED,
        label: 'verificationLevel.silver.restricted',
        color: '#96A1B5',
        cardColor: 'rgba(250, 250, 250, 1)',
        functionalityUnlocked: silverFunc,
        requirementsToUnlock: ['verifiedEmail', 'verifiedPhone', 'governmentId'],
        renderAsTile: false,
        imgSrc: 'verification_silver',
      },
      {
        status: AccountStatusEnum.GOLD_NZ,
        restrictedStatus: AccountStatusEnum.GOLD_NZ_RESTRICTED,
        label: 'verificationLevel.gold.title',
        color: '#997100',
        cardColor: 'rgba(255, 203, 54, 0.08)',
        functionalityUnlocked: ['cryptoTrading', 'fiat', 'crypto', 'refer', 'entityAccounts', 'trust'],
        requirementsToUnlock: ['governmentId', 'identityAuth'],
        renderAsTile: true,
        imgSrc: 'verification_gold',
      },
      {
        status: AccountStatusEnum.GOLD_NZ_RESTRICTED,
        restrictedStatus: AccountStatusEnum.GOLD_NZ_RESTRICTED,
        label: 'verificationLevel.gold.restricted',
        color: '#997100',
        cardColor: 'rgba(255, 203, 54, 0.08)',
        functionalityUnlocked: ['cryptoTrading', 'fiat', 'crypto', 'refer', 'entityAccounts', 'trust'],
        requirementsToUnlock: ['verifiedEmail', 'verifiedPhone', 'governmentId', 'identityAuth'],
        renderAsTile: false,
        imgSrc: 'verification_gold',
      },
      {
        status: AccountStatusEnum.GOLD,
        restrictedStatus: AccountStatusEnum.GOLD_RESTRICTED,
        label: 'verificationLevel.gold.title',
        color: '#997100',
        cardColor: 'rgba(255, 203, 54, 0.08)',
        functionalityUnlocked: goldFunc,
        requirementsToUnlock: ['governmentId', 'identityAuth'],
        renderAsTile: true,
        imgSrc: 'verification_gold',
      },
      {
        status: AccountStatusEnum.GOLD_RESTRICTED,
        restrictedStatus: AccountStatusEnum.GOLD_RESTRICTED,
        label: 'verificationLevel.gold.restricted',
        color: '#997100',
        cardColor: 'rgba(255, 203, 54, 0.08)',
        functionalityUnlocked: goldFunc,
        requirementsToUnlock: ['governmentId', 'identityAuth'],
        renderAsTile: false,
        imgSrc: 'verification_gold',
      },
      {
        status: AccountStatusEnum.DIAMOND,
        restrictedStatus: AccountStatusEnum.DIAMOND_RESTRICTED,
        label: 'verificationLevel.diamond.title',
        color: '#6DAFF6',
        cardColor: 'rgba(0, 114, 237, 0.08)',
        functionalityUnlocked: ['limits'],
        requirementsToUnlock: ['funds'],
        renderAsTile: true,
        imgSrc: 'verification_diamond',
      },
      {
        status: AccountStatusEnum.DIAMOND_RESTRICTED,
        restrictedStatus: AccountStatusEnum.DIAMOND_RESTRICTED,
        label: 'verificationLevel.diamond.restricted',
        color: '#6DAFF6',
        cardColor: 'rgba(0, 114, 237, 0.08)',
        functionalityUnlocked: ['limits'],
        requirementsToUnlock: ['funds'],
        renderAsTile: false,
        imgSrc: 'verification_diamond',
      },
    ];
  }, [isLegacyUser]);
};

const defaultStatusList: AccountStatusEnum[] = [
  AccountStatusEnum.BRONZE,
  AccountStatusEnum.BRONZE_RESTRICTED,
  AccountStatusEnum.SILVER,
  AccountStatusEnum.SILVER_RESTRICTED,
  AccountStatusEnum.GOLD,
  AccountStatusEnum.GOLD_RESTRICTED,
  AccountStatusEnum.DIAMOND,
  AccountStatusEnum.DIAMOND_RESTRICTED,
];

const nzStatusList: AccountStatusEnum[] = [
  AccountStatusEnum.BRONZE,
  AccountStatusEnum.BRONZE_RESTRICTED,
  AccountStatusEnum.GOLD_NZ,
  AccountStatusEnum.GOLD_NZ_RESTRICTED,
  AccountStatusEnum.DIAMOND,
  AccountStatusEnum.DIAMOND_RESTRICTED,
];

export type SelectedLevelParam = 'bronze' | 'silver' | 'gold' | 'diamond';

const urlToStatusMap: { [key in SelectedLevelParam]: AccountStatusEnum } = {
  bronze: AccountStatusEnum.BRONZE,
  silver: AccountStatusEnum.SILVER,
  gold: AccountStatusEnum.GOLD,
  diamond: AccountStatusEnum.DIAMOND,
};

export { useVerificationLevels, defaultStatusList, nzStatusList, urlToStatusMap, functionalityIcons };
