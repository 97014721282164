import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import {
  TriggerType,
  ProfitLossValueMethod,
} from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { useProfitStopLossValueCalculationMethodUI } from './ProfitStopLossValueCalculationMethod.utils';

type Props = {
  triggerType: TriggerType;
  profitLossValueMethod: ProfitLossValueMethod;
  setProfitLossValueMethod: React.Dispatch<React.SetStateAction<ProfitLossValueMethod>>;
};

const ProfitStopLossValueCalculationMethod: React.FC<Props> = ({
  triggerType,
  profitLossValueMethod,
  setProfitLossValueMethod,
}) => {
  const { label, targetLabel, triggerPriceLabel } = useProfitStopLossValueCalculationMethodUI({ triggerType });

  return (
    <FlexLayout direction='column' className='px-16' spacing={8}>
      <Body>{label}</Body>
      <FlexLayout direction='row' spacing={4}>
        <EnhancedTabs<ProfitLossValueMethod>
          value={profitLossValueMethod}
          onChange={setProfitLossValueMethod}
          tabs={[
            {
              title: targetLabel,
              value: ProfitLossValueMethod.AmountTarget,
            },
            {
              title: triggerPriceLabel,
              value: ProfitLossValueMethod.TriggerPrice,
            },
          ]}
        />
      </FlexLayout>
    </FlexLayout>
  );
};

export { ProfitStopLossValueCalculationMethod };
