import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineNotification } from '@swyftx/aviary/atoms/InlineNotification';
import { Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import Links from '@shared/constants/links';

import { checkAssetRedenomination, LimitedFunctionalityType } from '@hooks/Assets/useAssetLimitedFunctionality';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';

import { useIsFeatureEnabled } from '../../config';
import { AppFeatureData, AssetRedenominationInformation } from '../../config/featureFlags.data';

type Props = {
  assets: Asset[];
  limitedFunctionalityType: LimitedFunctionalityType;
  featureFlagAssetRedenominations?: AssetRedenominationInformation;
};

const LimitedFunctionalityNotificationItem: React.FC<Props> = ({ assets, limitedFunctionalityType }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'limitedFunctionality' });
  const { getFeatureData } = useIsFeatureEnabled();
  const featureFlagAssetRedenominations = getFeatureData(AppFeatureData.AssetRedenominationInformation);
  const { openModal } = useModal();
  const names = assets.map((a) => a.name);

  // This component is injected into the translations component which is where the text comes from
  const getLimitedNotificationItemComponent = () => {
    switch (limitedFunctionalityType) {
      case LimitedFunctionalityType.RedenominationInProgress:
      case LimitedFunctionalityType.RedenominationCompleted: {
        // TODO: this needs to be updated when the new support article is available with the correct, general link provided
        let link = Links.help.lowLiquidityAndLimited;

        // If there is only one asset, we can use the redenomination link directly, otherwise we need to use the general support link
        if (assets.length === 1) {
          const redenomMessaging = checkAssetRedenomination(assets[0], featureFlagAssetRedenominations);
          // if no redenom specific link is provided by the FF, use the general support link
          if (redenomMessaging?.linkToFurtherDetails) {
            link = redenomMessaging?.linkToFurtherDetails;
          }
        }

        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      }
      case LimitedFunctionalityType.LowLiquidity:
      case LimitedFunctionalityType.Sending:
      case LimitedFunctionalityType.Receiving:
      case LimitedFunctionalityType.SendingReceiving:
      case LimitedFunctionalityType.Buy:
      case LimitedFunctionalityType.Sell:
      case LimitedFunctionalityType.Trade:
        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() => window.open(Links.help.lowLiquidityAndLimited, '_blank', 'noopener,noreferrer')}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      case LimitedFunctionalityType.HighSlippage:
        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() => openModal(Modals.HighSlippageReviewModal, { showActions: false })}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      default:
        limitedFunctionalityType as never;
        return <></>;
    }
  };

  return (
    <InlineNotification severity='warning' className='full-width'>
      <Typography fontWeight={400} fontSize={12} marginTop='-1px !important' width='85%'>
        <Trans
          t={t}
          i18nKey={`labels.${limitedFunctionalityType}${names.length > 1 ? 'multiple' : ''}`}
          values={{ assetNames: names.join(', '), slippageThreshold: HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD }}
          components={[
            <Typography fontWeight={600} display='inline' fontSize={12} key='limitedFunctionalityNotificationItem' />,
            getLimitedNotificationItemComponent(),
          ]}
        />
      </Typography>
    </InlineNotification>
  );
};

export { LimitedFunctionalityNotificationItem };
