import { useMemo } from 'react';

import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { useFetchCurrentUserFees } from './useFetchCurrentUserFees';

const DEFAULT_TRADE_FEE = 0.6;

const useCurrentUserFees = () => {
  const { getUniqueIdentifier } = UserStore.useUserStore;
  const { data } = useFetchCurrentUserFees({ userId: getUniqueIdentifier() });

  const lowestFee = useMemo(() => {
    if (!data?.fees) return Big(DEFAULT_TRADE_FEE).div(100);

    return Big(
      data.fees.reduce(
        (lowest, current) => (current.feePercentage < lowest ? current.feePercentage : lowest),
        DEFAULT_TRADE_FEE,
      ),
    ).div(100);
  }, [data]);

  return { lowestFee };
};

export { useCurrentUserFees };
