import { DepositStatusEnum, OrderStatusEnum, WithdrawalStatusEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';

export interface TradePriceHistory {
  [key: string]: {
    avgPricePaid: string;
  };
}

// DO NOT change the type id which should match the orderType enum on the backend
export enum OrderType {
  MarketBuy = 1,
  MarketSell = 2,
  TriggerBuy = 3,
  TriggerSell = 4,
  StopBuy = 5,
  StopSell = 6,
  DustSell = 8,
  OTCBuy = 9,
  OTCSell = 10,
}

// DO NOT change the source id which should match the orderSource enum on the backend
// See: https://github.com/Swyftx/Swyftx/blob/main/lib/enums/lib/orderSource.ts#L6-L15
export enum OrderSource {
  Quickbuy = 1,
  Otc = 2,
  Swap = 3,
  MultiBuy = 4,
  RecurringOrderBalance = 5,
  RecurringOrderDeposit = 6,
  ParentOrder = 7,
  AssetMigration = 8,
}

export enum TransactionType {
  Buy = 'BUY',
  Sell = 'SELL',
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT',
  Staking = 'STAKING',
}

export enum AssetHistoryItemStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

export enum SortKey {
  Date = 'date',
  Amount = 'amount',
  UserCountryCurrency = 'userCountryValue',
}

export enum SortDirection {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export enum HistoryOrderActionType {
  MarketBuy = 'Market Buy',
  MarketSell = 'Market Sell',
  TriggerBuy = 'Trigger Buy',
  TriggerSell = 'Trigger Sell',
  StopBuy = 'Stop Buy',
  StopSell = 'Stop Sell',
  OTCBuy = 'OTC Buy',
  OTCSell = 'OTC Sell',
}

export enum HistoryOrderStatus {
  Complete = 'Complete',
  Pending = 'Pending',
  Failed = 'Failed',
}

export interface OrderHistoryResponse {
  orders: HistoricalOrder[];
}

export const orderType = {
  0: 'types.na',
  1: 'types.marketBuy',
  2: 'types.marketSell',
  3: 'types.triggerBuy',
  4: 'types.triggerSell',
  5: 'types.stopBuy',
  6: 'types.stopSell',
  8: 'types.dustSell',
  9: 'types.otcBuy',
  10: 'types.otcSell',
};

export const orderStatusColors: { [key: number]: 'primary' | 'error' | 'success' } = {
  [OrderStatusEnum.OPEN]: 'primary',
  [OrderStatusEnum.INSUFFICIENT_BALANCE]: 'error',
  [OrderStatusEnum.PARTIAL_FILL]: 'primary',
  [OrderStatusEnum.FILLED]: 'success',
  [OrderStatusEnum.PENDING]: 'primary',
  [OrderStatusEnum.USER_CANCELLED]: 'error',
  [OrderStatusEnum.FAILED]: 'error',
  [OrderStatusEnum.SYSTEM_CANCELLED]: 'error',
  [OrderStatusEnum.FAILED_MIN_AMOUNT]: 'error',
  [OrderStatusEnum.REFUNDED]: 'error',
  [OrderStatusEnum.FAILED_MAX_AMOUNT]: 'error',
};

export const orderStatus: { [key: number]: string } = {
  [OrderStatusEnum.OPEN]: 'status.open',
  [OrderStatusEnum.INSUFFICIENT_BALANCE]: 'status.cancelled',
  [OrderStatusEnum.PARTIAL_FILL]: 'status.partialFill',
  [OrderStatusEnum.FILLED]: 'status.completed',
  [OrderStatusEnum.PENDING]: 'status.processing',
  [OrderStatusEnum.USER_CANCELLED]: 'status.userCancelled',
  [OrderStatusEnum.FAILED]: 'status.error',
  [OrderStatusEnum.SYSTEM_CANCELLED]: 'status.systemCancelled',
  [OrderStatusEnum.FAILED_MIN_AMOUNT]: 'status.failedMinAmount',
  [OrderStatusEnum.REFUNDED]: 'status.refunded',
  [OrderStatusEnum.FAILED_MAX_AMOUNT]: 'status.error',
};

export const depositStatusColors: { [key: number]: 'primary' | 'error' | 'success' } = {
  [DepositStatusEnum.PENDING]: 'primary',
  [DepositStatusEnum.CREDITED]: 'success',
  [DepositStatusEnum.FAILED_REJECTED]: 'error',
  [DepositStatusEnum.FAILED_UNKNOWN]: 'error',
  [DepositStatusEnum.FAILED_MIN_DEPOSIT]: 'error',
  [DepositStatusEnum.PENDING_FIAT_CONVERTED]: 'primary',
  [DepositStatusEnum.PENDING_DAILY_LIMIT]: 'primary',
  [DepositStatusEnum.PENDING_NOT_CREDITED]: 'primary',
  [DepositStatusEnum.PENDING_EXTENDED_VERIFICATION]: 'primary',
  [DepositStatusEnum.REFUNDED]: 'error',
  [DepositStatusEnum.PENDING_DISABLED]: 'primary',
  [DepositStatusEnum.PENDING_TERMINAL]: 'primary',
  [DepositStatusEnum.CANCELLED]: 'error',
  [DepositStatusEnum.UNSEEN]: 'primary',
  [DepositStatusEnum.NOT_VERIFIED]: 'primary',
  [DepositStatusEnum.POLI_CLAWED_BACK]: 'error',
};

export const depositStatus: { [key: number]: string } = {
  [DepositStatusEnum.PENDING]: 'status.pending',
  [DepositStatusEnum.CREDITED]: 'status.completed',
  [DepositStatusEnum.FAILED_REJECTED]: 'status.failed',
  [DepositStatusEnum.FAILED_UNKNOWN]: 'status.failed',
  [DepositStatusEnum.FAILED_MIN_DEPOSIT]: 'status.failed',
  [DepositStatusEnum.PENDING_FIAT_CONVERTED]: 'status.pending',
  [DepositStatusEnum.PENDING_DAILY_LIMIT]: 'status.pending',
  [DepositStatusEnum.PENDING_NOT_CREDITED]: 'status.pending',
  [DepositStatusEnum.PENDING_EXTENDED_VERIFICATION]: 'status.pending',
  [DepositStatusEnum.REFUNDED]: 'status.refunded',
  [DepositStatusEnum.PENDING_DISABLED]: 'status.pending',
  [DepositStatusEnum.PENDING_TERMINAL]: 'status.pending',
  [DepositStatusEnum.CANCELLED]: 'status.cancelled',
  [DepositStatusEnum.UNSEEN]: 'status.processing',
  [DepositStatusEnum.NOT_VERIFIED]: 'status.pending',
  [DepositStatusEnum.POLI_CLAWED_BACK]: 'status.failed',
};

export const withdrawStatus: { [key: number]: string } = {
  [WithdrawalStatusEnum.PENDING]: 'status.pending',
  [WithdrawalStatusEnum.CREDITED]: 'status.completed',
  [WithdrawalStatusEnum.FAILED_REJECTED]: 'status.failed',
  [WithdrawalStatusEnum.FAILED_UNKNOWN]: 'status.failed',
  [WithdrawalStatusEnum.FAILED_MIN_WITHDRAWAL]: 'status.failed',
  [WithdrawalStatusEnum.PENDING_FIAT_FX]: 'status.pending',
  [WithdrawalStatusEnum.PENDING_DAILY_LIMIT]: 'status.pending',
  [WithdrawalStatusEnum.PENDING_NOT_CREDITED]: 'status.pending',
  [WithdrawalStatusEnum.PENDING_EXTENDED_VERIFICATION]: 'status.pending',
  [WithdrawalStatusEnum.REFUNDED]: 'status.refunded',
  [WithdrawalStatusEnum.PENDING_DISABLED]: 'status.pending',
  [WithdrawalStatusEnum.NOT_VERIFIED]: 'status.pending',
  [WithdrawalStatusEnum.PROCESSING]: 'status.processing',
  [WithdrawalStatusEnum.PENDING_LIQUIDITY]: 'status.pending',
  [WithdrawalStatusEnum.BOUNCED]: 'status.pending',
};
export const withdrawStatusColors: { [key: number]: 'primary' | 'error' | 'success' } = {
  [WithdrawalStatusEnum.PENDING]: 'primary',
  [WithdrawalStatusEnum.CREDITED]: 'success',
  [WithdrawalStatusEnum.FAILED_REJECTED]: 'error',
  [WithdrawalStatusEnum.FAILED_UNKNOWN]: 'error',
  [WithdrawalStatusEnum.FAILED_MIN_WITHDRAWAL]: 'error',
  [WithdrawalStatusEnum.PENDING_FIAT_FX]: 'primary',
  [WithdrawalStatusEnum.PENDING_DAILY_LIMIT]: 'primary',
  [WithdrawalStatusEnum.PENDING_NOT_CREDITED]: 'primary',
  [WithdrawalStatusEnum.PENDING_EXTENDED_VERIFICATION]: 'primary',
  [WithdrawalStatusEnum.REFUNDED]: 'error',
  [WithdrawalStatusEnum.PENDING_DISABLED]: 'primary',
  [WithdrawalStatusEnum.NOT_VERIFIED]: 'primary',
  [WithdrawalStatusEnum.PROCESSING]: 'primary',
  [WithdrawalStatusEnum.PENDING_LIQUIDITY]: 'primary',
  [WithdrawalStatusEnum.BOUNCED]: 'primary',
};

export const orderStatusColor: { [key: string]: 'primary' | 'success' | 'error' | 'warning' } = {
  PENDING: 'primary',
  FAILED: 'error',
  COMPLETED: 'success',
};

export interface HistoricalOrder {
  amount: Big | null;
  audValue: Big | null;
  created_time: number;
  feeAmount: Big | null;
  feeAsset: number | null;
  feeAudValue: Big | null;
  feeUserCountryValue: Big | null;
  order_type: OrderType;
  orderUuid: string;
  primary_asset: number;
  quantity_asset: number;
  quantity: Big;
  rate: Big | null;
  secondary_asset: number;
  status: OrderStatusEnum;
  total: Big | null;
  trigger: Big | null;
  updated_time: number;
  userCountryValue: Big | null;
  childOrders?: string[];
  linkedOrderUuid: string | null;
  order_source: OrderSource | null;
  orderSourceUuid: string | null;
  pro: boolean;
}

export interface HistoryResult {
  items: AssetHistoryItem[];
  runningTotal: Big | null;
  recordCount: number;
}

export interface HistoryOrder {
  actionType: HistoryOrderActionType;
  amount: number | string;
  asset: string;
  primaryAsset: number;
  quantity: number;
  quantityAsset: number;
  status: HistoryOrderStatus;
  trigger: number;
  updated: number;
}

export interface AssetHistoryItemAddressMetadata {
  bsb?: string;
  memo?: string;
  reference?: string;
}

export interface AssetHistoryItem {
  amount: string;
  date: number;
  feeAmount: string;
  feeUserCountryValue: string;
  secondaryAsset: number;
  primaryAsset: number;
  limitAsset: number;
  secondaryAmount: string;
  primaryAmount: string;
  userCountryValue: string;
  uuid: string;
  type: TransactionType;
  orderType: OrderType | null;
  orderSource: OrderSource | null;
  status: AssetHistoryItemStatus;
  statusRaw: number;
  label: string;
  address: string;
  addressMetadata: AssetHistoryItemAddressMetadata;
  txFeeAmount: number | null;
  trigger: string;
  childOrders?: string[];
  linkedOrderUuid: string | null;
  orderSourceUuid: string | null;
  pro?: boolean;
}

export interface AssetTransactionHistoryParams {
  assetId: number;
  page: number;
  limit: number;
  sortKey: SortKey;
  sortDirection: SortDirection;
  startDate?: number;
  endDate?: number;
  type?: string; // comma separated list of TransactionType
  status?: string; // comma separated list of AssetHistoryItemStatus
}
