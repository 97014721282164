import { useCallback } from 'react';

import { api } from '@shared/api';
import { TimesEnum } from '@shared/enums';

import { useQuery } from '@tanstack/react-query';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

type Props = {
  userId?: string;
};

const useFetchMonthlyTradeVolume = ({ userId }: Props) => {
  const { isSwyftxPro } = useSwyftxPro();

  const fetchMonthlyTradeVolume = useCallback(async () => {
    const res = await api.endpoints.get30DayTradeVolume({ query: { mode: isSwyftxPro ? 'pro' : undefined } });

    return res.data;
  }, [isSwyftxPro]);

  const { status, data, error, isFetching, isPreviousData, isStale } = useQuery({
    queryKey: ['current-user-monthly-volume', userId, isSwyftxPro],
    queryFn: () => fetchMonthlyTradeVolume(),
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE * 5,
  });

  return { status, data, error, isFetching, isPreviousData, isStale };
};

export { useFetchMonthlyTradeVolume };
