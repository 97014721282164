import React from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset, AssetType } from '@shared/api';

import { useFormatInputText } from 'src/lib/utils/hooks/useFormatInputText';

type Props = {
  onChange: (value: string) => void;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  asset: Asset;
  placeholder?: string;
  value: string;
  error?: boolean;
  forceSuffix?: string;
};

export const PriceInput: React.FC<Props> = ({
  asset,
  placeholder,
  leading,
  trailing,
  value,
  forceSuffix,
  onChange,
  error,
}) => {
  const prefix = asset.assetType === AssetType.Fiat ? '$' : '';

  const { formattedAmount, onChangeValue } = useFormatInputText({
    asset,
    onChange,
    value,
    ignoreAssetScale: false,
    blockAlphaNumericChars: true,
  });

  return (
    <Input
      value={formattedAmount}
      error={error}
      placeholder={placeholder}
      onChange={onChangeValue}
      leading={leading ? leading : <Body>{prefix}</Body>}
      trailing={trailing ? trailing : <Body>{forceSuffix ?? asset.code}</Body>}
    />
  );
};
