import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';

import {
  useProfitStopLossOrderDetails,
  useProfitStopLossAnalytics,
  useProfitStopLossOrder,
  useProfitStopLossSummary,
  useProfitStopLossTriggerType,
  useProfitStopLossValueCalculation,
  useProfitStopLossAssets,
} from '@global-components/Modals/ProfitStopLoss/ProfitStopLossOrderModal/hooks';
import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Big } from '@shared/safe-big';

import { useFetchOrder } from '@hooks/Orders';
import { useCurrentPrice } from '@hooks/Trade/useCurrentPrice';

import { observer } from 'mobx-react-lite';

import {
  ProfitStopLossTriggerType,
  ProfitStopLossValueCalculationMethod,
  ProfitStopLossValueCalculationValue,
  ProfitStopLossAmount,
  ProfitStopLossLinkedOrder,
  ProfitStopLossSummary,
  ProfitLossPlaceOrderSummary,
} from './components';

type Props = {
  orderUuid: string;
  focusedTriggerType?: TriggerType;
  onClose: () => void;
};

const ProfitStopLossOrderModal: React.FC<Props> = observer(({ orderUuid, focusedTriggerType, onClose }) => {
  const [reviewOrder, setReviewOrder] = useState<boolean>(false);
  const { order } = useFetchOrder(orderUuid);
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });

  const { trackProfitStopLossClosed, trackProfitStopLossStarted } = useProfitStopLossAnalytics();
  const { primaryAsset, secondaryAsset } = useProfitStopLossAssets(order);
  const currentPrice = useCurrentPrice(primaryAsset, secondaryAsset, 'bidPrice');
  const { triggerType, setTriggerType } = useProfitStopLossTriggerType(focusedTriggerType);
  const {
    profitLossValueMethod,
    profitLossValueMeasurement,
    profitLossValue,
    setProfitLossValueMethod,
    setProfitLossValueMeasurement,
    setProfitLossValue,
  } = useProfitStopLossValueCalculation({ currentPrice });

  const orderDetails = useProfitStopLossOrderDetails(order);

  const amount = Big(orderDetails?.amount);
  const feeAmount = Big(orderDetails?.feeAmount);
  const purchasePrice = Big(orderDetails?.purchasePrice);

  const { estimatedProfitDollars, estimatedProfitPercentage, estimatedTotal, feePercentage, triggerPrice } =
    useProfitStopLossSummary({
      profitLossValue,
      profitLossValueMeasurement,
      profitLossValueMethod,
      triggerType,
      purchasePrice: Big(orderDetails?.purchasePrice),
      amount,
      feeAmount: Big(orderDetails?.feeAmount),
    });

  const { validOrder, executingOrder, executeOrder } = useProfitStopLossOrder({
    secondaryAsset,
    primaryAsset,
    triggerType,
    currentPrice,
    amount: Big(orderDetails?.amount),
    triggerPrice,
    relatedOrderUuid: orderUuid,
    profitLossValueMethod,
    profitLossValueMeasurement,
    estimatedProfitPercentage,
    estimatedProfitDollars,
    profitLossValue,
  });

  useEffect(() => {
    trackProfitStopLossStarted();
  }, [trackProfitStopLossStarted]);

  const onCloseModal = () => {
    trackProfitStopLossClosed();
    onClose();
  };

  if (!orderDetails || !orderDetails.amount || !orderDetails.purchasePrice || !orderDetails.feeAmount) return null;

  return (
    <Modal
      id='tradePrompt-modal'
      open
      position='dynamic'
      onClose={onCloseModal}
      title={
        reviewOrder
          ? t(triggerType === TriggerType.StopLoss ? 'titles.summaryStopLoss' : 'titles.summaryProfit')
          : t('titles.triggerOrder')
      }
    >
      <FlexLayout direction='column' spacing={16} className='mb-16'>
        {reviewOrder && (
          <ProfitLossPlaceOrderSummary
            triggerType={triggerType}
            primaryAsset={primaryAsset}
            secondaryAsset={secondaryAsset}
            amount={amount}
            feePercentage={feePercentage}
            estimatedTotal={estimatedTotal}
            triggerPrice={triggerPrice}
            estimatedProfitPercentage={estimatedProfitPercentage}
            estimatedProfitDollars={estimatedProfitDollars}
          />
        )}

        {!reviewOrder && (
          <>
            <ProfitStopLossLinkedOrder
              primaryAsset={primaryAsset}
              secondaryAsset={secondaryAsset}
              amount={amount}
              purchasePrice={purchasePrice}
            />

            {/* Take Profit/ Stop loss Toggle */}
            <ProfitStopLossTriggerType
              triggerType={triggerType}
              setTriggerType={setTriggerType}
              setProfitLossValue={setProfitLossValue}
            />

            <FlexLayout
              direction='column'
              id='profit-loss-container'
              data-spotlightelementid='profit-loss-container'
              spacing={8}
            >
              {/*  Set Profit target ($,%,Price) */}
              <ProfitStopLossValueCalculationMethod
                triggerType={triggerType}
                profitLossValueMethod={profitLossValueMethod}
                setProfitLossValueMethod={setProfitLossValueMethod}
              />

              {/*  Input field $ amount, label is "Price to take Profit at (trigger price) */}
              <ProfitStopLossValueCalculationValue
                amount={amount}
                feeAmount={feeAmount}
                feePercentage={feePercentage}
                profitLossValueMeasurement={profitLossValueMeasurement}
                profitLossValueMethod={profitLossValueMethod}
                setProfitLossValueMeasurement={setProfitLossValueMeasurement}
                setProfitLossValue={setProfitLossValue}
                profitLossValue={profitLossValue}
                purchasePrice={purchasePrice}
                currentPrice={currentPrice}
                triggerPrice={triggerPrice}
                triggerType={triggerType}
                primaryAsset={primaryAsset}
                secondaryAsset={secondaryAsset}
                estimatedProfitPercentage={estimatedProfitPercentage}
              />
            </FlexLayout>

            {/* Amount of Asset */}
            <ProfitStopLossAmount secondaryAsset={secondaryAsset} amount={amount} />
          </>
        )}
        <FlexLayout direction='row' spacing={8} justifyContent='end' alignItems='center' className='w-full px-16'>
          {!reviewOrder && (
            <FlexLayout
              direction='column'
              id='profit-stop-loss-summary'
              data-spotlightelementid='profit-stop-loss-summary'
              className='w-full'
              spacing={16}
            >
              <ProfitStopLossSummary
                triggerType={triggerType}
                triggerPrice={triggerPrice}
                feePercentage={feePercentage}
                estimatedProfitPercentage={estimatedProfitPercentage}
                estimatedProfitDollars={estimatedProfitDollars}
                primaryAsset={primaryAsset}
                secondaryAsset={secondaryAsset}
                validOrder={validOrder}
              />
              <Button
                disabled={!validOrder}
                id='button-review-order'
                variant='filled'
                color='primary'
                size='lg'
                className='w-full'
                onClick={() => setReviewOrder(true)}
              >
                {t('buttonLabels.reviewOrder')}
              </Button>
            </FlexLayout>
          )}
          {reviewOrder && (
            <>
              <Button
                id='button-review-order'
                variant='outlined'
                size='lg'
                className='w-full'
                onClick={() => setReviewOrder(false)}
              >
                {t('buttonLabels.back')}
              </Button>
              <Tooltip
                title={
                  validOrder
                    ? undefined
                    : t(
                        triggerType === TriggerType.StopLoss
                          ? 'labels.invalidTradeTooltipLower'
                          : 'labels.invalidTradeTooltipHigher',
                        { code: secondaryAsset?.code },
                      )
                }
                side='top'
              >
                <span style={{ width: '100%' }}>
                  <Button
                    disabled={!validOrder}
                    id='button-review-order'
                    variant='filled'
                    color='primary'
                    size='lg'
                    loading={executingOrder}
                    className='w-full'
                    onClick={executeOrder}
                  >
                    {t('buttonLabels.placeOrder')}
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
});

export { ProfitStopLossOrderModal };
