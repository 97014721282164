import { useCallback, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset, AssetHistoryItemStatus, SortDirection, SortKey, TransactionType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService, TransactionHistoryItem } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { useTransactionDetails } from 'src/lib/transactions/hooks/useTransactionDetails';
import { useTransactions } from 'src/lib/transactions/hooks/useTransactions';

import { OpenOrdersTableData } from '../ordersTile.types';
import { AssetName } from '../tableItems/AssetName';
import { CurrentPrice } from '../tableItems/CurrentPrice';
import { FilledAmount } from '../tableItems/FilledAmount';
import { OrderActions } from '../tableItems/OrderActions';
import { OrderDate } from '../tableItems/OrderDate';
import { OrderPrice } from '../tableItems/OrderPrice';
import { OrderQuantity } from '../tableItems/OrderQuantity';
import { OrderSide } from '../tableItems/OrderSide';
import { OrderTypeCell } from '../tableItems/OrderType';
import { getOrderSide } from '../utils';

type OpenOrdersHeaderData = { [key in keyof OpenOrdersTableData]: EnhancedTableHeaderData };

export const useOpenOrdersTable = (asset?: Asset) => {
  const { getUniqueIdentifier } = UserStore.useUserStore;
  const { getRate } = RatesStore.useRatesStore;
  const [sort, setSort] = useState<EnhancedTableSort<OpenOrdersTableData>>({
    sortKey: 'date',
    sortDirection: 'DESC',
  });

  const { getTransactionDetails } = useTransactionDetails();
  const { isSwyftxPro } = useSwyftxPro();

  const { transactions } = useTransactions({
    sortKey: SortKey.Date,
    sortDirection: sort.sortKey === SortKey.Date ? (sort.sortDirection as SortDirection) : SortDirection.DESCENDING,
    pageLimit: 500,
    statuses: [AssetHistoryItemStatus.Pending],
    types: [TransactionType.Buy, TransactionType.Sell],
    userId: getUniqueIdentifier(),
    asset,
  });

  const headers: OpenOrdersHeaderData = {
    id: {
      title: 'ID',
      alignment: 'start',
      sortable: false,
      className: 'hidden',
    },
    asset: {
      title: 'Asset',
      alignment: 'start',
      sortable: true,
      className: 'max-w-auto sm:max-w-[12rem]',
    },
    side: {
      title: 'Side',
      alignment: 'start',
      sortable: true,
    },
    type: {
      title: 'Type',
      alignment: 'start',
      sortable: true,
    },
    triggerPrice: {
      title: 'Trigger Price',
      alignment: 'start',
      sortable: true,
      tooltip: 'The price you set to trigger your order.',
      className: 'hidden @sm:table-cell',
    },
    currentPrice: {
      title: 'Current Price',
      alignment: 'start',
      sortable: true,
      tooltip: 'The current price based on order direction. Buy orders use BID price, and Sell orders use ASK price.',
      className: 'hidden @md:table-cell',
    },
    amount: {
      title: 'Amount',
      alignment: 'start',
      sortable: true,
      className: 'hidden @sm:table-cell max-w-[10rem] @xl:max-w-full',
    },
    total: {
      title: 'Total',
      alignment: 'start',
      sortable: true,
      className: isSwyftxPro ? 'hidden @sm:table-cell' : 'hidden',
    },
    filled: {
      title: 'Filled',
      alignment: 'start',
      sortable: false,
      className: 'hidden @sm:table-cell',
    },
    date: {
      title: 'Date',
      alignment: 'start',
      sortable: true,
      className: 'hidden @md:table-cell',
    },
    actions: {
      title: 'Actions',
      alignment: 'end',
      sortable: false,
      className: 'table-cell @sm:hidden @md:table-cell',
    },
  };

  const data: EnhancedTableData<OpenOrdersTableData>[] = useMemo(
    () =>
      (transactions ?? []).map((order) => {
        const details = getTransactionDetails(order, { noSuffix: true });
        const primaryAsset = assetService.getAsset(order.primaryAsset)!;
        const secondaryAsset = assetService.getAsset(order.secondaryAsset)!;
        const orderSide = getOrderSide(order.orderType);
        const { bidPrice, askPrice } = getRate(order.secondaryAsset);
        const isLinked =
          isSwyftxPro &&
          (!!order.childOrders?.length ||
            !!order.linkedOrderUuid ||
            (!!order.orderSourceUuid && !order.orderSourceUuid.includes('reo_')));

        return {
          id: {
            value: order.uuid,
            element: null,
          },
          asset: {
            value: secondaryAsset.name,
            element: <AssetName asset={secondaryAsset} />,
          },
          side: {
            value: orderSide,
            element: <OrderSide orderType={order.orderType} />,
          },
          type: {
            value: order.orderType,
            element: <OrderTypeCell type={order.orderType} isLinked={isLinked} />,
          },
          triggerPrice: {
            value: orderSide === 'Buy' ? order.trigger : Big(1).div(order.trigger).toString(),
            element: (
              <OrderPrice orderRate={details.triggerPrice} asset={primaryAsset} secondaryAsset={secondaryAsset} />
            ),
          },
          currentPrice: {
            value: orderSide === 'Buy' ? askPrice : bidPrice,
            element: (
              <CurrentPrice
                assetId={order.secondaryAsset}
                side={getOrderSide(order.orderType)}
                baseAsset={primaryAsset}
              />
            ),
          },
          amount: {
            value: Big(order.secondaryAmount).abs().toString(),
            element: (
              <OrderQuantity
                orderAmount={order.secondaryAmount}
                approximate={details.approximateAmount}
                assetId={order.secondaryAsset}
                orderTotal={details.total}
                primaryAsset={primaryAsset}
              />
            ),
          },
          total: {
            value: Big(order.primaryAmount).abs().toString(),
            element: (
              <FlexLayout alignItems='center' spacing={4}>
                <Body color='primary' size='small'>
                  {details.total}
                </Body>
                <Body color='secondary' size='small'>
                  {details.totalAssetCode}
                </Body>
              </FlexLayout>
            ),
          },
          filled: {
            value: order.primaryAmount,
            element: (
              <FlexLayout direction='column' className='max-h-[1rem] justify-center'>
                <FilledAmount order={order} />
              </FlexLayout>
            ),
          },
          date: {
            value: Big(order.date).toString(),
            element: <OrderDate orderDate={order.date} alignment='start' />,
          },
          actions: {
            value: order,
            element: <OrderActions order={order as TransactionHistoryItem} />,
            blockClick: true,
          },
        };
      }),
    [getRate, getTransactionDetails, isSwyftxPro, transactions],
  );

  const sortItems = (
    a: EnhancedTableData<OpenOrdersTableData>,
    b: EnhancedTableData<OpenOrdersTableData>,
    sortingBy: EnhancedTableSort<OpenOrdersTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'asset':
      case 'side':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'amount':
      case 'total':
      case 'triggerPrice':
      case 'currentPrice':
      case 'type':
      case 'date':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      case 'id':
      case 'filled':
      case 'actions':
        return 1;
      default:
        sortingBy.sortKey satisfies never;
    }
    return 1;
  };

  const filteredData = useMemo(() => data.sort((a, b) => sortItems(a, b, sort)), [data, sort]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<OpenOrdersTableData>) => {
      const tableData: EnhancedTableData<OpenOrdersTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;
      setSort(newSort);
      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData],
  );

  return { headers, data: filteredData, sort, onSort };
};
