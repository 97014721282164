import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

type Props = {
  triggerType: TriggerType;
  setTriggerType: React.Dispatch<React.SetStateAction<TriggerType>>;
  setProfitLossValue: (newValue: string) => void;
};

const ProfitStopLossTriggerType: React.FC<Props> = ({ triggerType, setTriggerType, setProfitLossValue }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });

  const onUpdateTriggerType = (newTriggerType: TriggerType) => {
    setTriggerType(newTriggerType);
    setProfitLossValue('');
  };

  return (
    <FlexLayout className='w-full px-16'>
      <EnhancedTabs<TriggerType>
        onChange={onUpdateTriggerType}
        fullWidth
        value={triggerType}
        variant='default'
        tabs={[
          {
            title: t('labels.takeProfit'),
            spotlightElementId: 'btn-take-profit',
            color: 'accent',
            value: TriggerType.TakeProfit,
          },
          {
            title: t('labels.stopLoss'),
            spotlightElementId: 'btn-stop-loss',
            color: 'accent',
            value: TriggerType.StopLoss,
          },
        ]}
      />
    </FlexLayout>
  );
};

export { ProfitStopLossTriggerType };
