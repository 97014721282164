import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, useTheme } from '@mui/material';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { DiscountFilled } from '@swyftx/aviary/icons/filled';
import { Deposit, Trade, Withdraw } from '@swyftx/aviary/icons/outlined';
import { Divider, Grid, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetHistoryItemStatus, DashboardTile, OrderSource, TransactionType } from '@shared/api';
import { FiatCodeEnum, OrderStatusEnum } from '@shared/enums';
import { assetService, OrderType, TransactionHistoryItem } from '@shared/services';
import { formatDate } from '@shared/utils';

import { useAvo } from '@hooks/Avo/useAvo';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

import { ActivityTileMobileTransactionItem } from './ActivityTileMobileTransactionItem';

interface Props {
  transaction: TransactionHistoryItem;
  tile: DashboardTile;
  showDivider: boolean;
}

const ActivityTileTransactionItem: React.FC<Props> = observer(({ transaction, tile, showDivider }) => {
  const { invalidateCache } = useTransactionsCache();
  const theme = useTheme();
  const { navigate } = useNavigateRoute();
  const { openModal } = useModal();
  const secondaryAsset = assetService.getAsset(transaction.secondaryAsset);
  const { secondaryAmount } = transaction;
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const avo = useAvo();

  const { isLargeContent } = useContentBreakpoint();
  const isSmall = !isLargeContent || tile.metadata?.size === 'compact' || tile.metadata?.size === 'half';

  const handleClick = (asset: Asset) => {
    avo.viewedSingleAssetPage({
      screen: pathname,
      assetName: asset.name,
      assetId: asset.id,
      assetCode: asset.code,
    });
    navigate(NavigationURLs.SingleAsset, { pathParams: { asset: secondaryAsset?.code } });
  };

  const buildDate = () => {
    const splitDateTime = formatDate(transaction.date).split(',');

    const date = splitDateTime?.[0];
    const time = splitDateTime?.[1];

    return date ? (
      <Stack direction='row' spacing={1} alignItems='center' justifyContent={!isSmall ? 'flex-end' : 'flex-start'}>
        <Typography color='text.secondary' fontSize={14} fontWeight={500} number>
          {date}
        </Typography>
        {time ? (
          <>
            <Typography color='text.secondary' fontSize={14} fontWeight={500} number>
              &#183;
            </Typography>
            <Typography color='text.secondary' fontSize={14} fontWeight={500} number>
              {time.toLocaleUpperCase()}
            </Typography>
          </>
        ) : null}
      </Stack>
    ) : null;
  };

  const buildIcon = () => {
    let icon: React.ReactNode = null;

    switch (transaction.type) {
      case TransactionType.Buy:
      case TransactionType.Sell:
        icon = <Trade className='h-12 w-12' />;
        break;
      case TransactionType.Withdrawal:
        icon = <Withdraw className='h-12 w-12' />;
        break;
      case TransactionType.Deposit:
        icon = <Deposit className='h-12 w-12' />;
        break;
      case TransactionType.Staking:
        icon = <DiscountFilled className='h-12 w-12 text-color-text-secondary' />;
        break;
      default:
        icon = null;
    }

    return (
      <Avatar size='md' color='secondary' className='text-color-text-primary'>
        {icon}
      </Avatar>
    );
  };

  const buildOrderType = () => {
    if (transaction.type === TransactionType.Withdrawal) {
      return t('openOrdersSummary.labels.withdraw');
    }

    if (transaction.type === TransactionType.Deposit) {
      return t('openOrdersSummary.labels.deposit');
    }
    // orders created via the asset migration service need to be represented as asset migrations rather than buys or sells
    if (transaction.orderSource === OrderSource.AssetMigration) {
      return t('openOrdersSummary.labels.assetMigration');
    }

    switch (transaction.orderType) {
      case OrderType.DustSell:
        return t('openOrdersSummary.labels.dustSell');
      case OrderType.MarketSell:
        return t('openOrdersSummary.labels.marketSell');
      case OrderType.StopSell:
        return t('openOrdersSummary.labels.stopSell');
      case OrderType.TriggerSell:
        return t('openOrdersSummary.labels.triggerSell');
      case OrderType.TriggerBuy:
        return t('openOrdersSummary.labels.triggerBuy');
      case OrderType.StopBuy:
        return t('openOrdersSummary.labels.stopBuy');
      case OrderType.MarketBuy:
        return t('openOrdersSummary.labels.marketBuy');
      case OrderType.OTCBuy:
        return t('openOrdersSummary.labels.otcBuy');
      case OrderType.OTCSell:
        return t('openOrdersSummary.labels.otcSell');
      default:
        return '';
    }
  };

  const getStatusChipDisplay = () => {
    let color: 'primary' | 'error' | 'success' = 'primary';
    let text: any;

    if ([TransactionType.Buy, TransactionType.Sell].includes(transaction.type)) {
      switch (transaction.statusRaw) {
        case OrderStatusEnum.FAILED:
        case OrderStatusEnum.INSUFFICIENT_BALANCE:
        case OrderStatusEnum.USER_CANCELLED:
        case OrderStatusEnum.FAILED_MAX_AMOUNT:
        case OrderStatusEnum.SYSTEM_CANCELLED:
        case OrderStatusEnum.FAILED_MIN_AMOUNT:
        case OrderStatusEnum.REFUNDED:
          color = 'error';
          text = t('openOrdersSummary.labels.failed');
          break;
        case OrderStatusEnum.FILLED:
          color = 'success';
          text = t('openOrdersSummary.labels.completed');
          break;
        case OrderStatusEnum.OPEN:
        case OrderStatusEnum.PARTIAL_FILL:
        case OrderStatusEnum.PENDING:
        default:
          color = 'primary';
          text = t('openOrdersSummary.labels.pending');
          break;
      }
    } else {
      switch (transaction.status) {
        case AssetHistoryItemStatus.Completed:
          color = 'success';
          text = t('openOrdersSummary.labels.completed');
          break;
        case AssetHistoryItemStatus.Failed:
          color = 'error';
          if (
            transaction.statusRaw === OrderStatusEnum.USER_CANCELLED &&
            (transaction.type === TransactionType.Buy || transaction.type === TransactionType.Sell)
          ) {
            text = t('openOrdersSummary.labels.cancelled');
          } else {
            text = t('openOrdersSummary.labels.failed');
          }
          break;
        case AssetHistoryItemStatus.Cancelled:
          color = 'error';
          text = t('openOrdersSummary.labels.cancelled');
          break;
        case AssetHistoryItemStatus.Pending:
          break;
        default:
          color = 'primary';
          text = t('openOrdersSummary.labels.pending');
          break;
      }
    }

    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <FiberManualRecordIcon color={color} sx={{ fontSize: 12 }} />
        <Typography fontSize={12} fontWeight='500' color='text.secondary'>
          {text}
        </Typography>
      </Stack>
    );
  };

  const buildAmountText = () => {
    const sign =
      transaction.type === TransactionType.Sell || transaction.type === TransactionType.Withdrawal ? '' : '+';

    return (
      <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start'>
        <FormattedText
          prefix={sign}
          value={secondaryAmount}
          currency={secondaryAsset}
          formatBalance
          typographyProps={{
            weight: 'emphasis',
            className: 'truncate',
          }}
          formatOpts={{
            hideCode: true,
            appendCode: false,
          }}
        />
        <Typography fontSize={14} color='text.secondary' number fontWeight={500}>
          {secondaryAsset?.code ?? FiatCodeEnum.USD}
        </Typography>
      </Stack>
    );
  };

  const buildCurrency = () =>
    secondaryAsset ? (
      <Stack direction='row' spacing={1} alignItems='center' justifyContent={isSmall ? 'flex-end' : 'flex-start'}>
        <AssetIcon asset={secondaryAsset} size={20} />

        <Typography
          fontSize={14}
          color='text.secondary'
          number
          fontWeight={500}
          onClick={() => handleClick(secondaryAsset)}
          sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
        >
          {secondaryAsset.code}
        </Typography>
      </Stack>
    ) : null;

  const buildOrderDescription = () => (
    <Stack direction='row' alignItems='center' spacing={1} width='100%'>
      {/* icon */}
      {!isSmall && <Box sx={{ '.MuiAvatar-root': { background: theme.palette.divider } }}>{buildIcon()}</Box>}
      {/* type */}
      <Typography fontSize={12} fontWeight='400'>
        {buildOrderType()}
      </Typography>
      {/* status dot thing */}
      {!isSmall && getStatusChipDisplay()}
    </Stack>
  );

  const openDetailsModal = () => {
    if (transaction.type === TransactionType.Withdrawal || transaction.type === TransactionType.Deposit) {
      const asset = assetService.getAsset(transaction.secondaryAsset);
      if (asset) openModal(Modals.TransactionDetails, { transaction, asset });
    } else {
      openModal(Modals.OrderDetails, { orderId: transaction.uuid, transaction, onEditSuccess: invalidateCache });
    }
  };

  if (isSmall) {
    return (
      <ActivityTileMobileTransactionItem
        orderDescription={buildOrderDescription()}
        status={getStatusChipDisplay()}
        date={buildDate()}
        amount={buildAmountText()}
        currency={buildCurrency()}
        openOrderDetails={openDetailsModal}
      />
    );
  }

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
        openDetailsModal();
      }}
      sx={{
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer',
        },
      }}
    >
      <Grid container direction='row' alignItems='center' paddingX={2} paddingY={1.25}>
        {/* transaction type */}
        <LayoutGridItem xs={3}>{buildOrderDescription()}</LayoutGridItem>

        {/* currency */}
        <LayoutGridItem xs={3} direction='row' justifyContent='flex-start'>
          {buildCurrency()}
        </LayoutGridItem>

        <LayoutGridItem xs={3} direction='row' justifyContent='flex-start'>
          {buildAmountText()}
        </LayoutGridItem>

        <LayoutGridItem xs={3} justifyContent='flex-end'>
          {buildDate()}
        </LayoutGridItem>
      </Grid>
      {showDivider && <Divider variant='middle' />}
    </Stack>
  );
});

export { ActivityTileTransactionItem };
