import React, { useCallback, useState } from 'react';

import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { CompanyForm } from '@shared/api';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import { HumanReadablePurposeOfAccount } from '@routes/EntityOnboarding/types/EntityApplicationForm.types';

import { zodResolver } from '@hookform/resolvers/zod';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';

import { SupplyInformationStepComplianceDisclaimers } from './SupplyInformationStepComplianceDisclaimers';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { companyInformationSchema } from '../AddMembersStep/schemas/CompanyInformationSchema';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

type FormInputs = Omit<CompanyForm, 'directors' | 'shareholders'>;

export const CompanySupplyInformationStep: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData) as CompanyForm;

  const [offersFinancialServices, setOffersFinancialServices] = useState<boolean | undefined>(
    applicationData.offersFinancialServices ?? undefined,
  );
  const [offersCryptoServices, setOffersCryptoServices] = useState<boolean | undefined>(
    applicationData.offersCryptoServices ?? undefined,
  );
  const [tradesArmsOilOrPreciousMats, setTradesArmsOilOrPreciousMats] = useState<boolean | undefined>(
    applicationData.tradesArmsOilOrPreciousMats ?? undefined,
  );
  const [otherPurposeOfAccountSelected, setOtherPurposeOfAccountSelected] = useState<boolean>(
    applicationData.purposeOfAccount
      ? Object.keys(HumanReadablePurposeOfAccount).includes(applicationData.purposeOfAccount)
        ? false
        : true
      : false,
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      name: applicationData.name,
      businessActivity: applicationData.businessActivity,
      purposeOfAccount: applicationData.purposeOfAccount,
    },
    resolver: zodResolver(companyInformationSchema, undefined, { rawValues: true }),
  });

  const companyName = watch('name');
  const businessActivity = watch('businessActivity');
  const purposeOfAccount = watch('purposeOfAccount');

  const continueDisabled = useCallback(() => {
    if (
      tradesArmsOilOrPreciousMats === undefined ||
      offersFinancialServices === undefined ||
      offersCryptoServices === undefined
    )
      return true;
    return !companyName || !businessActivity || !purposeOfAccount;
  }, [
    businessActivity,
    companyName,
    tradesArmsOilOrPreciousMats,
    purposeOfAccount,
    offersFinancialServices,
    offersCryptoServices,
  ]);

  return (
    <EntityOnboardingStepContainer
      customTitle='Company information'
      submitDisabled={continueDisabled()}
      onSubmit={handleSubmit((data) =>
        entityOnboardingService.send({
          type: 'NEXT',
          data: { ...data, offersFinancialServices, offersCryptoServices, tradesArmsOilOrPreciousMats } as CompanyForm,
        }),
      )}
    >
      <EntityOnboardingPageLayout subtitle='Provide some basic information about your company. We are required to collect this for regulatory purposes.'>
        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            1. Legal company name
          </Body>
          <Controller
            name='name'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                placeholder='eg. Acme Pty Ltd'
                error={!!errors.name}
              />
            )}
          />
          <Body size='xsmall' color='secondary'>
            We&apos;ll use this for the platform so you know which entity you are actively using.
          </Body>
          {errors.name && (
            <Body size='small' color='error'>
              {errors.name.message}
            </Body>
          )}
        </FlexLayout>
        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            2. What is the main business activity of your company?
          </Body>
          <Controller
            name='businessActivity'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                placeholder='eg. Ecommerce store'
                error={!!errors.businessActivity}
              />
            )}
          />
          {errors.businessActivity && (
            <Body size='small' color='error'>
              {errors.businessActivity.message}
            </Body>
          )}
        </FlexLayout>
        <FlexLayout direction='column' spacing={4}>
          <Body size='small' weight='emphasis'>
            3. What is the purpose of using Swyftx?
          </Body>
          <Controller
            name='purposeOfAccount'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                items={[
                  ...Object.entries(HumanReadablePurposeOfAccount).map(([key, value]) => ({
                    label: value,
                    value: key,
                  })),
                  { label: 'Other', value: 'OTHER' },
                ]}
                placeholder='Please select an option'
                value={otherPurposeOfAccountSelected ? 'OTHER' : field.value}
                onValueChange={(e) => {
                  if (e === '') return;
                  if (e === 'OTHER') {
                    setOtherPurposeOfAccountSelected(true);
                    field.onChange(' ');
                  } else {
                    setOtherPurposeOfAccountSelected(false);
                    field.onChange(e);
                  }
                }}
                error={!!errors.purposeOfAccount}
              />
            )}
          />
          {errors.purposeOfAccount && (
            <Body size='small' color='error'>
              {errors.purposeOfAccount.message}
            </Body>
          )}
        </FlexLayout>
        {otherPurposeOfAccountSelected && (
          <FlexLayout direction='column' spacing={4}>
            <Body size='small' weight='emphasis'>
              3.1. Why are you creating an account?
            </Body>
            <Controller
              name='purposeOfAccount'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={field.onChange}
                  placeholder='eg. Investing'
                  error={!!errors.purposeOfAccount}
                />
              )}
            />
            {errors.purposeOfAccount && (
              <Body size='small' color='error'>
                {errors.purposeOfAccount.message}
              </Body>
            )}
          </FlexLayout>
        )}
        <SupplyInformationStepComplianceDisclaimers
          entityType='company'
          offersFinancialServices={offersFinancialServices}
          setOffersFinancialServices={setOffersFinancialServices}
          offersCryptoServices={offersCryptoServices}
          setOffersCryptoServices={setOffersCryptoServices}
          tradesArmsOilOrPreciousMats={tradesArmsOilOrPreciousMats}
          setTradesArmsOilOrPreciousMats={setTradesArmsOilOrPreciousMats}
        />
      </EntityOnboardingPageLayout>
    </EntityOnboardingStepContainer>
  );
});
