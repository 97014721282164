import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { formatCurrency } from '@swyftx/currency-util';

import { AssetType, HistoricalOrder, OrderSource } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useTradeFeesData } from '@routes/Profile/subroutes/TradeFees/useTradeFeesData';

import { DateTime } from 'luxon';
import { getOrderSide, getOrderType } from 'src/lib/UniversalTrade/components/OrdersTile/utils';

import { OrderInfoAmount } from '../components/InfoItems/OrderInfoAmount';
import { OrderInfoFee } from '../components/InfoItems/OrderInfoFee';
import { OrderPrice } from '../components/InfoItems/OrderPrice';
import { OrderTriggerPrice } from '../components/InfoItems/OrderTriggerPrice';
import { getStatusColor, getStatusText, isTriggerOrder } from '../utils';

type OrderInfoItem = {
  title: string;
  data: string | React.ReactNode;
};

const DEFAULT_FEE = 0.6;

export const useOrderInfo = (order: HistoricalOrder) => {
  const { t } = useTranslation('orders');
  const items: OrderInfoItem[] = [];
  const primaryAsset = assetService.getAsset(order?.primary_asset)!;
  const asset = assetService.getAsset(order?.secondary_asset || 3)!;
  const { getRate, convertRate } = RatesStore.useRatesStore;
  const rate = getRate(asset);
  const { userFeeStatus } = useTradeFeesData(order.pro ? 'pro' : 'standard');
  const baseAsset = useBaseAsset()!;
  const feePercentage = userFeeStatus?.feePercentage ?? DEFAULT_FEE;

  const isPrimaryAssetFiat = primaryAsset.assetType === AssetType.Fiat;
  const isOrderAssetMigration = order.order_source === OrderSource.AssetMigration;

  const currentPriceInPrimaryAsset = () => {
    if (!order) return '-';

    return `${formatCurrency(convertRate(baseAsset, primaryAsset, rate.askPrice).toString(), {
      isFiat: isPrimaryAssetFiat,
    })} ${primaryAsset.code}/${asset.code}`;
  };

  const statusText = useMemo(() => getStatusText(order.status), [order.status]);
  const statusColor = useMemo(() => getStatusColor(order.status), [order.status]);

  // asset migration orders should not show the order side and require an override for order type
  if (!isOrderAssetMigration) {
    items.push({
      title: 'Side',
      data: getOrderSide(order.order_type),
    });
  }

  items.push(
    {
      title: 'Order type',
      data: isOrderAssetMigration ? t('types.assetMigration') : getOrderType(order.order_type),
    },
    {
      title: 'Amount',
      data: <OrderInfoAmount order={order} />,
    },
    {
      title: 'Status',
      data: (
        <Chip color={statusColor} variant='subtle'>
          {statusText}
        </Chip>
      ),
    },
    {
      title: 'Date Placed',
      data: DateTime.fromMillis(order.created_time).toFormat('dd/MM/yyyy, hh:mma'),
    },
  );

  if (isTriggerOrder(order.order_type)) {
    items.push({
      title: 'Trigger Price',
      data: <OrderTriggerPrice order={order} />,
    });
  } else {
    items.push({
      title: 'Price',
      data: <OrderPrice order={order} />,
    });
  }

  items.push(
    {
      title: 'Current Price',
      data: currentPriceInPrimaryAsset(),
    },
    {
      title: order.status === OrderStatusEnum.OPEN ? `Estimated Fee (${feePercentage}%)` : 'Fee',
      data: <OrderInfoFee order={order} />,
    },
  );

  return { items };
};
