import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowLineOnlyUp, Coin, DollarInCircle, Gift } from '@swyftx/aviary/icons/outlined';

import { observer } from 'mobx-react-lite';

import { VerificationFunctionality, functionalityIcons } from '../VerificationLevel.data';

type Props = {
  functionality: VerificationFunctionality;
};

const iconComponents: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  Gift,
  Coin,
  ArrowUp: ArrowLineOnlyUp,
  Dollar: DollarInCircle,
};

const VerificationFunctionalityItem: React.FC<Props> = observer(({ functionality }) => {
  const { t } = useTranslation('profile.verification');
  const Icon = iconComponents[functionalityIcons[functionality]];

  return (
    <FlexLayout direction='row' className='items-center' spacing={8}>
      <Icon className='h-16 w-16 shrink-0' />
      <Body size='small'>{t(`verificationLevel.functionality.${functionality}`)}</Body>
    </FlexLayout>
  );
});

export { VerificationFunctionalityItem };
