import { useEffect, useState } from 'react';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';

import { useHighSlippage, useUniversalTradeUtilityStore } from '@hooks/Trade';
import { useLowLiquidity } from '@hooks/Trade/useLowLiquidity';

import { AppFeatureData, useIsFeatureEnabled } from 'src/config';
import { AssetRedenominationInformation } from 'src/config/featureFlags.data';

enum LimitedFunctionalityType {
  SendingReceiving = 'depositWithdraw',
  Sending = 'withdraw',
  Receiving = 'deposit',
  Trade = 'trade',
  Buy = 'buy',
  Sell = 'sell',
  LowLiquidity = 'lowLiquidity',
  HighSlippage = 'highSlippage',
  RedenominationInProgress = 'redenominationInProgress',
  RedenominationCompleted = 'redenominationCompleted',
}

export type LimitedFunctionalityItem = {
  assets: Asset[];
  limitedFunctionalityType: LimitedFunctionalityType;
  featureFlagData?: any; // some JSON value from Launchdarkly
};

export type RedenominationMessagingDetails = {
  type: LimitedFunctionalityType.RedenominationCompleted | LimitedFunctionalityType.RedenominationInProgress;
  legacyAssetDetails: { legacyCode: string; legacyName: string }[];
  rebrandedAssetDetails: { rebrandedName: string; rebrandedCode: string };
  linkToFurtherDetails?: string;
};

export const checkAssetRedenomination = (
  asset: Asset,
  assetRedenominations: AssetRedenominationInformation[],
  checkAgainstLegacyAsset = true,
): RedenominationMessagingDetails | null => {
  if (assetRedenominations.length === 0) {
    return null;
  }
  /** default to checking against legacy asset - asset wallet and asset page are only concerned with redenominations when pertaining to the legacy asset,
   order summaries on the other hand are concerned with redenominations when pertaining to both the legacy or rebranded asset and thus need to check for
   redenomination based on the order type (rebranded - market buy orders/legacy - market sell orders) */
  const assetRedenominationsFiltered = assetRedenominations.filter((a) =>
    checkAgainstLegacyAsset
      ? assetService.getAsset(a.legacyAssetId)?.code === asset.code
      : a.rebrandedCode === asset.code,
  );

  // if asset is already delisted, redenmoination is complete, if not redenom is still in progress
  // if checking against rebranded asset, only show completed state
  const redenominationType =
    assetService.isAssetDelisted(asset) || !checkAgainstLegacyAsset
      ? LimitedFunctionalityType.RedenominationCompleted
      : LimitedFunctionalityType.RedenominationInProgress;

  if (assetRedenominationsFiltered.length) {
    return {
      type: redenominationType,
      // it is possible for a multiple legacy assets to be redenominated to a single rebranded asset
      legacyAssetDetails: assetRedenominationsFiltered.map((a) => {
        const legacyAsset = assetService.getAsset(a.legacyAssetId);
        return {
          legacyCode: legacyAsset?.code ?? '',
          legacyName: legacyAsset?.name ?? '',
        };
      }),
      rebrandedAssetDetails: {
        rebrandedName: assetRedenominationsFiltered[0].rebrandedName,
        rebrandedCode: assetRedenominationsFiltered[0].rebrandedCode,
      },
      // TODO: this needs to be updated when the new general support article is available for use if no redenom specific link is provided
      linkToFurtherDetails: assetRedenominationsFiltered.find((a) => a.link)?.link ?? undefined,
    };
  }
  return null;
};

const useAssetLimitedFunctionality = (assets: Asset[], assetsToIgnore: number[] = []) => {
  const [limitedFunctionalityItems, setLimitedFunctionalityItems] = useState<LimitedFunctionalityItem[]>([]);
  const { highSlippageTrades } = useUniversalTradeUtilityStore();
  const { getIsLowLiquidity } = useLowLiquidity();
  const { getIsHighSlippage } = useHighSlippage();
  const { getFeatureData } = useIsFeatureEnabled();
  const featureFlagAssetRedenominations = getFeatureData(AppFeatureData.AssetRedenominationInformation);

  useEffect(() => {
    if (!assets.length) {
      setLimitedFunctionalityItems([]);
      return;
    }

    let limitedItems: LimitedFunctionalityItem[] = [];

    assets.forEach((asset) => {
      const redenomAsset = checkAssetRedenomination(asset, featureFlagAssetRedenominations);

      if (redenomAsset) {
        limitedItems.push({
          assets: [asset],
          limitedFunctionalityType: redenomAsset.type,
          featureFlagData: redenomAsset,
        });
      }

      if (getIsLowLiquidity(asset)) {
        const lowLiquidityEntry = limitedItems.find(
          (li) => li.limitedFunctionalityType === LimitedFunctionalityType.LowLiquidity,
        );

        if (!lowLiquidityEntry) {
          limitedItems.push({
            assets: [asset],
            limitedFunctionalityType: LimitedFunctionalityType.LowLiquidity,
          });
        } else {
          lowLiquidityEntry.assets.push(asset);
        }
      }

      if (getIsHighSlippage(asset)) {
        const highSlippageEntry = limitedItems.find(
          (li) => li.limitedFunctionalityType === LimitedFunctionalityType.HighSlippage,
        );

        if (!highSlippageEntry) {
          limitedItems.push({
            assets: [asset],
            limitedFunctionalityType: LimitedFunctionalityType.HighSlippage,
          });
        } else {
          highSlippageEntry.assets.push(asset);
        }
      }

      if (assetsToIgnore.includes(asset.id)) {
        return;
      }

      if (asset.buyDisabled && !asset.sellEnabled) {
        const tradeEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Trade);

        if (!tradeEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Trade });
        } else {
          tradeEntry.assets.push(asset);
        }
      }

      if (asset.buyDisabled && asset.sellEnabled) {
        const buyEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Buy);

        if (!buyEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Buy });
        } else {
          buyEntry.assets.push(asset);
        }
      }

      if (!asset.buyDisabled && !asset.sellEnabled) {
        const buyEntry = limitedItems.find((li) => li.limitedFunctionalityType === LimitedFunctionalityType.Sell);

        if (!buyEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: LimitedFunctionalityType.Sell });
        } else {
          buyEntry.assets.push(asset);
        }
      }

      if (!assetService.canWithdrawAndDepositAsset(asset)) {
        const canWithdraw = assetService.canWithdrawAsset(asset);
        const canDeposit = assetService.canDepositAsset(asset);
        let limitedType = LimitedFunctionalityType.SendingReceiving;

        if (canWithdraw && !canDeposit) limitedType = LimitedFunctionalityType.Receiving;
        if (!canWithdraw && canDeposit) limitedType = LimitedFunctionalityType.Sending;

        const sendReceiveEntry = limitedItems.find((li) => li.limitedFunctionalityType === limitedType);

        if (!sendReceiveEntry) {
          limitedItems.push({ assets: [asset], limitedFunctionalityType: limitedType });
        } else {
          sendReceiveEntry.assets.push(asset);
        }
      }
    });

    setLimitedFunctionalityItems(limitedItems);
  }, [
    assets,
    assetsToIgnore,
    featureFlagAssetRedenominations,
    getIsHighSlippage,
    getIsLowLiquidity,
    highSlippageTrades,
  ]);

  return {
    limitedFunctionalityItems,
  };
};

export { useAssetLimitedFunctionality, LimitedFunctionalityType };
