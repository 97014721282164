import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import {
  ProfitLossValueMeasurement,
  ProfitLossValueMethod,
  TriggerType,
} from '@global-components/Modals/ProfitStopLoss/types';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

type Props = {
  triggerType: TriggerType;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
  profitLossValueMethod: ProfitLossValueMethod;
  profitLossValue: string;
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  currentPrice?: string;
  triggerPrice?: string;
  estimatedProfitPercentage: string;
};

const MAX_STOP_LOSS_PERCENTAGE = 99;

const useProfitStopLossValueCalculationValueUI = ({
  triggerType,
  profitLossValueMeasurement,
  profitLossValueMethod,
  profitLossValue,
  primaryAsset,
  secondaryAsset,
  currentPrice,
  triggerPrice,
  estimatedProfitPercentage,
}: Props) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const [error, setError] = useState<string>('');

  const isTakeProfit = useMemo(() => triggerType === TriggerType.TakeProfit, [triggerType]);

  const isDollarCalculation = useMemo(() => {
    if (profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) return false;
    return profitLossValueMeasurement === ProfitLossValueMeasurement.Dollar;
  }, [profitLossValueMeasurement, profitLossValueMethod]);

  useEffect(() => {
    if (!profitLossValue || !currentPrice || !triggerPrice || !estimatedProfitPercentage) {
      setError('');
      return;
    }

    if (triggerType === TriggerType.StopLoss) {
      if (Big(currentPrice).lte(Big(triggerPrice))) {
        setError(
          t('errors.triggerPriceMustBeLess', {
            price: formatCurrency(currentPrice, primaryAsset),
            pair: `${primaryAsset?.code}/${secondaryAsset?.code}`,
          }),
        );
        return;
      }

      if (Big(estimatedProfitPercentage).gte(MAX_STOP_LOSS_PERCENTAGE)) {
        setError(t('errors.stopLossPercentage', { amount: MAX_STOP_LOSS_PERCENTAGE }));
        return;
      }
    } else if (Big(currentPrice).gte(Big(triggerPrice))) {
      setError(
        t('errors.triggerPriceMustBeMore', {
          price: formatCurrency(currentPrice, primaryAsset),
          pair: `${primaryAsset?.code}/${secondaryAsset?.code}`,
        }),
      );
      return;
    }

    setError('');
  }, [
    currentPrice,
    primaryAsset,
    profitLossValue,
    triggerPrice,
    triggerType,
    estimatedProfitPercentage,
    t,
    secondaryAsset?.code,
  ]);

  const label = useMemo(() => {
    if (profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) {
      return t('labels.priceToTrigger');
    }

    const symbol = profitLossValueMeasurement === ProfitLossValueMeasurement.Dollar ? '$' : '%';
    return t(isTakeProfit ? 'labels.profitAmount' : 'labels.lossAmount', { symbol });
  }, [profitLossValueMethod, profitLossValueMeasurement, t, isTakeProfit]);

  const endAdornment = useMemo(() => {
    if (isDollarCalculation || profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) {
      return <Numeric color='secondary'>{primaryAsset?.code}</Numeric>;
    }

    if (!isDollarCalculation && profitLossValueMethod === ProfitLossValueMethod.AmountTarget) {
      return <Numeric color='secondary'>%</Numeric>;
    }

    return null;
  }, [isDollarCalculation, primaryAsset?.code, profitLossValueMethod]);

  const startAdornment = useMemo(() => {
    if (
      (isDollarCalculation || profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) &&
      primaryAsset?.assetType === AssetType.Fiat
    ) {
      return <Body color='primary'>$</Body>;
    }

    return null;
  }, [isDollarCalculation, primaryAsset?.assetType, profitLossValueMethod]);

  const placeholder = useMemo(
    () => (isDollarCalculation || profitLossValueMethod === ProfitLossValueMethod.TriggerPrice ? '0.00' : '0%'),
    [isDollarCalculation, profitLossValueMethod],
  );

  return {
    error,
    label,
    endAdornment,
    startAdornment,
    placeholder,
    isDollarCalculation,
  };
};

export { useProfitStopLossValueCalculationValueUI };
