import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { InformationFilled } from '@swyftx/aviary/icons/filled';
import { ArrowLineOnlyRight } from '@swyftx/aviary/icons/outlined';

import { useEntitySwitch } from '@hooks/Entity/useEntitySwitch';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

interface Props {
  isEntity: boolean;
}

export const EntityApplicationIntroductionStepNotification: React.FC<Props> = observer((props) => {
  const { isEntity } = props;
  const { selectAccount, entityAccounts } = useEntitySwitch();
  const { navigate } = useNavigateRoute();

  const notificationContent = useMemo(
    () =>
      isEntity
        ? {
            title: 'Personal account must be active',
            body: 'Swap to your personal account in order to apply for entity accounts.',
            buttonLabel: 'Switch to personal account',
            onClickAction: () => {
              const selectedAccount = entityAccounts.find((account) => account.uuid === 'USER');
              if (selectedAccount) {
                selectAccount(selectedAccount);
              }
            },
          }
        : {
            title: 'Account upgrade required',
            body: 'Complete silver and gold verification levels to apply for entity accounts.',
            buttonLabel: 'Upgrade now',
            onClickAction: () => navigate(NavigationURLs.ProfileVerification),
          },
    [entityAccounts, isEntity, navigate, selectAccount],
  );

  const { title, body, buttonLabel, onClickAction } = notificationContent;

  return (
    <Notification
      title={title}
      icon={<InformationFilled className='shrink-0 text-color-text-accent' />}
      severity='info'
      actions={
        <FlexLayout direction='row' spacing={4} alignItems='center' className='cursor-pointer hover:underline'>
          <BodyLink onClick={onClickAction} color='accent' size='small'>
            {buttonLabel}
          </BodyLink>
          <ArrowLineOnlyRight className='h-14 w-14 text-color-text-accent' />
        </FlexLayout>
      }
    >
      <Body>{body}</Body>
    </Notification>
  );
});
