import { useCallback, useMemo, useState } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';

import { Asset, AssetHistoryItemStatus, SortDirection, SortKey, TransactionType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { useTransactionDetails } from 'src/lib/transactions/hooks/useTransactionDetails';
import { useTransactions } from 'src/lib/transactions/hooks/useTransactions';

import { OrderHistoryTableData } from '../ordersTile.types';
import { AssetName } from '../tableItems/AssetName';
import { OrderDate } from '../tableItems/OrderDate';
import { OrderPrice } from '../tableItems/OrderPrice';
import { OrderSide } from '../tableItems/OrderSide';
import { OrderStatusChip } from '../tableItems/OrderStatus';
import { OrderTotal } from '../tableItems/OrderTotal';
import { OrderTypeCell } from '../tableItems/OrderType';
import { getOrderSide } from '../utils';

type OrderHistoryHeaderData = { [key in keyof OrderHistoryTableData]: EnhancedTableHeaderData };

export const useOrderHistoryTable = (asset?: Asset, hideCancelled?: boolean) => {
  const { getUniqueIdentifier } = UserStore.useUserStore;
  const [sort, setSort] = useState<EnhancedTableSort<OrderHistoryTableData>>({
    sortKey: 'date',
    sortDirection: 'DESC',
  });
  const isXs = useTailwindBreakpoint('xs');
  const { isSwyftxPro } = useSwyftxPro();

  const { getTransactionDetails } = useTransactionDetails();

  const statuses = hideCancelled
    ? Object.values(AssetHistoryItemStatus).filter(
        (item) => ![AssetHistoryItemStatus.Cancelled, AssetHistoryItemStatus.Failed].includes(item),
      )
    : undefined;

  const { transactions } = useTransactions({
    sortKey: SortKey.Date,
    sortDirection: sort.sortKey === SortKey.Date ? (sort.sortDirection as SortDirection) : SortDirection.DESCENDING,
    pageLimit: 500,
    types: [TransactionType.Buy, TransactionType.Sell],
    userId: getUniqueIdentifier(),
    asset,
    statuses,
  });

  const headers: OrderHistoryHeaderData = {
    id: {
      title: 'ID',
      alignment: 'start',
      sortable: false,
      className: 'hidden',
    },
    asset: {
      title: 'Asset',
      alignment: 'start',
      sortable: true,
      className: 'max-w-auto sm:max-w-[12rem]',
    },
    side: {
      title: 'Side',
      alignment: 'start',
      sortable: true,
    },
    type: {
      title: 'Type',
      alignment: 'start',
      sortable: true,
    },
    orderPrice: {
      title: 'Order Price',
      alignment: 'start',
      sortable: true,
      className: 'hidden @sm:table-cell',
    },
    amount: {
      title: 'Amount',
      alignment: isXs ? 'end' : 'start',
      sortable: true,
      className: 'flex-wrap sm:flex-nowrap max-w-[8rem] sm:max-w-auto',
    },
    total: {
      title: 'Total',
      alignment: 'start',
      sortable: true,
      className: 'hidden @sm:table-cell',
    },
    status: {
      title: 'Status',
      alignment: 'start',
      sortable: true,
      className: 'hidden @sm:table-cell',
    },
    date: {
      title: 'Date',
      alignment: 'end',
      sortable: true,
      className: 'hidden @md:table-cell',
    },
  };

  const data: EnhancedTableData<OrderHistoryTableData>[] = useMemo(
    () =>
      (transactions ?? []).map((order) => {
        const details = getTransactionDetails(order, { noSuffix: true });
        const asset = assetService.getAsset(order.secondaryAsset)!;
        const primaryAsset = assetService.getAsset(order.primaryAsset);
        const secondaryAsset = assetService.getAsset(order.secondaryAsset);
        const orderSide = getOrderSide(order.orderType);
        const isLinked =
          isSwyftxPro &&
          (!!order.childOrders?.length ||
            !!order.linkedOrderUuid ||
            (!!order.orderSourceUuid && !order.orderSourceUuid.includes('reo_')));

        return {
          value: order,
          id: {
            value: order.uuid,
            element: null,
          },
          asset: {
            value: secondaryAsset?.name,
            element: <AssetName asset={asset} />,
          },
          side: {
            value: orderSide,
            element: <OrderSide orderType={order.orderType} />,
          },
          type: {
            value: order.orderType,
            element: <OrderTypeCell type={order.orderType} isLinked={isLinked} />,
          },
          orderPrice: {
            value: details.triggerPrice.replace(/[$,]/g, ''),
            element: <OrderPrice orderRate={details.triggerPrice} asset={primaryAsset} secondaryAsset={asset} />,
          },
          amount: {
            value: Big(details.amount).abs().toString(),
            element: <OrderTotal orderTotal={details.amount} asset={secondaryAsset} />, // Using the OrderTotal component removes the need for the custom logic
          },
          total: {
            value: details.total.replace(/[$,-]/g, ''),
            element: <OrderTotal orderTotal={details.total} asset={primaryAsset} />,
          },
          status: {
            value: details.status.status,
            element: <OrderStatusChip status={details.status.status} color={details.status.color} />,
          },
          date: {
            value: Big(order.date).toString(),
            element: <OrderDate orderDate={order.date} alignment='end' />,
          },
        };
      }),
    [getTransactionDetails, isSwyftxPro, transactions],
  );

  const sortItems = (
    a: EnhancedTableData<OrderHistoryTableData>,
    b: EnhancedTableData<OrderHistoryTableData>,
    sortingBy: EnhancedTableSort<OrderHistoryTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'asset':
      case 'side':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'amount':
      case 'status':
      case 'total':
      case 'orderPrice':
      case 'type':
      case 'date':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      case 'id':
        return 1;
      default:
        sortingBy.sortKey satisfies never;
    }
    return 1;
  };

  const filteredData = useMemo(() => data.sort((a, b) => sortItems(a, b, sort)), [data, sort]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<OrderHistoryTableData>) => {
      const tableData: EnhancedTableData<OrderHistoryTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;
      setSort(newSort);
      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData],
  );

  return { headers, data, sort, onSort };
};
