import { SourceOfWealthOption, UserDocumentDto } from './compliance';
import { String64, String255, Email, PhoneNumber } from './primitives';

export enum EntityMembershipType {
  MajorShareholder = 'Major Shareholder',
  Beneficiary = 'Beneficiary',
  Director = 'Director',
  Trustee = 'Trustee',
  None = 'None',
}

export enum EntityPermission {
  Trade = 'trading',
  Custom = 'custom',
  Read = 'reading',
  Admin = 'admin',
  None = 'none',
}

export enum PurposeOfAccount {
  'DAY_TRADING' = 'day-trading',
  'INVESTMENT' = 'investment',
  'PAYING_FOR_RANSOMWARE' = 'paying_for_ransomware',
  'PURCHASING_WITH_CRYPTO' = 'purchasing_goods_or_services_with_crypto',
  'TRANSFERRING_MONEY' = 'transferring_money',
  'RAMPING_CRYPTO' = 'on-ramping_off-ramping_crypto',
  'RECREATIONAL_TRADING' = 'recreational_trading',
  'RESEARCH_TESTING' = 'research_testing',
  'SENDING_FUNDS_EXTERNALLY' = 'sending_funds_externally',
  'STAKING' = 'staking',
}

export type EntityType = 'COMPANY' | 'SMSF_INDIVIDUAL' | 'SMSF_CORPORATE' | 'TRUST_INDIVIDUAL' | 'TRUST_CORPORATE';

/**
 * A completed form to create a new entity.
 */
export type EntityApplicationForm = CompanyForm | SmsfForm | IndividualTrustForm | CorporateTrustForm;

export type GetEntityApplicationDocumentsResponse = UserDocumentDto[];

export type CompanyForm = {
  type: 'COMPANY';
  name: String255;
  businessActivity: String255;
  purposeOfAccount: PurposeOfAccount | String64;
  offersFinancialServices: boolean;
  offersCryptoServices: boolean;
  tradesArmsOilOrPreciousMats: boolean;
  /**
   * @minItems 1
   * @maxItems 12
   */
  directors: Individual[];
  /**
   * All shareholders that own 25% or more of the company.
   *
   * @minItems 0
   * @maxItems 4
   */
  shareholders: Shareholder[];
};

export type TrustForm = IndividualTrustForm | CorporateTrustForm;

export type SmsfForm = IndividualSmsfForm | CorporateSmsfForm;

type IndividualSmsfForm = {
  type?: 'SMSF_INDIVIDUAL';
  /**
   * @minItems 1
   * @maxItems 10
   */
  trustees?: Individual[];
};

type CorporateSmsfForm = {
  type?: 'SMSF_CORPORATE';
  /**
   * @minItems 1
   * @maxItems 10
   */
  trustees?: SmsfCorporateTrustee[];
};

export type IndividualTrustForm = {
  type: 'TRUST_INDIVIDUAL';
  /**
   * @minItems 1
   * @maxItems 20
   */
  trustees: Individual[];
  /**
   * @minItems 1
   * @maxItems 20
   */
  beneficiaries: Individual[];
};

export type CorporateTrustForm = {
  type: 'TRUST_CORPORATE';
  businessActivity: String255;
  purposeOfAccount: PurposeOfAccount | String64;
  sourceOfWealth: SourceOfWealthOption | string;
  offersFinancialServices: boolean;
  offersCryptoServices: boolean;
  tradesArmsOilOrPreciousMats: boolean;
  /**
   * @minItems 1
   * @maxItems 20
   */
  directors: Individual[];
  /**
   * All shareholders that own 25% or more of the trust.
   *
   * @minItems 0
   * @maxItems 4
   */
  shareholders: Shareholder[];
  /**
   * @minItems 1
   * @maxItems 20
   */
  beneficiaries: Individual[];
};

export type Shareholder = Individual | Corporate;

export type Trustee = Individual | SmsfCorporateTrustee;

export type Individual = {
  type: 'INDIVIDUAL';
  firstName: String64;
  lastName: String64;
  email: Email;
  phone: PhoneNumber;
};

export type Corporate = {
  type: 'CORPORATE';
  name: String255;
  businessActivity: String255;
  offersFinancialServices: boolean;
  offersCryptoServices: boolean;
  tradesArmsOilOrPreciousMats: boolean;
};

export type SmsfCorporateTrustee = Pick<Corporate, 'name' | 'type'>;

export type EntityHistoryResponse = EntityHistoryPayload[];

export type EntityHistoryPayload = {
  id: string;
  status: 'STARTED' | 'SUBMITTED' | 'COMPLETED' | 'FAILED';
  createdAt: number;
  submittedAt: number;
  completedAt?: number;
};

export type EntityOnboardingResponse = Partial<EntityApplicationForm>;
export type EntityOnboardingPayload = any;
