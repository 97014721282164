import React, { useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';
import { UploadFileFilled } from '@swyftx/aviary/icons/filled';
import { Help, UploadFile } from '@swyftx/aviary/icons/outlined';

import {
  useEntityOnboardingSelector,
  useEntityOnboardingService,
} from '@routes/EntityOnboarding/EntityOnboarding.context';
import {
  PreviouslyUploadedFiles,
  UploadedFiles,
  UploadInProgress,
} from '@routes/SourceOfWealth/components/DocumentUploadCards';

import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone';
import { SupportingDocumentsContext } from 'src/context/DocumentUpload/SupportingDocument.context';
import { DocumentUploadFlow, maxFileSizePerFlow } from 'src/context/DocumentUpload/supportingDocumentsUtils';

import { RequiredDocumentItem } from './RequiredDocumentItem';
import { useDocumentHelpLinks } from './useDocumentHelpLinks';
import { useDocumentSuggestions } from './useDocumentSuggestions';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';

const MAX_SIZE = maxFileSizePerFlow[DocumentUploadFlow.EntityOnboarding] / 1000000;

export const UploadDocumentsContent: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();
  const DocumentsContext = useContext(SupportingDocumentsContext);
  const applicationData = useEntityOnboardingSelector((state) => state.context.applicationData);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: DocumentsContext?.onDrop });

  if (!DocumentsContext) {
    return null;
  }

  const {
    selectFilesEnabled,
    previousFiles,
    filesInProgress,
    deleteFile,
    uploadedFiles,
    loading,
    backDisabled,
    continueDisabled,
  } = DocumentsContext;

  const getDropzonePropsIfEnabled = () => {
    if (selectFilesEnabled) {
      return getRootProps();
    }
    return {};
  };

  const humanReadableEntityType = useMemo(() => {
    switch (applicationData.type) {
      case 'COMPANY':
        return 'company';
      case 'TRUST_CORPORATE':
      case 'TRUST_INDIVIDUAL':
        return 'trust';
      case 'SMSF_INDIVIDUAL':
      case 'SMSF_CORPORATE':
        return 'SMSF';
      default:
        return 'entity';
    }
  }, [applicationData.type]);

  const documentSignatureRequirements = useMemo(() => {
    if (applicationData.type === 'COMPANY') {
      return 'All documents must be dated within the past 3 months.';
    }
    return 'All documents must have been certified by a Justice of the Peace or an accountant within the past 3 months.';
  }, [applicationData.type]);

  const { requiredDocuments, optionalDocuments } = useDocumentSuggestions();
  const { helpLinkDestination } = useDocumentHelpLinks();

  return (
    <EntityOnboardingStepContainer
      customTitle='Upload Documents'
      backDisabled={backDisabled}
      onSubmit={(e) => {
        e.preventDefault();
        entityOnboardingService.send({ type: 'NEXT' });
      }}
      submitDisabled={continueDisabled}
    >
      <FlexLayout direction='column' spacing={32}>
        <FlexLayout direction='column' spacing={16}>
          <Body color='secondary'>
            We&apos;re required to verify the structure of your {humanReadableEntityType} through legal documentation.{' '}
            {documentSignatureRequirements}
          </Body>
          <FlexLayout direction='row' spacing={4} className='w-full items-center'>
            <Icon icon={<Help />} color='accent' className='h-18 w-18' />
            <Body color='secondary' size='small'>
              Not sure which documents to upload?
            </Body>
            <BodyLink color='accent' size='small' className='decoration underline' href={helpLinkDestination}>
              Learn more
            </BodyLink>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='column' spacing={8}>
          <Body weight='emphasis'> Required documents:</Body>
          <ul>
            {requiredDocuments?.map((requiredDocument, index) => (
              <RequiredDocumentItem suggestedDocument={requiredDocument} key={`${requiredDocument.title}-${index}`} />
            ))}
          </ul>
          {optionalDocuments.length > 0 && (
            <>
              <Body weight='emphasis' className='mt-16'>
                Optional documents:
              </Body>
              <ul>
                {optionalDocuments.map((optionalDocument, index) => (
                  <RequiredDocumentItem
                    suggestedDocument={optionalDocument}
                    key={`${optionalDocument.title}-${index}`}
                  />
                ))}
              </ul>
            </>
          )}
        </FlexLayout>

        <FlexLayout direction='column' spacing={12}>
          <FlexLayout
            direction='column'
            className={`flex h-[12rem] w-full items-center  justify-center rounded-lg border-2 border-dashed px-12 text-center ${
              isDragActive ? 'border-color-background-info-main' : ''
            }`}
            spacing={12}
            {...getDropzonePropsIfEnabled()}
          >
            <UploadFileFilled
              id='document_upload_icon'
              className='h-32 w-32 text-color-icon-accent'
              alt='Upload icon'
            />
            {!isDragActive && (
              <>
                <FlexLayout direction='column'>
                  <Body size='small' weight='emphasis' className='pointer-non text-color-text-primary'>
                    {!selectFilesEnabled
                      ? 'Maximum documents selected'
                      : 'Drag and drop or select files (up to 5 files)'}
                  </Body>
                  <Body className='pointer-none text-color-text-secondary'>
                    JPEG, PNG, PDF. Max size {MAX_SIZE}MB per file.
                  </Body>
                </FlexLayout>
                <Button
                  variant='outlined'
                  className='w-full'
                  leadingIcon={<UploadFile />}
                  disabled={!selectFilesEnabled}
                >
                  Select files
                </Button>
              </>
            )}
            {isDragActive && (
              <Body size='small' weight='emphasis' className='pointer-non text-color-text-primary'>
                Drop files here
              </Body>
            )}

            {selectFilesEnabled && <input type='file' accept='.jpeg, .png, .application/pdf' {...getInputProps()} />}
          </FlexLayout>
          <UploadInProgress filesInProgress={filesInProgress} />
          <UploadedFiles uploadedFiles={uploadedFiles} deleteFile={deleteFile} />
          {!loading && previousFiles.length > 0 && (
            <PreviouslyUploadedFiles documents={previousFiles} deleteFile={deleteFile} />
          )}
        </FlexLayout>
      </FlexLayout>
    </EntityOnboardingStepContainer>
  );
});
