import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Add } from '@swyftx/aviary/icons/outlined';
import { RadioGroup } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

import { CompanyForm } from '@shared/api';

import { useShareholdersStep } from '@routes/EntityOnboarding/hooks/useShareholdersStep';
import { yesNoRadioGroup } from '@routes/EntityOnboarding/types/EntityApplicationFormContent';
import { getRadioSelectedValue } from '@routes/EntityOnboarding/utils/getRadioSelectedValue';

import { observer } from 'mobx-react-lite';

import { AddCorporateShareholder } from './components/AddCorporateShareholder';
import { AddIndividual } from './components/AddIndividual';
import { CorporateCard } from './components/CorporateCard';
import { IndividualCard } from './components/IndividualCard';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { ConfirmRemoveCorporateMembersModal } from '../../modals/ConfirmRemoveCorporateMembersModal';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const CompanyAddShareholdersStep: React.FC = observer(() => {
  const {
    addIndividualMemberOpen,
    addCorporateMemberOpen,
    continueDisabled,
    corporateShareholders,
    handleRemove,
    hasCompanyShareholders,
    setHasCompanyShareholders,
    individualShareholders,
    onCancel,
    onAddCorporateShareholder,
    onAddMember: onAddCompanyMember,
    toggleAddCorporateMemberOpen,
    toggleAddIndividualMemberOpen,
    entityOnboardingService,
    shareholders,
    applicationData,
    onEditIndividual,
    onEditCorporate,
    removeAllCorporateShareholders,
    hasNotAddedCompanyShareholder,
    directors,
  } = useShareholdersStep();
  const [confirmRemoveCorporateShareholdersOpen, setConfirmRemoveCorporateShareholdersOpen] = useState<boolean>(false);

  const companyName = (applicationData as CompanyForm).name;

  const rawShareholders = useMemo(() => shareholders.map((shareholder) => shareholder.shareholder), [shareholders]);
  const rawDirectors = useMemo(() => directors.map((director) => director.individual), [directors]);

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: { shareholders: rawShareholders, directors: rawDirectors } as CompanyForm,
      }),
    [entityOnboardingService, rawDirectors, rawShareholders],
  );

  const handleBack = useCallback(() => {
    entityOnboardingService.send({
      type: 'BACK',
      data: { shareholders: rawShareholders, directors: rawDirectors } as CompanyForm,
    });
  }, [entityOnboardingService, rawDirectors, rawShareholders]);

  const handleRadioOnChange = useCallback(
    (newValue: boolean) => {
      if (hasCompanyShareholders === true && corporateShareholders.length && newValue === false) {
        return setConfirmRemoveCorporateShareholdersOpen(true);
      }
      setHasCompanyShareholders(newValue);
      toggleAddCorporateMemberOpen(false);
      toggleAddIndividualMemberOpen(false);
    },
    [
      hasCompanyShareholders,
      corporateShareholders.length,
      setHasCompanyShareholders,
      toggleAddCorporateMemberOpen,
      toggleAddIndividualMemberOpen,
    ],
  );

  const addShareholderDisabled = useMemo(
    () => (corporateShareholders.length + individualShareholders.length >= 4 ? true : false),
    [corporateShareholders.length, individualShareholders.length],
  );

  const skipDisabled = useMemo(
    () => (corporateShareholders.length || individualShareholders.length ? true : false),
    [corporateShareholders.length, individualShareholders.length],
  );

  return (
    <>
      <EntityOnboardingStepContainer
        customTitle='Business shareholders'
        submitDisabled={continueDisabled}
        onSubmit={handleSubmit}
        onBack={handleBack}
        renderSkip
        skipDisabled={skipDisabled}
      >
        <EntityOnboardingPageLayout
          subtitle={`List all shareholders that own 25% or more shares, equity or voting rights of ${companyName}. We are required
            to collect this for regulatory purposes.`}
        >
          <FlexLayout direction='column' spacing={12}>
            <Body weight='emphasis'>1. Are any shares held by a company (Pty Ltd)?</Body>
            <RadioGroup
              checkboxes={yesNoRadioGroup}
              selected={
                hasCompanyShareholders !== undefined ? getRadioSelectedValue(hasCompanyShareholders) : undefined
              }
              onChange={(value) => handleRadioOnChange(value === 'yes')}
            />
          </FlexLayout>
          {hasCompanyShareholders !== undefined && (
            <FlexLayout direction='column' spacing={16}>
              <Body weight='emphasis'>2. Please add all shareholders of the company:</Body>
              {individualShareholders.map((shareholder, index) => (
                <IndividualCard
                  key={`${shareholder.individual.firstName}-${index}`}
                  member={shareholder}
                  onRemove={() => handleRemove(shareholder.uuid)}
                  memberType='shareholder'
                  onEditMember={onEditIndividual}
                />
              ))}
              {corporateShareholders.map((shareholder, index) => (
                <CorporateCard
                  key={`${shareholder.corporate.name}-${index}`}
                  shareholder={shareholder}
                  onRemove={() => handleRemove(shareholder.uuid)}
                  onEditCorporateMember={onEditCorporate}
                />
              ))}
              {addIndividualMemberOpen && (
                <Card className='p-12'>
                  <AddIndividual onCompanySubmit={onAddCompanyMember} onCancel={onCancel} memberType='shareholder' />
                </Card>
              )}
              {addCorporateMemberOpen && (
                <Card className='p-12'>
                  <AddCorporateShareholder
                    onCancel={toggleAddCorporateMemberOpen}
                    onSubmit={onAddCorporateShareholder}
                  />
                </Card>
              )}
              <FlexLayout direction='row' className='w-full flex-wrap' spacing={8}>
                <Button
                  leadingIcon={<Add />}
                  onClick={() => toggleAddIndividualMemberOpen(true)}
                  disabled={addIndividualMemberOpen || addCorporateMemberOpen || addShareholderDisabled}
                >
                  Add individual shareholder
                </Button>
                {hasCompanyShareholders && (
                  <Button
                    leadingIcon={<Add />}
                    onClick={toggleAddCorporateMemberOpen}
                    disabled={addIndividualMemberOpen || addCorporateMemberOpen || addShareholderDisabled}
                  >
                    Add corporate shareholder
                  </Button>
                )}
              </FlexLayout>
              {hasNotAddedCompanyShareholder && (
                <Body size='small' color='error'>
                  Please add at least one corporate shareholder
                </Body>
              )}
            </FlexLayout>
          )}
        </EntityOnboardingPageLayout>
      </EntityOnboardingStepContainer>
      {confirmRemoveCorporateShareholdersOpen && (
        <ConfirmRemoveCorporateMembersModal
          open={confirmRemoveCorporateShareholdersOpen}
          setOpen={setConfirmRemoveCorporateShareholdersOpen}
          onConfirm={removeAllCorporateShareholders}
        />
      )}
    </>
  );
});
