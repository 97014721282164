import React, { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Card, CardTitle } from '@swyftx/aviary/atoms/Card';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { Label } from '@swyftx/aviary/atoms/Label';
import { RadioGroup, RadioGroupItem } from '@swyftx/aviary/atoms/Radio';
import { Select } from '@swyftx/aviary/atoms/Select';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Page } from '@swyftx/aviary/layout/Page';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { PriceInput } from '@global-components/PriceInput';

// import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { Bitcoin, Car, Home, Plane, ArrowRight, CheckCircle2, Check } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useIntercom } from 'react-use-intercom';
import { LoanTypeValueType, PurposeOfLoanValueType, PurposeOfLoan } from 'src/context/Avo/generated-avo';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  className?: string;
};

const LoansPage: React.FC<Props> = observer(() => {
  // const { convertRate } = RatesStore.useRatesStore;
  const { getBalance } = UserStore.useUserStore;
  const avo = useAvo();
  const { openModal } = useModal();
  const { show } = useIntercom();
  const { pathname } = useLocation();
  const { getAssetById } = useMarkets();

  const availableBitcoinBalance = getBalance(3);

  const asset = useMemo(() => getAssetById(1), [getAssetById]);

  // const rateBTCtoAUD = convertRate(3, 1, 1, 'midPrice'); // This converts BTC to AUD
  // const rateBTCtoAUD = getRate(3).midPrice; // This uses the user's baseCurrency

  const [selectedLoan, setSelectedLoan] = useState<LoanTypeValueType>('Credit Line');
  const [purposeOfLoan, setPurposeOfLoan] = useState<PurposeOfLoanValueType>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collateralAmount, setCollateralAmount] = useState('');
  const [borrowAmount, setBorrowAmount] = useState('');

  // const collateralAmountNum = parseFloat(collateralAmount) || 0;
  const borrowAmountNum = parseFloat(borrowAmount) || 0;

  // const collateralValueAUD = Big(rateBTCtoAUD).times(collateralAmountNum);

  // const collateralRequired = Big(borrowAmountNum).div(rateBTCtoAUD).times(1.4);
  // const loanToValueRatio = collateralValueAUD.gt(0) ? Big(borrowAmountNum).div(collateralValueAUD) : 0;

  const isFormValid =
    purposeOfLoan && (selectedLoan === 'Fixed Term' ? borrowAmountNum >= 2000 : borrowAmountNum >= 50);

  const formRef = useRef<HTMLFormElement>(null);

  const handleApplyNowClick = (value?: LoanTypeValueType) => {
    avo.clickedButton({
      parentComponent: null,
      tableName: null,
      screen: pathname,
      buttonName: `Register now (${value || 'type not selected'})`,
      modalName: null,
    });

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (value) {
      setSelectedLoan(value as LoanTypeValueType);
    }
  };

  const items = [
    {
      step: 1,
      title: 'Apply for loan',
      description: 'Submit your loan application for review.',
    },
    {
      step: 2,
      title: 'Receive loan',
      description: 'Collateral is secured and you receive AUD.',
    },
    {
      step: 3,
      title: 'Repay loan',
      description: 'Pay interest and principal by end of loan term.',
    },
    {
      step: 4,
      title: 'Collateral is returned',
      description: 'Your Bitcoin collateral is returned to your account.',
    },
  ];

  return (
    <Page
      showCompactHeader={false}
      alignment='center'
      offset={false}
      className='text-primary'
      customHeader={
        <div className='mx-auto max-w-6xl pb-0 xs:p-4 md:p-32 md:pb-64 md:pt-96'>
          <section className='mb-16 grid items-stretch gap-24 md:grid-cols-5'>
            <div className='flex flex-col space-y-16 sm:text-left md:col-span-2'>
              <div className='flex flex-row gap-16'>
                <div className='text-[0.75rem] font-medium text-color-text-accent'>BITCOIN BACKED LOANS</div>
                <Chip variant='subtle' size='sm'>
                  Coming soon
                </Chip>
              </div>
              <div className='space-y-32'>
                <div>
                  <h2 className='line-height-48 font-sans text-32'>
                    Access cash <span className='font-callout'>without</span> selling Bitcoin.
                  </h2>
                  <p className='text-lg'>Get a loan backed by your BTC—flexible terms and fast approval.</p>
                </div>
                <ul className='space-y-32'>
                  <li className='flex items-center gap-12'>
                    <Bitcoin className='h-25 w-25 rounded-[50%] bg-orange-500 p-4 text-white' />
                    <span>Secured by Bitcoin</span>
                  </li>
                </ul>
                <Button
                  className='mt-auto w-max'
                  trailingIcon={<ArrowRight className='h-25 w-25' />}
                  onClick={() => handleApplyNowClick('Credit Line')}
                >
                  Register now
                </Button>
              </div>
            </div>

            <div className='flex items-stretch xs:gap-4 sm:col-span-3 sm:gap-16'>
              <Card className='flex flex-1 flex-col px-24 py-32'>
                <div className='grid flex-1 justify-between'>
                  <div className='flex flex-col justify-between space-y-24'>
                    <Chip variant='subtle' size='sm'>
                      Most flexible
                    </Chip>
                    <CardTitle>Flexi Credit</CardTitle>
                    <div>
                      <span className='line-height-32 font-["DINNextLTPro"] text-32 font-semibold'>
                        9.50<span className='text-20'>% p.a.</span>
                      </span>
                    </div>
                  </div>
                  <hr className='mt-32' />
                  <div className='my-16 flex flex-col justify-between'>
                    <ul className='space-y-8'>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Borrow between $50 - 250k AUD</span>
                      </li>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Repay any time within 1 year</span>
                      </li>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Fixed interest rates</span>
                      </li>

                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Up to 40% LVR</span>
                      </li>

                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>2% Origination Fee</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <Button className='mt-auto w-full' size='lg' onClick={() => handleApplyNowClick('Credit Line')}>
                  Register now
                </Button>
              </Card>

              <Card className='flex flex-1 flex-col px-24 py-32'>
                <div className='grid flex-1 justify-between'>
                  <div className='flex flex-col justify-between space-y-24'>
                    <Chip color='success' variant='subtle' size='sm'>
                      Lowest rate
                    </Chip>
                    <CardTitle>Fixed Term Loan</CardTitle>
                    <div>
                      <span className='line-height-32 font-["DINNextLTPro"] text-32 font-semibold'>
                        8.95<span className='text-20'>%p.a.</span>
                      </span>
                    </div>
                  </div>
                  <hr className='mt-32' />
                  <div className='my-16 flex flex-col justify-between'>
                    <ul className='space-y-8'>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Borrow between $2k - $5M AUD</span>
                      </li>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>3 year fixed term</span>
                      </li>
                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Fixed interest rates</span>
                      </li>

                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>Up to 40% LVR</span>
                      </li>

                      <li className='flex items-center gap-12'>
                        <Check className='h-25 w-25' />
                        <span className='text-[1rem]'>2% Origination Fee</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <Button className='mt-auto w-full' size='lg' onClick={() => handleApplyNowClick('Fixed Term')}>
                  Register now
                </Button>
              </Card>
            </div>
          </section>
        </div>
      }
    >
      <div className='space-y-16'>
        {/* Use Cases Section */}
        <section className='mx-auto max-w-6xl xs:px-32 xs:py-24 md:py-96 lg:px-0'>
          <div className='text-center'>
            <div className='text-[0.75rem] font-medium text-color-text-accent'>USE CASES</div>
            <h2 className='line-height-48 text-32 font-light'>Put Bitcoin to work</h2>
            <p className='mb-8 text-muted-foreground'>Wondering what the loan can be used for?</p>
          </div>
          <div className='grid gap-16 space-y-16 xs:grid-cols-1 xs:py-24 sm:grid-cols-3 md:py-96'>
            {/* <div className='space-y-16 sm:pt-16'>
              <Bitcoin className='mb-4 h-32 w-32 text-color-text-accent' />
              <div>
                <h3 className='mb-2 font-semibold'>Buy more crypto</h3>
                <p className='text-[0.75rem] text-muted-foreground'>
                  Expand your portfolio without selling current assets.
                </p>
              </div>
            </div> */}
            <div className='space-y-16 sm:pt-16'>
              <Car className='mb-4 h-32 w-32 text-color-text-accent' />
              <div>
                <h3 className='mb-2 font-semibold'>Buy a car</h3>
                <p className='text-muted-foreground'>Upgrade without dipping into your savings.</p>
              </div>
            </div>
            <div className='space-y-16'>
              <Home className='mb-4 h-32 w-32 text-color-text-accent' />
              <div>
                <h3 className='mb-2 font-semibold'>Home improvements</h3>
                <p className='text-muted-foreground'>Dreaming of a new bathroom? Renovate now, repay later.</p>
              </div>
            </div>
            <div className='space-y-16'>
              <Plane className='mb-4 h-32 w-32 text-color-text-accent' />
              <div>
                <h3 className='mb-2 font-semibold'>Take a holiday</h3>
                <p className='text-muted-foreground'>
                  Unlock funds to explore, relax, and create unforgettable travel memories.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* How it Works Section */}
        <div className='bg-color-background-surface-primary xs:py-24 md:py-96'>
          <section className='mx-auto max-w-6xl gap-64 text-center xs:px-32 lg:px-0'>
            <div className='text-center'>
              <div className='text-[0.75rem] font-medium text-color-text-accent'>HOW IT WORKS</div>
              <h2 className='line-height-48 text-32 font-light'>The 101 of crypto backed loans.</h2>
              <p className='mb-8 text-muted-foreground'>Getting your crypto-backed loan is quick and easy.</p>
            </div>
            <div className='flex flex-col gap-4 py-64 md:flex-row'>
              {items.map((item, index) => (
                <React.Fragment key={item.step}>
                  <Card className='flex-1 p-24 text-center'>
                    <Avatar color='primary' size='md' className='mb-16 h-30 w-30 self-center'>
                      <Body weight='emphasis' color='inverse'>
                        {item.step}
                      </Body>
                    </Avatar>
                    <h3 className='mb-2 font-semibold'>{item.title}</h3>
                    <p className='text-muted-foreground'>{item.description}</p>
                  </Card>
                  {index < items.length - 1 && (
                    <div className='mx-24 flex items-center justify-center text-center'>
                      <span className='block md:hidden'>↓</span>
                      <span className='hidden md:block'>→</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className='flex justify-center'>
              <Button size='lg' onClick={() => handleApplyNowClick()}>
                Register now
              </Button>
            </div>
          </section>
        </div>

        {/* FAQ Section */}
        <section className='mx-auto max-w-6xl space-y-96 xs:px-32 xs:py-24 md:py-96 lg:px-0'>
          <Card className='py-64 xs:px-32 md:px-96'>
            <div className='pb-32 pt-0 text-center'>
              <div className='mb-4 text-[0.75rem] font-medium text-color-text-accent'>WHY CHOOSE SWYFTX?</div>
              <h2 className='line-height-48 text-32 font-light'>Trust your crypto with industry leaders</h2>
              <p className='mb-12 text-center text-muted-foreground'>
                Security you can trust, ensuring your assets remain protected.
              </p>
            </div>
            <div className='grid gap-32 md:grid-cols-3 '>
              <div className='space-y-16'>
                <CheckCircle2 className='h-32 w-32 text-color-text-accent' />
                <h3 className='text-xl font-semibold'>ISO 27001 certified</h3>
                <p className='text-muted-foreground'>
                  Assets will be secured using a leading digital asset custody platform trusted by major institutions
                  for top-tier protection.
                </p>
              </div>
              <div className='space-y-16'>
                <CheckCircle2 className='h-32 w-32 text-color-text-accent' />
                <h3 className='text-xl font-semibold'>Trusted and transparent</h3>
                <p className='text-muted-foreground'>We operate a trusted and transparent service.</p>
              </div>
              <div className='space-y-16'>
                <CheckCircle2 className='h-32 w-32 text-color-text-accent' />
                <h3 className='text-xl font-semibold'>Collaborative custody</h3>
                <p className='text-muted-foreground'>
                  Your assets are securely held in multi-sig storage, ensuring no single person or company can move or
                  lend them.
                </p>
              </div>
            </div>
          </Card>
          <div className='text-center'>
            <div>
              <div className='text-[0.75rem] font-medium text-color-text-accent'>NO-FUSS LOAN TERMS</div>
              <h1 className='line-height-48 text-32 font-light'>Frequently asked questions</h1>
              <p className='mb-8 text-muted-foreground'>Getting your crypto-backed loan is quick and easy.</p>
            </div>

            <div className='flex w-full max-w-2xl flex-col gap-32 justify-self-center pb-32 pt-64 text-left'>
              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>
                  What are crypto-backed loans (CBLs) and why should I use one?
                </h2>
                <p className='text-color-text-secondary'>
                  Crypto-backed loans (CBLs) allow you to use your cryptocurrency as collateral to borrow funds without
                  selling your assets. This helps you access cash while keeping your crypto investments intact,
                  potentially avoiding taxable events and benefiting from future gains.
                </p>
              </div>

              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>What is Loan-to-Value ratio (LTV)?</h2>
                <p className='text-color-text-secondary'>
                  LTV is the percentage of your collateral’s value that you can borrow against. For example, with an LTV
                  of 40%, if you deposit $20,000 AUD worth of BTC, you could borrow up to $8,000 AUD.
                </p>
              </div>

              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>What are the borrowing limits?</h2>
                <p className='text-color-text-secondary'>
                  The minimum borrow amount is $50.00 and the maximum amount is $5,000,000.00. This depends on the loan
                  selected, the value of your collateral, and your desired LTV.
                </p>
              </div>

              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>
                  Are crypto-backed loans available in my country?
                </h2>
                <p className='text-color-text-secondary'>
                  We are planning to provide loans to Australian customers only.
                </p>
              </div>

              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>
                  What happens if the value of my collateral changes before I repay the loan?
                </h2>
                <p className='text-color-text-secondary'>
                  If the value of your collateral drops, you may need to provide additional BTC collateral, or top-up
                  using AUD, to maintain the loan-to-value ratio (LTV). We will notify you if any action is required.
                </p>
              </div>

              <div>
                <h2 className='text-lg mb-1 font-semibold text-primary'>When are you launching this?</h2>
                <p className='text-color-text-secondary'>
                  As this is only an expression of interest and not an active product offering, we do not have a launch
                  date scheduled at this time. This is purely a preliminary step to gauge interest and does not imply
                  any commitment to launch or offer the product in the future. If there are updates or a decision to
                  proceed with a product offering, we will notify those who have expressed interest.
                </p>
              </div>
            </div>
            <div className='flex justify-center'>
              <Button size='lg' onClick={show}>
                Still got questions? Ask our team
              </Button>
            </div>
          </div>
        </section>

        {/* form */}
        <div className='bg-color-background-surface-primary xs:py-24 xs:py-24 md:py-96'>
          <section className='mx-auto max-w-6xl space-y-16 xs:px-32 lg:px-0'>
            <div className='grid gap-16 lg:grid-cols-[400px,1fr] lg:gap-32'>
              <div className='max-w-md py-32'>
                <div className='space-y-8 pb-32'>
                  <h2 className='text-[0.75rem] font-medium text-color-text-accent'>INTERESTED?</h2>
                  <h2 className='line-height-40 text-32 font-light'>Express interest now</h2>
                  <p className='mb-8 text-muted-foreground'>
                    We&apos;re currently gathering interest - this product is in a developmental stage and may be
                    subject to changes. As such, no loan application will be submitted and no further action will be
                    taken.
                  </p>
                </div>

                <ul className='space-y-16 text-left'>
                  {[
                    'You register interest. This gives you exclusive early-access.',
                    "If we launch the product, you'll be one of the first users notified and invited to apply.",
                  ].map((step, index) => (
                    <li key={index} className='flex items-center space-x-16'>
                      <Avatar color='primary' size='md' className='h-30 w-30'>
                        <Body weight='emphasis' color='inverse'>
                          {index + 1}
                        </Body>
                      </Avatar>
                      <p className='text-color-text-secondary'>{step}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <Card className='p-48 xs:p-24'>
                <form className='space-y-32' ref={formRef}>
                  <div className='space-y-16'>
                    <div className='w-full'>
                      <div className='mb-4 text-[0.75rem] font-medium'>Which loan would you be interested in?</div>
                      <RadioGroup
                        value={selectedLoan}
                        onValueChange={(value) => {
                          avo.clickedButton({
                            parentComponent: 'Loans Express Interest Form',
                            tableName: null,
                            screen: pathname,
                            buttonName: `Select loan type in form (${value})`,
                            modalName: null,
                          });
                          setSelectedLoan(value as LoanTypeValueType);
                          setBorrowAmount('');
                        }}
                        className='flex gap-4 xs:flex-col md:flex-row'
                      >
                        <Label htmlFor='flexi' className='flex-1 cursor-pointer'>
                          <Card className='rounded-lg border [&:has(:checked)]:border-primary'>
                            <div className='flex items-center p-8 pl-16'>
                              <RadioGroupItem value='Credit Line' id='flexi' className='mr-16' />
                              <div>
                                <div className='font-semibold'>Flexi Credit</div>
                                <div className='text-[0.75rem] text-muted-foreground'>
                                  40% LVR | 9.50% p.a* | 12 Months
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Label>
                        <Label htmlFor='fixed' className='flex-1 cursor-pointer'>
                          <Card className='rounded-lg border [&:has(:checked)]:border-primary'>
                            <div className='flex items-center p-8 pl-16'>
                              <RadioGroupItem value='Fixed Term' id='fixed' className='mr-16' />
                              <div>
                                <div className='font-semibold'>Fixed Term Loan</div>
                                <div className='text-[0.75rem] text-muted-foreground'>
                                  40% LVR | 8.95% p.a* | 3 Years
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Label>
                      </RadioGroup>
                    </div>

                    <div className='space-y-8'>
                      <label className='text-[0.75rem] font-medium'>Why would you take out a loan?</label>
                      <Select
                        value={purposeOfLoan}
                        onValueChange={(value) => setPurposeOfLoan(value as PurposeOfLoanValueType)}
                        items={Object.entries(PurposeOfLoan).map(([key, value]) => ({
                          key,
                          value,
                          label: value,
                        }))}
                      />
                    </div>

                    {/* <div className='space-y-8'>
                      <label className='text-[0.75rem] font-medium'>Collateral amount</label>
                      <div className='text-[0.75rem] text-muted-foreground'>
                        Available: {availableBitcoinBalance} BTC
                      </div>
                      <div className='relative'>
                        <Input
                          type='number'
                          placeholder='0.0000'
                          value={collateralAmount}
                          onChange={(e) => setCollateralAmount(e.target.value)}
                          className='pr-16'
                          leading={<Bitcoin className='h-25 w-25 rounded-[50%] bg-orange-500 p-4 text-white' />}
                          trailing={
                            <Button
                              variant='outlined'
                              color='primary'
                              size='sm'
                              onClick={() => setCollateralAmount(availableBitcoinBalance.toString())}
                            >
                              MAX
                            </Button>
                          }
                        />
                      </div>
                      <div className='text-[0.75rem] text-muted-foreground'>
                        ≈${Big(collateralAmount).times(rateBTCtoAUD).toString() || '0'} AUD
                      </div>
                    </div> */}

                    <div className='space-y-8'>
                      <label className='text-[0.75rem] font-medium'>How much would you like to borrow?</label>
                      <div className='text-[0.75rem] text-muted-foreground'>{`Limit: ${
                        selectedLoan !== 'Credit Line' ? '$2,000 - $5,000,000 AUD' : '$50 - $250,000 AUD'
                      }`}</div>
                      <div className='relative'>
                        {asset && (
                          <PriceInput
                            asset={asset}
                            leading={<img src='/assets/images/flags/AU.svg' alt='Australia flag' />}
                            placeholder='$0.00'
                            forceSuffix=''
                            onChange={setBorrowAmount}
                            value={borrowAmount}
                            trailing={
                              <Button
                                variant='outlined'
                                color='primary'
                                size='sm'
                                onClick={() => {
                                  setBorrowAmount(selectedLoan !== 'Credit Line' ? '5000000' : '250000');
                                  avo.clickedButton({
                                    parentComponent: 'Loans Express Interest Form',
                                    tableName: null,
                                    screen: pathname,
                                    buttonName: `Max`,
                                    modalName: null,
                                  });
                                }}
                              >
                                MAX
                              </Button>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className='space-y-16'>
                    <h3 className='font-medium'>What your loan could look like:</h3>
                    <div className='space-y-8'>
                      <div className='flex justify-between text-[0.75rem]'>
                        <span>Borrow Amount:</span>
                        <span>{borrowAmountNum ? `$${borrowAmountNum.toFixed(2)}` : '-'}</span>
                      </div>
                      <div className='flex justify-between text-[0.75rem]'>
                        <span>Collateral Required, based on Borrow amount:</span>
                        <span>{collateralRequired ? `${collateralRequired} BTC` : '-'}</span>
                      </div>
                      <div className='flex justify-between text-[0.75rem]'>
                        <span>Loan-to-Value Ratio:</span>
                        <span>{loanToValueRatio ? `${loanToValueRatio.times(100).toFixed(2)}%` : '-'}</span>
                      </div>
                    </div>
                    <div className='text-[0.75rem] text-muted-foreground'>
                      This is not an application, but an indication of interest in a product that is currently under
                      development
                    </div>
                  </div> */}

                  <Button
                    className='w-full'
                    size='lg'
                    disabled={!isFormValid}
                    onClick={() => {
                      avo.clickedButton({
                        parentComponent: 'Loans Express Interest Form',
                        tableName: null,
                        screen: pathname,
                        buttonName: `Submit`,
                        modalName: null,
                      });
                      avo.loanApplicationSubmitted({
                        screen: '',
                        loanType: selectedLoan,
                        purposeOfLoan: purposeOfLoan || PurposeOfLoan.OTHER,
                        purposeOfLoanOther: undefined,
                        loanTerm: undefined,
                        loanCollateralAmount: collateralAmount,
                        loanBorrowAmount: borrowAmount,
                        loanExceedsCurrentBalance: borrowAmount > availableBitcoinBalance,
                      });
                      setPurposeOfLoan(undefined);
                      setBorrowAmount('');
                      setSelectedLoan('Credit Line');
                      openModal(Modals.LoanPostApplicationPopup);
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </Card>
            </div>
          </section>

          <div className='mx-auto max-w-6xl space-y-16 text-center text-[12px] xs:px-32 xs:pt-24 md:pt-96 lg:px-0'>
            <p>
              This is an expression of interest only. The credit product mentioned is not currently available and is not
              being offered for application, nor does it represent an offer or solicitation to apply for credit. Swyftx
              is not authorised to provide credit products, nor are we authorised to assist or suggest applications for
              credit products.
            </p>
            <p>
              By submitting your interest, you acknowledge that Swyftx does not have the required authorisations or
              licenses to offer or facilitate any credit-related services under Australian law. Any information
              collected through this expression of interest is solely for gauging potential interest in future product
              offerings and does not indicate an application process or imply any future credit availability.
            </p>
            <p>
              For advice regarding financial or credit products, we recommend consulting a licensed credit provider or
              financial advisor.
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
});

export { LoansPage };
