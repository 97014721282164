import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, BodyLink } from '@swyftx/aviary/atoms/Typography';

import { HistoricalOrder, OrderType } from '@shared/api';
import { assetService } from '@shared/services';

import { AppFeatureData, useIsFeatureEnabled } from 'src/config';
import {
  checkAssetRedenomination,
  LimitedFunctionalityType,
  RedenominationMessagingDetails,
} from 'src/hooks/Assets/useAssetLimitedFunctionality';

type Props = {
  order: HistoricalOrder;
};

export const getAssetMigrationLabelValues = (redenomMessaging: RedenominationMessagingDetails) => {
  const legacyAssetDetails = redenomMessaging.legacyAssetDetails
    .map((redenom) => `${redenom.legacyName} (${redenom.legacyCode})`)
    .join(', ');
  const { rebrandedName, rebrandedCode } = redenomMessaging.rebrandedAssetDetails;
  const rebrandedAssetDetails = `${rebrandedName} (${rebrandedCode})`;
  const { linkToFurtherDetails } = redenomMessaging;

  return {
    legacyAssetDetails,
    rebrandedAssetDetails,
    linkToFurtherDetails,
  };
};

const AssetMigrationOrderNotification: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleAsset' });
  const { getFeatureData } = useIsFeatureEnabled();
  const featureFlagAssetRedenominations = getFeatureData(AppFeatureData.AssetRedenominationInformation);

  /** If the order is a market sell it means the secondary asset is the legacy asset of the redenomination, if it is a market buy the secondary asset is the rebranded asset
   In both cases we need to check the secondary asset of the order to display the correct redenomination side in the notification */
  const checkAgainstLegacyAsset = order.order_type === OrderType.MarketSell;
  const asset = assetService.getAsset(order.secondary_asset);

  if (!asset) return null;

  const redenomMessaging = checkAssetRedenomination(asset, featureFlagAssetRedenominations, checkAgainstLegacyAsset);

  if (redenomMessaging) {
    const assetMigrationValues = getAssetMigrationLabelValues(redenomMessaging);

    return (
      <Notification
        className='w-full'
        severity='warning'
        title={
          redenomMessaging.type === LimitedFunctionalityType.RedenominationInProgress
            ? t('tokenSwap.titleInProgress')
            : t('tokenSwap.titleCompleted')
        }
      >
        <Body>
          <Trans
            i18nKey={
              redenomMessaging.type === LimitedFunctionalityType.RedenominationInProgress
                ? 'tokenSwap.inProgress'
                : 'tokenSwap.completed'
            }
            t={t}
            values={{
              legacyAssetDetails: assetMigrationValues.legacyAssetDetails,
              rebrandedAssetDetails: assetMigrationValues.rebrandedAssetDetails,
              linkToFurtherDetails: assetMigrationValues.linkToFurtherDetails
                ? t('tokenSwap.linkToFurtherDetails')
                : '',
            }}
            components={[<BodyLink color='accent' key={asset.code} href={assetMigrationValues.linkToFurtherDetails} />]}
          />
        </Body>
      </Notification>
    );
  } else {
    return <></>;
  }
};

export default AssetMigrationOrderNotification;
