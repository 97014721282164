import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { AssetIconsGroup } from 'src/lib/markets/components';

type Props = {
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  amount: Big;
  purchasePrice: Big;
};

const ProfitStopLossLinkedOrder: React.FC<Props> = ({ primaryAsset, secondaryAsset, amount, purchasePrice }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });

  return (
    <FlexLayout direction='column'>
      <FlexLayout className='px-16' direction='row' alignItems='center' justifyContent='space-between' spacing={4}>
        <FlexLayout direction='column'>
          <Body weight='emphasis' color='primary'>
            {t('labels.linkedOrder')}
          </Body>
          <Body weight='emphasis' color='primary'>
            {t('labels.instantBuy')}
          </Body>
        </FlexLayout>
        <FlexLayout direction='column' alignItems='end'>
          <FlexLayout direction='row' spacing={8} alignItems='center'>
            {primaryAsset && secondaryAsset && <AssetIconsGroup assets={[primaryAsset, secondaryAsset]} size={16} />}

            <Numeric color='secondary'>{formatCurrency(amount, secondaryAsset)}</Numeric>
          </FlexLayout>
          <Numeric color='secondary'>
            {formatCurrency(purchasePrice, primaryAsset, { hideCode: true, appendCode: false })} {primaryAsset?.code}/
            {secondaryAsset?.code}
          </Numeric>
        </FlexLayout>
      </FlexLayout>
      <Separator className='mt-8 w-full' orientation='horizontal' />
    </FlexLayout>
  );
};

export { ProfitStopLossLinkedOrder };
