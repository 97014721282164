import { useCallback } from 'react';

import { UserBalance } from '@shared/api';
import { TimesEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore, UserStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import UserService from '@services/UserService';

import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const useDusting = () => {
  const { balances, getUniqueIdentifier } = UserStore.useUserStore;
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const fetchDustingStatus = useCallback(async () => {
    const response = await UserService.CheckDustLimit();
    const nextDustTime = response.nextAvailable;
    let message = '';

    if (nextDustTime && nextDustTime > Date.now()) {
      const nextDustDateTime = DateTime.fromMillis(nextDustTime);
      const { hours, minutes } = nextDustDateTime.diffNow(['hours', 'minutes']);
      const wholeMinutes = Math.ceil(minutes);
      const hoursMessage = hours > 0 ? `${hours} hours` : '';
      const minutesMessage = wholeMinutes > 0 ? `${wholeMinutes} minutes` : '';
      const timeMessage = [hoursMessage, minutesMessage].filter((m) => m !== '').join(' and ');
      message = `Dusting can only be executed once every 24 hours. You will be able to dust assets again in ${timeMessage}`;
    }

    if (!message) {
      const allAssetsAboveMinimum = Object.values(balances)
        .filter((b: UserBalance) => b.assetId !== baseAsset?.id && Big(b.availableBalance).gt(0))
        .every((balance: UserBalance) => Big(balance.availableBalance).gte(getMinimumOrderAmount(balance.assetId)));

      if (allAssetsAboveMinimum) {
        message = 'No assets available to dust';
      }
    }

    return message;
  }, [balances, baseAsset?.id, getMinimumOrderAmount]);

  const { data, isFetching } = useQuery({
    queryKey: ['dustMessage', getUniqueIdentifier()],
    queryFn: () => fetchDustingStatus(),
    keepPreviousData: true,
    staleTime: TimesEnum.MINUTE,
  });

  return { dustMessage: data, isFetching };
};

export { useDusting };
