import { useEffect, useState } from 'react';

import { api, Asset } from '@shared/api';
import { Big } from '@shared/safe-big';

import { ProfitLossValueMeasurement, ProfitLossValueMethod, TriggerType } from '../../types';

type Props = {
  primaryAsset?: Asset;
  secondaryAsset?: Asset;
  amount?: Big;
  feeAmount?: Big;
  profitLossValue: string;
  profitLossValueMethod: ProfitLossValueMethod;
  profitLossValueMeasurement: ProfitLossValueMeasurement;
  triggerType: TriggerType;
  purchasePrice?: Big;
};

const useProfitStopLossSummary = ({
  profitLossValue,
  amount,
  profitLossValueMethod,
  profitLossValueMeasurement,
  triggerType,
  feeAmount,
  purchasePrice,
}: Props) => {
  const [estimatedProfitPercentage, setEstimatedProfitPercentage] = useState<string>('');
  const [estimatedProfitDollars, setEstimatedProfitDollars] = useState<string>('');
  const [estimatedTotal, setEstimatedTotal] = useState<string>('');
  const [feePercentage, setFeePercentage] = useState<string>('');
  const [triggerPrice, setTriggerPrice] = useState<string>('');

  const resetValues = () => {
    setEstimatedProfitDollars('');
    setEstimatedProfitPercentage('');
    setTriggerPrice('');
    setEstimatedTotal('');
  };

  useEffect(() => {
    const fetchFeePercentage = async () => {
      const response = await api.endpoints.getUserFees();
      setFeePercentage(response.data.generalOrderFee);
    };

    fetchFeePercentage();
  }, []);

  useEffect(() => {
    if (!profitLossValue) resetValues();
  }, [profitLossValue]);

  useEffect(() => {
    if (!amount || !profitLossValue) {
      return;
    }

    const amountToSell = Big(amount);
    const relativeCost = Big(purchasePrice).times(Big(amountToSell).plus(feeAmount ?? 0));

    if (profitLossValueMethod === ProfitLossValueMethod.TriggerPrice) {
      const newTradeValue = Big(Big(amount).plus(feeAmount ?? 0)).times(triggerPrice);

      const profitDollars = newTradeValue.minus(relativeCost);
      const profitPercentage = profitDollars.div(relativeCost).times(100);
      const totalTradeValue = relativeCost.plus(profitDollars);

      setTriggerPrice(profitLossValue);
      setEstimatedProfitDollars(profitDollars.abs().toFixed(2));
      setEstimatedProfitPercentage(profitPercentage.abs().toFixed(2));
      setEstimatedTotal(totalTradeValue.toFixed(2));
    } else {
      const isTakeProfit = triggerType === TriggerType.TakeProfit;

      let profitDollars = Big(0);
      let profitPercentage = Big(0);

      if (profitLossValueMeasurement === ProfitLossValueMeasurement.Dollar) {
        profitDollars = Big(profitLossValue).times(isTakeProfit ? 1 : -1);
        profitPercentage = profitDollars.div(relativeCost).times(100);
      } else {
        profitPercentage = Big(profitLossValue);
        profitDollars = relativeCost.times(profitPercentage.div(100)).times(isTakeProfit ? 1 : -1);
      }

      const totalTradeValue = relativeCost.plus(profitDollars);

      const totalFeeAmount = totalTradeValue.times(feePercentage);

      setEstimatedProfitDollars(profitDollars.abs().toFixed(2));
      setEstimatedProfitPercentage(profitPercentage.abs().toFixed(2));

      if (profitLossValueMeasurement === ProfitLossValueMeasurement.Percentage) {
        if (isTakeProfit) {
          setTriggerPrice(
            Big(purchasePrice)
              .times(Big(1).plus(profitPercentage.abs().div(100)))
              .toString(),
          );
        } else {
          setTriggerPrice(
            Big(purchasePrice)
              .times(Big(1).minus(profitPercentage.abs().div(100)))
              .toString(),
          );
        }

        setEstimatedTotal(totalTradeValue.toFixed(2));
      } else {
        const tradeValueWithFee = Big(totalTradeValue).plus(totalFeeAmount);
        setTriggerPrice(Big(tradeValueWithFee).div(amount).toString());
      }
    }
  }, [
    profitLossValueMethod,
    profitLossValueMeasurement,
    profitLossValue,
    amount,
    feePercentage,
    triggerType,
    feeAmount,
    purchasePrice,
    triggerPrice,
  ]);

  return {
    triggerPrice,
    estimatedProfitPercentage,
    estimatedProfitDollars,
    estimatedTotal,
    feePercentage,
  };
};

export { useProfitStopLossSummary };
