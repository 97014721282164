import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircle from '@mui/icons-material/CheckCircle';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TickInCircle } from '@swyftx/aviary/icons/outlined';

import { VerificationStatus } from '@shared/api/@types/verification';
import { GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { assertUnreachable } from '@utils/guards/assert';

import { ProfileVerificationContext } from '@Profile/subroutes/Verification/ProfileVerification.context';

import { observer } from 'mobx-react-lite';

import { VerificationRequirement } from '../VerificationLevel.data';

type Props = {
  requirement: VerificationRequirement;
};

const VerificationRequirementsItem: React.FC<Props> = observer(({ requirement }) => {
  const { userProfile, isKyc1Complete, isKyc2Complete } = UserStore.useUserStore;
  const { jumioTransaction } = useContext(ProfileVerificationContext);
  const { t } = useTranslation('profile.verification');

  let completed: boolean;
  if (requirement === 'verifiedEmail') {
    completed = !!userProfile?.verification?.email;
  } else if (requirement === 'verifiedPhone') {
    completed = !!userProfile?.verification?.phone;
  } else if (requirement === 'governmentId') {
    completed = userProfile?.verification?.greenid.status === GreenIdStatusEnum.VERIFIED;
  } else if (requirement === 'identityAuth') {
    completed = isKyc1Complete() || jumioTransaction?.result?.status === VerificationStatus.PASSED;
  } else if (requirement === 'funds') {
    completed = isKyc2Complete();
  } else {
    // cause a compile error if there's a requirement without a handler
    assertUnreachable();
  }

  return (
    <FlexLayout direction='row' className='items-center' spacing={8}>
      {completed && <CheckCircle color='success' sx={{ width: '16px' }} />}
      {!completed && <TickInCircle className='h-16 w-16 shrink-0' />}

      <Body size='small'>{t(`verificationLevel.requirements.${requirement}`)}</Body>
    </FlexLayout>
  );
});

export { VerificationRequirementsItem };
